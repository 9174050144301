var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", [_vm._v("\n    Horários ocupados\n  ")]),
      _c(
        "div",
        { staticClass: "inline-flex" },
        _vm._l(_vm.schedules, function (schedule, key) {
          return _c("div", { key: key }, [
            _c("div", { staticStyle: { border: "1px solid #c0c0c0" } }, [
              _c("div", [_vm._v(_vm._s(schedule.start_time))]),
              _c("div", { staticClass: "text-center" }, [_vm._v("a")]),
              _c("div", [_vm._v(_vm._s(schedule.end_time))]),
            ]),
          ])
        }),
        0
      ),
      _c("vs-divider"),
      _c("div", [_vm._t("default")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }