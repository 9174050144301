<template>
  <div>
    <vs-popup :active.sync="listSchedulePopup" title="Aulas pendentes - não enviadas" fullscreen>
        <div>
          <h4>As aulas abaixo não foram enviadas para o IBIO, portanto, serão removidas.</h4>
          <h3>Selecione as aulas que deseja manter no sistema.</h3>
           <vs-divider />
           <div class="flex flex-row-reverse">
            <vs-button @click="syncIbioNow">Importar aulas do IBIO</vs-button>
          </div>
           <vs-divider />
          <list-schedule-1 v-model="selectedSchedules" :data="schedules" />
          <vs-divider />
           <div class="flex flex-row-reverse">
            <vs-button @click="syncIbioNow">Importar aulas do IBIO</vs-button>
          </div>
        </div>
    </vs-popup>
  </div>
</template>

<script>
import ListSchedule1 from '@/components/schedule/practical/ListSchedule1'
export default {
  components: {
    ListSchedule1
  },
  data () {
    return {
      selectedSchedules: [],
      schedules: [],
      listSchedulePopup: false,
      callback: null,
      date: null
    }
  },
  methods: {
    execute (date, callback) {
      this.callback = callback
      this.date = date
      this.fetchNotSyncedSchedules()
    },
    fetchNotSyncedSchedules () {
      this.$http.post(
        `${process.env.VUE_APP_API_URL}/notSyncedSchedules`,
        {
          startDate: this.date,
          endDate: this.date
        }
      ).then(e => {
        if (e.data && e.data.length) {
          this.listSchedulePopup = true
          this.schedules = e.data
        } else {
          this.syncIbioNow()
        }
      }).catch(this.mixinCatch)
    },
    syncIbioNow () {
      this.listSchedulePopup = false
      this.sincIbio()
    },
    sincIbio () {
      const payload = {
        endDate: this.date,
        startDate: this.date,
        now: true,
        except: this.selectedSchedules.map(e => e.id)
      }
      this.$vs.loading()
      this.$http.post(
        `${process.env.VUE_APP_API_URL}/sincronizeIbio`,
        payload
      ).then(() => {
        this.mixinNotify('Importação concluída')
        if (this.callback) this.callback()
      })
        .catch(this.mixinCatch)
        .finally(this.mixinFinally)
    }
  }
}
</script>
