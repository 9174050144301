<template>
    <sig-input-number v-model="localValue" :max="max" :label="label" />
</template>

<script>

export default {
  components: {
  },
  props: {
    max: {
      default () {
        return 20
      }
    },
    value: {}
  },
  computed: {
    label () {
      return `Aulas - Quantidade ${this.localValue} de ${this.max}`
    },
    localValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
