<template>
  <div class="w-full">
      <list-schedule v-model="selectedSchedules" :data="schedules" />
      <vs-divider />
      <div class="flex flex-row-reverse bg-grey-lighter">
        <vs-button @click="startSchedulesSaving" :disabled="!selectedSchedules.length">Agendar</vs-button>
      </div>
  </div>
</template>

<script>

import ListSchedule from './ListSchedule.vue'
import axios from '@/axios'

export default {
  components: {
    ListSchedule
  },
  props: {
    data: {}
  },
  data () {
    return {
      schedules: [],
      selectedSchedules: []
    }
  },
  methods: {
    generateSchedules (payload) {
      this.schedules = []
      this.selectedSchedules = []

      this.$vs.loading()
      axios.post(`${process.env.VUE_APP_API_URL}/schedule/generate`, payload)
        .then(resp => {
          this.schedules = resp.data.map(e => {
            e.times = `${e.start_time}-${e.end_time}`
            return e
          })
        })
        .catch(this.mixinCatch)
        .finally(this.mixinFinally)
    },
    startSchedulesSaving () {
      if (!this.selectedSchedules.length) {
        this.mixinNotify('Operação concluída')
        this.$emit('saved')
        return
      }
      this.saveSchedule(this.selectedSchedules.pop())
    },
    saveSchedule (schedule) {
      const payload = {
        type: schedule.type,
        student_id: schedule.id,
        student_name: schedule.student_name,
        employee_id: schedule.employee_id,
        category: schedule.category,
        schedules: [
          {
            status: schedule.status,
            type: schedule.type,
            duration: '00:50',
            start_date: schedule.start_date,
            start_time: schedule.start_time,
            end_time: schedule.end_time,
            student_id: schedule.student_id,
            employee_id: schedule.employee_id,
            vehicle_id: schedule.vehicle ? schedule.vehicle.id : null,
            category: schedule.category,
            type_service: schedule.type_service,
            ibio: schedule.ibio
          }
        ]
      }

      this.$vs.loading()
      axios.post(`${process.env.VUE_APP_API_URL}/schedule`, payload)
        .then(() => {
          this.mixinNotify('Registro de aula feito')
          this.startSchedulesSaving()
        })
        .catch((e) => {
          this.askTryAgain(e, schedule)
        })
        .finally(this.mixinFinally)
    },
    askTryAgain (error, schedule) {
      if (error.response.status === 412) {
        this.$vs.notify({
          //time: error.response.data.erro_sincronizacao_ibio ? '' : 5000,
          title: 'AVISO',
          fixed: !!error.response.data.erro_sincronizacao_ibio,
          text: error.response.data.erro_sincronizacao_ibio ? error.response.data.erro_sincronizacao_ibio : error.response.data.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'top-center'
        })
      } else {
        this.$vs.notify({
          time: 5000,
          title: 'Erro',
          text: error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }

      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Falha ao agendar',
        text: `Ocorreu um erro no agendamento de ${this.scheduleToString(schedule)}?`,
        accept: () => { this.saveSchedule(schedule) },
        cancel: this.startSchedulesSaving,
        acceptText: 'Tentar de novo',
        cancelText: 'Ignorar e avançar'
      })
    },
    scheduleToString (schedule) {
      if (!schedule) return ''
      return `${this.mixinConvertDateStringUsToBr(schedule.start_date)} - ${schedule.start_time}`
    }
  }
}
</script>

