var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("sig-input-number", {
    attrs: { max: _vm.max, label: _vm.label },
    model: {
      value: _vm.localValue,
      callback: function ($$v) {
        _vm.localValue = $$v
      },
      expression: "localValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }