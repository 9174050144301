var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          attrs: {
            active: _vm.listSchedulePopup,
            title: "Aulas pendentes - não enviadas",
            fullscreen: "",
          },
          on: {
            "update:active": function ($event) {
              _vm.listSchedulePopup = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("h4", [
                _vm._v(
                  "As aulas abaixo não foram enviadas para o IBIO, portanto, serão removidas."
                ),
              ]),
              _c("h3", [
                _vm._v("Selecione as aulas que deseja manter no sistema."),
              ]),
              _c("vs-divider"),
              _c(
                "div",
                { staticClass: "flex flex-row-reverse" },
                [
                  _c("vs-button", { on: { click: _vm.syncIbioNow } }, [
                    _vm._v("Importar aulas do IBIO"),
                  ]),
                ],
                1
              ),
              _c("vs-divider"),
              _c("list-schedule-1", {
                attrs: { data: _vm.schedules },
                model: {
                  value: _vm.selectedSchedules,
                  callback: function ($$v) {
                    _vm.selectedSchedules = $$v
                  },
                  expression: "selectedSchedules",
                },
              }),
              _c("vs-divider"),
              _c(
                "div",
                { staticClass: "flex flex-row-reverse" },
                [
                  _c("vs-button", { on: { click: _vm.syncIbioNow } }, [
                    _vm._v("Importar aulas do IBIO"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }