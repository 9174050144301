var render = function render(_c, _vm) {
  return _c("vx-card", { staticClass: "px-4", class: _vm.data.staticClass }, [
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        {
          staticClass:
            "vx-col w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid flex justify-between flex items-center",
        },
        [
          _c("div", { staticClass: "flex items-center" }, [
            _c(
              "div",
              [
                _c("vs-avatar", {
                  staticClass:
                    "sender__avatar--single flex-shrink-0 mr-3 border-2 border-solid border-white",
                  attrs: {
                    src: _vm.props.student.photo && _vm.props.student.photo.url,
                    size: "65px",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "flex flex-col" }, [
              _c("h5", { staticClass: "mb-1" }, [
                _vm._v(_vm._s(_vm.props.student.name)),
              ]),
              _c("div", { staticClass: "flex items-center" }, [
                _c("span", { staticClass: "text-sm" }, [
                  _vm._v(
                    "\n              Instrutor: " +
                      _vm._s(_vm.props.schedule.employee.name) +
                      " "
                  ),
                  _c("br"),
                  _vm._v(
                    "\n              Status: " +
                      _vm._s(
                        _vm._f("scheduleStatus")(_vm.props.schedule.status)
                      ) +
                      "\n            "
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "flex flex-col justify-end" }, [
            _c("span", { staticClass: "text-right font-semibold" }, [
              _vm._v(
                "Data: " +
                  _vm._s(
                    _vm._f("dateTime")(_vm.props.schedule.start_date, "date")
                  )
              ),
            ]),
            _c("div", { staticClass: "flex justify-end mt-2 font-semibold" }, [
              _c("span", { staticClass: "text-right" }, [
                _vm._v(
                  "Horário: " +
                    _vm._s(_vm.props.schedule.start_time.slice(0, 5)) +
                    " até " +
                    _vm._s(_vm.props.schedule.end_time.slice(0, 5))
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ]),
    _vm.props.schedule.status === "Bloqueado"
      ? _c("div", { staticClass: "vx-row" }, [
          _c("div", { staticClass: "vx-col w-full" }, [
            _c("div", { staticClass: "mail__content break-words mt-8 mb-4" }, [
              _c("h5", [_vm._v("Motivo do Bloqueio")]),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.props.schedule.extra || "Motivo não registrado") +
                  "\n      "
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }