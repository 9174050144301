
// transforma uma string de categorias em Array 
export function categoriaStringToArray (categoryString) {
  if (!categoryString) {
    return []
  }

  const categoryArray = []

  if (categoryString.includes('ACC')) {
    categoryArray.push('ACC')
  }

  const splitACC = categoryString.split('ACC')
  return [...categoryArray, ...splitACC.split('')]
}

/**
 * 
 * @param {*} categoriaStringA 
 * @param {*} categoriaStringB 
 * @returns array com as categorias que se encontram nas duas strings
 */
export function categoriasEmComum (categoriaStringA, categoriaStringB) {
  const emComum = []

  const categoriasA = categoriaStringToArray(categoriaStringA)
  const categoriasB = categoriaStringToArray(categoriaStringB)


  return emComum
}