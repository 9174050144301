var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-sidebar",
        {
          staticClass: "full-vs-sidebar email-view-sidebar items-no-padding",
          attrs: {
            "click-not-close": "",
            parent: "#schedule-practical",
            "hidden-background": true,
            "position-right": "",
          },
          model: {
            value: _vm.isSidebarActive,
            callback: function ($$v) {
              _vm.isSidebarActive = $$v
            },
            expression: "isSidebarActive",
          },
        },
        [
          _c(
            "vs-popup",
            {
              attrs: {
                fullscreen: "",
                title: _vm.showPdfTitle,
                active: _vm.popupShowPdf,
              },
              on: {
                "update:active": function ($event) {
                  _vm.popupShowPdf = $event
                },
              },
            },
            [
              _c("object", {
                staticStyle: { height: "600px" },
                attrs: { data: _vm.srcPdf, width: "100%" },
              }),
            ]
          ),
          _c(
            "vs-prompt",
            {
              attrs: {
                title: "Imprimir",
                "accept-text": "Confirmar",
                active: _vm.popupCategoryCnh,
              },
              on: {
                accept: _vm.doPrint,
                "update:active": function ($event) {
                  _vm.popupCategoryCnh = $event
                },
              },
            },
            [
              _c(
                "vs-row",
                [
                  _c(
                    "vs-col",
                    [
                      _c("label", { staticClass: "vs-input--label" }, [
                        _vm._v("Categoria"),
                      ]),
                      _c("v-select", {
                        staticStyle: { "background-color": "white" },
                        attrs: {
                          clearable: false,
                          reduce: (option) => option.value,
                          options: _vm.categoryCNHOptions,
                          placeholder: "Selecione",
                          dir: _vm.$vs.rtl ? "rtl" : "ltr",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "no-options",
                            fn: function ({}) {
                              return [
                                _vm._v(
                                  "\n              Nenhum resultado encontrado.\n            "
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.categoryCnh,
                          callback: function ($$v) {
                            _vm.categoryCnh = $$v
                          },
                          expression: "categoryCnh",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.schedule.id
            ? _c(
                "div",
                { staticClass: "mail-sidebar-content px-0 sm:pb-6 h-full" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex flex-wrap items-center email-header justify-between md:px-8 px-6 sm:pb-2 sm: pt-6 d-theme-dark-bg",
                    },
                    [
                      _c("div", { staticClass: "flex mb-4" }, [
                        _c(
                          "div",
                          { staticClass: "flex items-center" },
                          [
                            _c("feather-icon", {
                              staticClass:
                                "cursor-pointer hover:text-primary mr-4",
                              attrs: {
                                icon: _vm.$vs.rtl
                                  ? "ArrowRightIcon"
                                  : "ArrowLeftIcon",
                                "svg-classes": "w-6 h-6",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("closeSidebar")
                                },
                              },
                            }),
                            _c("h4", [
                              _vm._v("DETALHAMENTO DE AGENDA PRÁTICA"),
                            ]),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "ml-10 mb-4 mt-1" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "email__actions--single flex items-baseline",
                          },
                          [
                            _c(
                              "vx-tooltip",
                              {
                                staticClass: "mr-3 ml-auto",
                                attrs: { text: "Imprimir", position: "top" },
                              },
                              [
                                _c("feather-icon", {
                                  staticClass: "cursor-pointer",
                                  attrs: {
                                    icon: "PrinterIcon",
                                    "svg-classes": "h-6 w-6",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.popupCategoryCnh = true
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "vx-tooltip",
                              {
                                staticClass: "mr-3 ml-auto",
                                attrs: {
                                  text: "Sincronizar IBio",
                                  position: "top",
                                },
                              },
                              [
                                _c("feather-icon", {
                                  staticClass: "cursor-pointer",
                                  attrs: {
                                    icon: "UploadCloudIcon",
                                    "svg-classes": "h-6 w-6",
                                  },
                                  on: { click: _vm.confirmSincIBio },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "vx-tooltip",
                              {
                                attrs: {
                                  text: "Alterar Status",
                                  position: "top",
                                },
                              },
                              [
                                _c(
                                  "vs-dropdown",
                                  {
                                    staticClass: "cursor-pointer",
                                    attrs: {
                                      "vs-custom-content": "",
                                      "vs-trigger-click": "",
                                    },
                                  },
                                  [
                                    _c("feather-icon", {
                                      staticClass: "mb-2",
                                      attrs: {
                                        icon: "FolderIcon",
                                        "svg-classes": "h-6 w-6",
                                      },
                                    }),
                                    _c(
                                      "vs-dropdown-menu",
                                      { staticClass: "w-60" },
                                      [
                                        _c(
                                          "vs-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.updateStatus(
                                                  "Concluído"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "flex items-center",
                                              },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "h-4 w-4 inline-block rounded-full mr-3 bg-success",
                                                }),
                                                _c("span", [
                                                  _vm._v("Concluído"),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "vs-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.updateStatus(
                                                  "Não Concluído"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "flex items-center",
                                              },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "h-4 w-4 inline-block rounded-full mr-3 border-2 border-solid border-secondary",
                                                }),
                                                _c("span", [
                                                  _vm._v("Não Concluído"),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "vs-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.updateStatus(
                                                  "ABSENCE"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "flex items-center",
                                              },
                                              [
                                                _c("feather-icon", {
                                                  staticClass:
                                                    "mr-2 text-danger",
                                                  attrs: {
                                                    icon: "SlashIcon",
                                                    svgClasses: "w-4 h-4",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-danger hover:text-primary",
                                                  },
                                                  [_vm._v("Ausente")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "vx-tooltip",
                              {
                                staticClass: "ml-3",
                                attrs: {
                                  text: "Deletar Selecionados",
                                  position: "top",
                                },
                              },
                              [
                                _c("feather-icon", {
                                  staticClass: "cursor-pointer",
                                  attrs: {
                                    icon: "TrashIcon",
                                    "svg-classes": "h-6 w-6",
                                  },
                                  on: { click: _vm.confirmDelete },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    _vm.scrollbarTag,
                    {
                      key: _vm.$vs.rtl,
                      tag: "component",
                      staticClass:
                        "scroll-area-mail-content md:px-8 md:pb-8 pt-4 px-6 pb-6",
                      attrs: { settings: _vm.settings },
                    },
                    [
                      _vm.isSidebarActive
                        ? _c("div", [
                            _c("div", { staticClass: "vx-row" }, [
                              _c(
                                "div",
                                { staticClass: "vx-col w-full" },
                                [
                                  _c("email-mail-card", {
                                    attrs: {
                                      student: _vm.student,
                                      schedule: _vm.schedule,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm.student.id
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "vx-row",
                                    staticStyle: { "margin-top": "2.2rem" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "vx-col w-full" },
                                      [
                                        _c(
                                          "vx-card",
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "vx-row" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "vx-col w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid flex justify-between flex items-center",
                                                  },
                                                  [
                                                    _c(
                                                      "h5",
                                                      { staticClass: "mb-1" },
                                                      [
                                                        _vm._v(
                                                          "\n                      Outros Agendamentos de " +
                                                            _vm._s(
                                                              _vm.student.name
                                                            ) +
                                                            "\n                    "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm.student.schedules
                                              ? _c(
                                                  "vs-table",
                                                  {
                                                    staticClass: "bordered",
                                                    staticStyle: {
                                                      overflow:
                                                        "-webkit-paged-y",
                                                    },
                                                    attrs: {
                                                      noDataText:
                                                        "Nenhum Agendamento.",
                                                      data: _vm.student.schedules
                                                        .filter(
                                                          (a) =>
                                                            a.type ===
                                                            "practical"
                                                        )
                                                        .sort(
                                                          (a, b) =>
                                                            new Date(
                                                              `${a.start_date.slice(
                                                                0,
                                                                10
                                                              )}T${
                                                                a.start_time
                                                              }-03:00`
                                                            ) -
                                                            new Date(
                                                              `${b.start_date.slice(
                                                                0,
                                                                10
                                                              )}T${
                                                                b.start_time
                                                              }-03:00`
                                                            )
                                                        ),
                                                      hoverFlat: "",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function ({
                                                            data,
                                                          }) {
                                                            return _vm._l(
                                                              data,
                                                              function (
                                                                schedule,
                                                                indextr
                                                              ) {
                                                                return _c(
                                                                  "vs-tr",
                                                                  {
                                                                    key: indextr,
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "12px",
                                                                        opacity:
                                                                          "1 !important",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "vs-td",
                                                                      [
                                                                        _c(
                                                                          "vs-checkbox",
                                                                          {
                                                                            staticClass:
                                                                              "vs-checkbox-small ml-0 mr-1",
                                                                            attrs:
                                                                              {
                                                                                "vs-value":
                                                                                  schedule,
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm.selectedSchedules,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.selectedSchedules =
                                                                                      $$v
                                                                                  },
                                                                                expression:
                                                                                  "selectedSchedules",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "vs-td",
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "cursor-default font-semibold",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                indextr +
                                                                                  1
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "vs-td",
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "cursor-default font-semibold",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm._f(
                                                                                  "dateTime"
                                                                                )(
                                                                                  schedule.start_date,
                                                                                  "date"
                                                                                )
                                                                              ) +
                                                                                "\n                          " +
                                                                                _vm._s(
                                                                                  schedule.start_time.slice(
                                                                                    0,
                                                                                    5
                                                                                  )
                                                                                )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "vs-td",
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "cursor-default font-semibold",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                schedule
                                                                                  .employee
                                                                                  .name
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "vs-td",
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "cursor-default font-semibold",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                schedule
                                                                                  .vehicle
                                                                                  .brand
                                                                              ) +
                                                                                "\n                          " +
                                                                                _vm._s(
                                                                                  schedule
                                                                                    .vehicle
                                                                                    .model
                                                                                ) +
                                                                                "\n                          " +
                                                                                _vm._s(
                                                                                  schedule
                                                                                    .vehicle
                                                                                    .license_plate
                                                                                )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "vs-td",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "text-align":
                                                                              "center",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "cursor-default font-semibold",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                schedule.category
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "vs-td",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "text-align":
                                                                              "center",
                                                                          },
                                                                      },
                                                                      [
                                                                        (!schedule.ibio &&
                                                                          schedule.sync_ibio_last_try) ||
                                                                        schedule.sincIbioStatus ===
                                                                          "processando"
                                                                          ? _c(
                                                                              "feather-icon",
                                                                              {
                                                                                staticClass:
                                                                                  "text-warning",
                                                                                attrs:
                                                                                  {
                                                                                    title:
                                                                                      "Sincronizando",
                                                                                    icon: "ClockIcon",
                                                                                    svgClasses:
                                                                                      "w-5 h-5",
                                                                                  },
                                                                              }
                                                                            )
                                                                          : schedule.ibio &&
                                                                            schedule.id_ibio
                                                                          ? _c(
                                                                              "feather-icon",
                                                                              {
                                                                                staticClass:
                                                                                  "text-success",
                                                                                attrs:
                                                                                  {
                                                                                    title:
                                                                                      "Sincronizada",
                                                                                    icon: "CheckIcon",
                                                                                    svgClasses:
                                                                                      "w-5 h-5",
                                                                                  },
                                                                              }
                                                                            )
                                                                          : _c(
                                                                              "feather-icon",
                                                                              {
                                                                                staticClass:
                                                                                  "text-warning",
                                                                                attrs:
                                                                                  {
                                                                                    title:
                                                                                      "Não sincronizada",
                                                                                    icon: "XIcon",
                                                                                    svgClasses:
                                                                                      "w-5 h-5",
                                                                                  },
                                                                              }
                                                                            ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "vs-td",
                                                                      [
                                                                        schedule.status ===
                                                                        "Não Concluído"
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "h-4 w-4 inline-block rounded-full border-2 border-solid border-secondary",
                                                                              }
                                                                            )
                                                                          : schedule.status ===
                                                                            "Concluído"
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "h-4 w-4 inline-block rounded-full bg-success",
                                                                              }
                                                                            )
                                                                          : _c(
                                                                              "feather-icon",
                                                                              {
                                                                                staticClass:
                                                                                  "text-danger",
                                                                                attrs:
                                                                                  {
                                                                                    icon: "SlashIcon",
                                                                                    svgClasses:
                                                                                      "w-4 h-4",
                                                                                  },
                                                                              }
                                                                            ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "cursor-default ml-1 mr-2",
                                                                            class: `text-${
                                                                              schedule.status ===
                                                                              "Concluído"
                                                                                ? "success"
                                                                                : schedule.status ===
                                                                                  "Não Concluído"
                                                                                ? "secondary"
                                                                                : "danger"
                                                                            }`,
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                          " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "scheduleStatus"
                                                                                  )(
                                                                                    schedule.status
                                                                                  )
                                                                                ) +
                                                                                "\n                        "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              }
                                                            )
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      2670118650
                                                    ),
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "thead" },
                                                      [
                                                        _c(
                                                          "vs-th",
                                                          {
                                                            staticStyle: {
                                                              "font-size":
                                                                "11px",
                                                              width: "40px",
                                                            },
                                                          },
                                                          [
                                                            _c("vs-checkbox", {
                                                              staticClass:
                                                                "select-all-chexkbox",
                                                              attrs: {
                                                                disabled:
                                                                  !_vm.student
                                                                    .schedules
                                                                    .length,
                                                                "icon-pack":
                                                                  "feather",
                                                                icon: _vm.selectAllIcon,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.selectAllCheckBox,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.selectAllCheckBox =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "selectAllCheckBox",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "vs-th",
                                                          {
                                                            staticStyle: {
                                                              "font-size":
                                                                "11px",
                                                              width: "40px",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v("#"),
                                                            ]),
                                                          ]
                                                        ),
                                                        _c(
                                                          "vs-th",
                                                          {
                                                            staticStyle: {
                                                              "font-size":
                                                                "11px",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v("HORÁRIO"),
                                                            ]),
                                                          ]
                                                        ),
                                                        _c(
                                                          "vs-th",
                                                          {
                                                            staticStyle: {
                                                              "font-size":
                                                                "11px",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "INSTRUTOR"
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _c(
                                                          "vs-th",
                                                          {
                                                            staticStyle: {
                                                              "font-size":
                                                                "11px",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v("VEÍCULO"),
                                                            ]),
                                                          ]
                                                        ),
                                                        _c(
                                                          "vs-th",
                                                          {
                                                            staticStyle: {
                                                              "font-size":
                                                                "11px",
                                                              width: "50px",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v("CAT."),
                                                            ]),
                                                          ]
                                                        ),
                                                        _c(
                                                          "vs-th",
                                                          {
                                                            staticStyle: {
                                                              "font-size":
                                                                "11px",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v("IBio"),
                                                            ]),
                                                          ]
                                                        ),
                                                        _c(
                                                          "vs-th",
                                                          {
                                                            staticStyle: {
                                                              "font-size":
                                                                "11px",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v("STATUS"),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }