var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full" },
    [
      _c("list-schedule", {
        attrs: { data: _vm.schedules },
        model: {
          value: _vm.selectedSchedules,
          callback: function ($$v) {
            _vm.selectedSchedules = $$v
          },
          expression: "selectedSchedules",
        },
      }),
      _c("vs-divider"),
      _c(
        "div",
        { staticClass: "flex flex-row-reverse bg-grey-lighter" },
        [
          _c(
            "vs-button",
            {
              attrs: { disabled: !_vm.selectedSchedules.length },
              on: { click: _vm.startSchedulesSaving },
            },
            [_vm._v("Agendar")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }