var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-table",
        {
          staticClass: "bordered",
          staticStyle: { overflow: "-webkit-paged-y" },
          attrs: {
            noDataText: "Nenhum Agendamento.",
            hoverFlat: "",
            data: _vm.data,
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ data }) {
                return _vm._l(data, function (schedule, idx) {
                  return _c(
                    "vs-tr",
                    {
                      key: idx,
                      staticStyle: {
                        "font-size": "12px",
                        opacity: "1 !important",
                      },
                      attrs: {
                        state: schedule.status === "canceled" ? "danger" : null,
                      },
                    },
                    [
                      _c(
                        "vs-td",
                        [
                          _c("vs-checkbox", {
                            staticClass: "vs-checkbox-small ml-0 mr-1",
                            attrs: { "vs-value": schedule },
                            on: { input: _vm.checkIfAllowSelect },
                            model: {
                              value: _vm.selectedSchedules,
                              callback: function ($$v) {
                                _vm.selectedSchedules = $$v
                              },
                              expression: "selectedSchedules",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("vs-td", [
                        _c(
                          "span",
                          { staticClass: "cursor-default font-semibold" },
                          [_vm._v(_vm._s(idx + 1))]
                        ),
                      ]),
                      _c("vs-td", [
                        _c(
                          "span",
                          { staticClass: "cursor-default font-semibold" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.getOperation(schedule.status)) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                      _c("vs-td", [
                        _c(
                          "span",
                          { staticClass: "cursor-default font-semibold" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.mixinConvertDateStringUsToBr(
                                    schedule.start_date
                                  )
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                      _c("vs-td", [
                        _c(
                          "span",
                          { staticClass: "cursor-pointer font-semibold" },
                          [
                            _c("v-select", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate.initial",
                                  value: "required",
                                  expression: "'required'",
                                  modifiers: { initial: true },
                                },
                              ],
                              attrs: {
                                clearable: false,
                                reduce: (option) => option,
                                options: _vm.hourOptions,
                                "data-vv-as": "Horário",
                                name: "time",
                                dir: _vm.$vs.rtl ? "rtl" : "ltr",
                              },
                              on: {
                                open: function ($event) {
                                  return _vm.fetchAvailableSchedules(
                                    schedule,
                                    idx
                                  )
                                },
                                input: (e) =>
                                  _vm.changeHourSchedule(schedule, e),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "no-options",
                                    fn: function ({}) {
                                      return [
                                        _vm._v(
                                          "\n                Nenhum resultado encontrado.\n              "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: schedule.times,
                                callback: function ($$v) {
                                  _vm.$set(schedule, "times", $$v)
                                },
                                expression: "schedule.times",
                              },
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("time"),
                                    expression: "errors.has('time')",
                                  },
                                ],
                                staticClass: "text-danger text-sm",
                              },
                              [_vm._v(_vm._s(_vm.errors.first("time")))]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("vs-td", [
                        schedule.employee
                          ? _c(
                              "span",
                              { staticClass: "cursor-default font-semibold" },
                              [_vm._v(_vm._s(schedule.employee.name))]
                            )
                          : _vm._e(),
                      ]),
                      _c("vs-td", [
                        schedule.vehicle
                          ? _c(
                              "span",
                              { staticClass: "cursor-default font-semibold" },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(schedule.vehicle.brand) +
                                    "\n            " +
                                    _vm._s(schedule.vehicle.model) +
                                    "\n            " +
                                    _vm._s(schedule.vehicle.license_plate) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _c("vs-td", { staticStyle: { "text-align": "center" } }, [
                        _c(
                          "span",
                          { staticClass: "cursor-default font-semibold" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(schedule.category) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                      _c("vs-td", { staticStyle: { "text-align": "center" } }, [
                        _c(
                          "span",
                          { staticClass: "cursor-default font-semibold" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(schedule.observation) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  )
                })
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c(
                "vs-th",
                { staticStyle: { "font-size": "11px", width: "40px" } },
                [
                  _c("vs-checkbox", {
                    staticClass: "select-all-chexkbox",
                    attrs: {
                      disabled: !_vm.data.length,
                      "icon-pack": "feather",
                      icon: _vm.selectAllIcon,
                    },
                    model: {
                      value: _vm.checkboxSelectAll,
                      callback: function ($$v) {
                        _vm.checkboxSelectAll = $$v
                      },
                      expression: "checkboxSelectAll",
                    },
                  }),
                ],
                1
              ),
              _c(
                "vs-th",
                { staticStyle: { "font-size": "11px", width: "40px" } },
                [_c("span", [_vm._v("#")])]
              ),
              _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                _c("span", [_vm._v("OPERAÇÃO")]),
              ]),
              _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                _c("span", [_vm._v("DATA")]),
              ]),
              _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                _c("span", [_vm._v("HORÁRIO")]),
              ]),
              _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                _c("span", [_vm._v("INSTRUTOR")]),
              ]),
              _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                _c("span", [_vm._v("VEÍCULO")]),
              ]),
              _c(
                "vs-th",
                { staticStyle: { "font-size": "11px", width: "50px" } },
                [_c("span", [_vm._v("CAT.")])]
              ),
              _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                _c("span", [_vm._v("Observação")]),
              ]),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }