// retorna o primeiro employee cadastrado para este veiculo
/**
 * 
 * @param {object} vehicle 
 * @returns {object}o primeiro employee cadastrado para este vehicle, ou null
 */
export function getEmployee (vehicle) {
  if (!vehicle) {
    return null
  }
  if (!vehicle.employees) {
    return null
  }
  if (vehicle.employees.length < 1) {
    return null
  }
  return vehicle.employees[0]
}

/**
 * 
 * @param {object} vehicle 
 * @param {number} employee_id 
 * @returns {boolean}
 */
export function employeeEstaCadastradoNesteCarro (vehicle, employee_id) {
  // console.log('vehicle', vehicle)
  // console.log('vehicle', employee_id)
  if (!vehicle || !employee_id) {
    return false
  }
  if (!vehicle.employees) {
    return false
  }
  if (vehicle.employees.length < 1) {
    return false
  }

  for (const i in vehicle.employees) {
    if (vehicle.employees[i].id === employee_id) {
      return true
    }
  }

  return false
}