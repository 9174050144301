<template>
    <div class="w-full">
      <label class="vs-input--label">Veículo</label>
      <v-select
        v-model="vehicle"
        :multiple="multiple"
        placeholder="Selecione"
        :filterable="true"
        :reduce="(option) => option.value"
        :options="allOptions"
        data-vv-as="Veículo"
        :appendToBody="appendToBody"
        class="fix-vselect"
        :autoscroll="false"
        v-validate.initial="'required'"
        style="background-color: white"
        name="single"
        :dir="$vs.rtl ? 'rtl' : 'ltr'"
        :disabled="disabled"
      >
        <template #no-options="{}"> Nenhum resultado encontrado. </template>
          <template v-slot:option="option">
            <div class="text-black flex items-center hover:text-primary">
            <i class="mr-1 material-icons-outlined flex items-center icon" title="Moto"
            :style="{
              color: option.data.identification_color
            }"
            >
              {{ option.data.icon }}
              </i>
              {{ option.label }}
            </div>
          </template>
      </v-select>
      <span v-if="required" class="text-danger text-sm" v-show="errors.has('single')">{{
        errors.first('single')
      }}</span>
    </div>
</template>

<script>
// Store Module

export default {
  props: {
    value: {
      default () {
        return {
          id: null
        }
      }
    },
    useId: {
      default: false
    },
    multiple: {
      default: false
    },
    required: {
      default: false
    },
    styleSelect: {},
    appendToBody: {},
    disabled: {}
  },
  data () {
    return {
      data: []
    }
  },
  computed: {
    allOptions () {
      return this.$store.getters['vehicle/forSelect']
    },
    allVehicles () {
      return this.$store.state.vehicle.vehicles
    },
    vehicle: {
      get () {
        // o v-model pode ser field ID ou Object
        if (this.useId) return this.value
        if (Array.isArray(this.value) && this.value.length) {
          return [...new Set(this.value.map(e => e.id))]
        }
        return this.value && this.value.id ? this.value.id : null

      },
      set (value) {
        if (this.useId) { // no v-model foi passado um ID
          this.$emit('input', value)
        } else  {  // no v-model foi passado um objeto
          if (this.multiple) {
            this.$emit('input', this.allVehicles.filter(e => value.includes(e.id)))
          } else {
            this.$emit('input', this.allVehicles.find(e => e.id === value))
          }
        }
      }
    }
  },
  methods: {
    isValid () {
      this.$validator.validateAll()
      return !this.errors.has('single')
    },
    getSelecteds () {
      if (Array.isArray(this.schedule)) return this.allOptions.filter(e => this.schedule.includes(e.value))
      return this.allOptions.filter(e => e.value === this.vehicle)
    }
  },
  created () {
    // obter servicos
    if (!this.allVehicles.length) {
      this.$store.dispatch('vehicle/fetchAll')
    }
  },
  updated () {
    const elements = this.$el.getElementsByClassName('vs__selected')
    const vehicles = this.getSelecteds()
    for (let i = 0; i < vehicles.length; i++) {
      elements[i].innerHTML = `<i class="mr-1 material-icons-outlined flex items-center icon" title="Moto" style="color: ${vehicles[i].data.identification_color};">${vehicles[i].data.icon}</i>${vehicles[i].label}`
    }
  }
}
</script>
