<template>
  <div>
    <vs-sidebar
      click-not-close
      parent="#schedule-practical"
      :hidden-background="true"
      class="full-vs-sidebar email-view-sidebar items-no-padding"
      v-model="isSidebarActive"
      position-right
    >
      <div class="mail-sidebar-content px-0 sm:pb-6 h-full">
        <div
          class="
            flex flex-wrap
            items-center
            email-header
            justify-between
            md:px-8
            px-6
            sm:pb-2 sm:
            pt-6
            d-theme-dark-bg
          "
        >
          <div class="flex mb-4">
            <div class="flex items-center">
              <feather-icon
                :icon="$vs.rtl ? 'ArrowRightIcon' : 'ArrowLeftIcon'"
                @click="$emit('closeSidebar')"
                class="cursor-pointer hover:text-primary mr-4"
                svg-classes="w-6 h-6"
              ></feather-icon>
              <h4>MUDANÇA DE AGENDA PRÁTICA</h4>
            </div>
          </div>
          <div class="ml-10 mb-4 mt-1">
            <div class="email__actions--single flex items-baseline">
              <!-- <vx-tooltip text="Imprimir" position="top" class="ml-4">
                    <feather-icon icon="PrinterIcon" svg-classes="h-6 w-6" class="cursor-pointer" @click="$emit('markUnread')"></feather-icon>
                  </vx-tooltip> -->
              <!-- <vx-tooltip text="Deletar este agendamento" position="top" class="ml-4">
                    <feather-icon icon="TrashIcon" svg-classes="h-6 w-6" class="cursor-pointer" @click="$emit('delete')"></feather-icon>
                  </vx-tooltip> -->

              <vx-tooltip
                text="Não trocar. Apenas enviar para o IBIO"
                position="top"
                class="mr-3 ml-auto"
              >
                <feather-icon
                  icon="UploadCloudIcon"
                  svg-classes="h-6 w-6"
                  class="cursor-pointer"
                  @click="askConfirmIbioSync"
                ></feather-icon>
              </vx-tooltip>
              <vx-tooltip
                text="Confirmar Troca"
                position="top"
                class="mr-3 ml-auto"
              >
                <feather-icon
                  icon="SaveIcon"
                  svg-classes="h-6 w-6"
                  class="cursor-pointer"
                  @click="confirmChangeSchedules"
                ></feather-icon>
              </vx-tooltip>
            </div>
          </div>
        </div>
        <component
          :is="scrollbarTag"
          class="scroll-area-mail-content md:px-8 md:pb-8 pt-4 px-6 pb-6"
          :settings="settings"
          :key="$vs.rtl"
        >
          <vs-card>
            <div class="vx-row">
              <div class="vx-col md:w-1/4 w-full mt-0">
                <label class="vs-input--label">Instrutor [origem]</label>
                <v-select
                  v-model="employee_from_id"
                  :reduce="(option) => option.value"
                  :options="employees"
                  placeholder="Selecione"
                  style="background-color: white"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                >
                  <template #no-options="{}">
                    Desculpe, nenhum resultado encontrado.
                  </template>
                </v-select>
              </div>
              <div class="vx-col md:w-1/4 w-full mt-0">
                <label class="vs-input--label">Instrutor [destino]</label>
                <v-select
                  v-model="employee_dest_id"
                  :reduce="(option) => option.value"
                  :options="employees"
                  placeholder="Selecione"
                  style="background-color: white"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                >
                  <template #no-options="{}">
                    Desculpe, nenhum resultado encontrado.
                  </template>
                </v-select>
              </div>
              <div class="vx-col md:w-1/4 w-full mt-0">
                <label class="vs-input--label">Veículo [origem]</label>
                <v-select
                  v-model="vehicle_from_id"
                  :reduce="(option) => option.value"
                  :options="vehicles"
                  placeholder="Selecione"
                  style="background-color: white"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                >
                  <template #no-options="{}">
                    Desculpe, nenhum resultado encontrado.
                  </template>
                </v-select>
              </div>
              <div class="vx-col md:w-1/4 w-full mt-0">
                <label class="vs-input--label">Veículo [destino]</label>
                <v-select
                  v-model="vehicle_dest_id"
                  :reduce="(option) => option.data"
                  :options="vehicles"
                  placeholder="Selecione"
                  style="background-color: white"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                >
                  <template #no-options="{}">
                    Desculpe, nenhum resultado encontrado.
                  </template>
                </v-select>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col md:w-1/2 w-full mt-0">
                <label class="vs-input--label">Aluno</label>
                <v-select
                  v-model="student_id"
                  @search="debouncedGetSearchStudent"
                  :clearable="true"
                  :filterable="false"
                  style="background-color: white"
                  :reduce="(option) => option.value"
                  :options="studentOptions"
                  placeholder="Digite cpf ou nome do aluno..."
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                >
                  <template #no-options="{}">
                    Nenhum resultado encontrado.
                  </template>
                </v-select>
              </div>
              <div class="vx-col md:w-1/6 w-full mt-0">
                <label class="vs-input--label">Categoria</label>
                <v-select
                  v-model="category_cnh"
                  :clearable="true"
                  style="background-color: white"
                  :reduce="(option) => option.value"
                  :options="categoryCNHOptions"
                  placeholder="Selecione"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                >
                  <template #no-options="{}">
                    Nenhum resultado encontrado.
                  </template>
                </v-select>
              </div>
               <div class="mt-3 flex flex-wrap items-center">
                 <vs-checkbox
                v-model="onlyIbioSync"
                class="ml-0 mt-2"
                >IBio</vs-checkbox
              >
              </div>
              <div class="mt-3 flex flex-wrap items-center">
                <vs-button icon="search" @click="searchSchedules" type="filled"
                  >Buscar</vs-button
                >
              </div>
            </div>
          </vs-card>
          <vs-card>
            <vs-table
              noDataText="Nenhum Agendamento."
              v-if="schedules"
              :data="schedules"
              class="bordered"
              hoverFlat
              style="overflow: -webkit-paged-y"
            >
              <template slot="thead">
                <vs-th style="font-size: 11px; width: 40px">
                  <vs-checkbox
                    v-model="selectAllCheckBox"
                    :disabled="!schedules.length"
                    icon-pack="feather"
                    :icon="selectAllIcon"
                    class="select-all-chexkbox"
                  ></vs-checkbox>
                </vs-th>
                <vs-th style="font-size: 11px; width: 40px"
                  ><span>#</span></vs-th
                >
                <vs-th style="font-size: 11px"><span>HORÁRIO</span></vs-th>
                <vs-th style="font-size: 11px"><span>INSTRUTOR</span></vs-th>
                <vs-th style="font-size: 11px"><span>ALUNO</span></vs-th>
                <vs-th style="font-size: 11px"><span>VEÍCULO</span></vs-th>
                <vs-th style="font-size: 11px; width: 50px"
                  ><span>CAT.</span></vs-th
                >
                <vs-th style="font-size: 11px"><span>Mensagem</span></vs-th>
                <vs-th style="font-size: 11px"><span>IBio</span></vs-th>
                <vs-th style="font-size: 11px"><span>STATUS</span></vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr
                  :key="indextr"
                  v-for="(schedule, indextr) in data"
                  style="font-size: 12px; opacity: 1 !important"
                >
                  <vs-td
                    ><vs-checkbox
                      v-model="selectedSchedules"
                      :vs-value="schedule"
                      class="vs-checkbox-small ml-0 mr-1"
                    ></vs-checkbox
                  ></vs-td>
                  <vs-td
                    ><span class="cursor-default font-semibold">{{
                      indextr + 1
                    }}</span></vs-td
                  >
                  <vs-td
                    ><span class="cursor-default font-semibold"
                      >{{ schedule.start_date | dateTime('date') }}
                      {{ schedule.start_time.slice(0, 5) }}</span
                    ></vs-td
                  >
                  <vs-td class="text-red-dark"
                    ><span class="cursor-default font-semibold">{{
                      schedule.employee.name
                    }}</span></vs-td
                  >
                  <vs-td
                    ><span class="cursor-default font-semibold">{{
                      schedule.student ? schedule.student.name : ''
                    }}</span></vs-td
                  >
                  <vs-td
                    ><span class="cursor-default font-semibold"
                      >{{ schedule.vehicle ? schedule.vehicle.brand : '' }}
                      {{ schedule.vehicle ? schedule.vehicle.model : '' }}
                      {{
                        schedule.vehicle ? schedule.vehicle.license_plate : ''
                      }}</span
                    ></vs-td
                  >
                  <vs-td style="text-align: center"
                    ><span class="cursor-default font-semibold">{{
                      schedule.category
                    }}</span></vs-td
                  >
                  <vs-td
                    ><span class="cursor-default font-semibold">{{
                      schedule.message
                    }}</span></vs-td
                  >
                  <vs-td style="text-align: center">
                    <feather-icon
                      title="Sincronizando"
                      v-if="
                        (!schedule.ibio && schedule.sync_ibio_last_try) ||
                        schedule.sincIbioStatus === 'processando'
                      "
                      class="text-warning"
                      icon="ClockIcon"
                      svgClasses="w-5 h-5"
                    />
                    <feather-icon
                      title="Sincronizada"
                      v-else-if="schedule.ibio && schedule.id_ibio"
                      class="text-success"
                      icon="CheckIcon"
                      svgClasses="w-5 h-5"
                    />
                    <feather-icon
                      v-else
                      class="text-warning"
                      title="Não sincronizada"
                      icon="XIcon"
                      svgClasses="w-5 h-5"
                    />
                     {{schedule.observation}}
                  </vs-td>
                  <vs-td>
                    <div
                      v-if="schedule.status === 'Não Concluído'"
                      class="
                        h-4
                        w-4
                        inline-block
                        rounded-full
                        border-2 border-solid border-secondary
                      "
                    ></div>
                    <div
                      v-else-if="schedule.status === 'Concluído'"
                      class="h-4 w-4 inline-block rounded-full bg-success"
                    ></div>
                    <feather-icon
                      v-else
                      class="text-danger"
                      icon="SlashIcon"
                      svgClasses="w-4 h-4"
                    />
                    <span
                      class="cursor-default ml-1 mr-2"
                      :class="`text-${
                        schedule.status === 'Concluído'
                          ? 'success'
                          : schedule.status === 'Não Concluído'
                          ? 'secondary'
                          : 'danger'
                      }`"
                    >
                      {{ schedule.status }}
                    </span>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </vs-card>
        </component>
      </div>
    </vs-sidebar>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import _ from 'lodash'
import EmailMailCard from './EmailMailCard.vue'
import moduleEmployee from '@/store/employee/moduleEmployee.js'
import moduleVehicle from '@/store/vehicle/moduleVehicle.js'
import moduleStudentManagement from '@/store/student-management/moduleStudentManagement.js'
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    // USO ESTA PROPRIEDADE PARA FORÇAR A ATUALIZAÇÃO DO ALUNO E COM ISSO MOSTRAR AS ALTERAÇÕES NA AGENDA
    // QUANDO NECESSÁRIO ISSO FOI FEITO PARA MOSTRAR NA LISTAGEM A TROCA DE STATUS PARA AUSENTE
    request: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      onlyIbioSync: true,
      selectedSchedules: [],
      showThread: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.5
      },
      //
      employee_from_id: null,
      employee_dest_id: null,
      vehicle_from_id: null,
      vehicle_dest_id: null,
      student_id: null,
      category_cnh: null,
      start_date: null,
      end_date: null,
      categoryCNHOptions: [
        { label: 'A', value: 'A' },
        { label: 'B', value: 'B' },
        { label: 'C', value: 'C' },
        { label: 'D', value: 'D' },
        { label: 'E', value: 'E' },
        { label: 'ACC', value: 'ACC' }
      ],
      studentOptions: []
    }
  },
  watch: {
    async isSidebarActive () {
      this.selectedSchedules = []
      this.$store.commit('schedule/SET_SCHEDULES_BY_FILTER', [])
    },
    async selectedSchedules (value) {
      const tmp = 1000
      if (tmp.length < value.length) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Aula com status de concluído não pode ser alterada.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        this.selectedSchedules = tmp
      }
    },
    async periods () {
      this.resetSchedulesByFilter()
    },
    async selectedDays () {
      this.resetSchedulesByFilter()
    }
  },
  computed: {
    user () {
      return this.$store.state.AppActiveUser
    },
    schedules () {
      //#
      return this.$store.state.schedule.schedulesByFilter
    },
    periods () {
      //#
      return this.$store.state.schedule.periods // 1 MATUTINO 2 VESPERTINO 3 NOTURNO
    }, //#
    hours () {
      this.resetSchedulesByFilter()
      const h = this.$store.getters.scheduleClassTimes()
      const hours = []
      this.periods.forEach((p) => {
        hours.push([
          h[p][0].replace(/\s.+/g, ''),
          h[p][h[p].length - 1].replace(/.+\s/g, '')
        ])
      })

      return hours
    },
    selectedDays () {
      //#
      return this.$store.state.schedule.selectedDays
    },
    selectAllCheckBox: {
      get () {
        return this.selectedSchedules.length
      },
      set (value) {
        if (value) {
          this.selectedSchedules = this.schedules
        } else {
          this.selectedSchedules = []
        }
      }
    },
    selectAllIcon () {
      return this.selectedSchedules.length === this.schedules.length
        ? 'icon-check'
        : 'icon-minus'
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    },
    //
    employees () {
      return this.$store.getters['employee/forSelect']
    },
    vehicles () {
      return this.$store.getters['vehicle/forSelect']
    }
  },
  methods: {
    askConfirmIbioSync () {
      if (!this.selectedSchedules.length) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Selecione ao menos um agendamento.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      } else {
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: 'Confirmar Sincronização IBio',
          text: 'Tem certeza que deseja sincronizar as agendas selecionadas no IBio?',
          accept: this.sincIBioSelected,
          acceptText: 'Sincronizar',
          cancelText: 'Cancelar'
        })
      }
    },
    async sincIBioSelected () {
      // alert('em desenvolvimento')
      try {
        this.$vs.loading()
        await this.$store.dispatch('schedule/onlySyncSelectedSchedulesIbio', this.selectedSchedules.map(a => a.id))
        this.selectedSchedules = []
        this.$store.commit('schedule/SET_SCHEDULES_BY_FILTER', [])
        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Agendas Sincronizadas.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        this.$vs.loading.close()
        console.log(error)
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning',
            position: 'top-center'
          })
          if (error.response.data.code === 'LOGIN_ERROR') { // ERRO DE LOGIN NO IBIO
            this.selectedSchedules = []
          }
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }

    },
    openLoading () {
      //#
      this.$vs.loading({
        container: '#loading',
        scale: 0.8
      })
    },
    closeLoading () {
      //#
      this.$vs.loading.close('#loading > .con-vs-loading')
    },
    async resetSchedulesByFilter () {
      this.selectedSchedules = []
      this.$store.commit('schedule/SET_SCHEDULES_BY_FILTER', [])
    },
    async updateStatus (status) {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(
        (a) => a.module === 'spa_schedule_practical'
      )
      if (
        !this.user.roles.some((role) => role.name === 'admin') &&
        (!mod || !mod.pivot.permissions.includes('save'))
      ) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      if (!this.selectedSchedules.length) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Selecione ao menos um agendamento.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }
      try {
        this.$vs.loading()
        /**
         * FAÇO UM COMMIT EM ALUNOS ALTERANDO AS AGENDAS NO ALUNO NA LISTA
         * FAÇO OUTRO AOMMIT DIRETO NAS AGENDAS ALTERANDO AS AGENDAS DA TABELA
         */
        this.$store.commit('studentManagement/UPDATE_MASSIVE_SCHEDULES', {
          schedules: this.selectedSchedules,
          changes: { status, duration: '00:50' }
        }) // PRECISO COLOCAR ASSIM O TEMPO PQ NO BANCO É TIME
        this.$store.commit('schedule/UPDATE_MASSIVE_SCHEDULES', {
          schedules: this.selectedSchedules,
          changes: { status, duration: '00:50' }
        }) // PRECISO COLOCAR ASSIM O TEMPO PQ NO BANCO É TIME
        const selectedSchedules = JSON.parse(
          JSON.stringify(this.selectedSchedules)
        )
        selectedSchedules.map((a) => {
          a.status = status
          a.duration = '00:50'
        })

        await this.$store.dispatch('schedule/updateMany', selectedSchedules)
        this.selectedSchedules = []
        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Alterados.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning',
            position: 'top-center'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    async deleteSelected () {
      if (!this.selectedSchedules.length) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Selecione ao menos um agendamento.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }
      try {
        this.$vs.loading()

        // SE EU ESTIVER EXCLUINDO O AGENDAMENTO QUE CLIQUEI,
        // DEVO FECHAR A JANELA LATERAL POIS O MESMO FICA VAZIO
        const scheduleIndex = this.selectedSchedules.findIndex(
          (e) => e.id === this.schedule.id
        )
        if (scheduleIndex >= 0) this.$emit('closeSidebar')

        await this.$store.dispatch(
          'schedule/deleteMany',
          this.selectedSchedules.map((a) => a.id)
        )
        this.$store.commit(
          'studentManagement/DELETE_SCHEDULES',
          this.selectedSchedules
        ) // PRECISO COLOCAR ASSIM O TEMPO PQ NO BANCO É TIME

        this.selectedSchedules = []
        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Excluídos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning',
            position: 'top-center'
          })
          if (error.response.data.code === 'LOGIN_ERROR') {
            // ERRO DE LOGIN NO IBIO
            this.$store.commit(
              'studentManagement/DELETE_SCHEDULES',
              this.selectedSchedules
            ) // PRECISO COLOCAR ASSIM O TEMPO PQ NO BANCO É TIME
            this.selectedSchedules = []
          }
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    confirmChangeSchedules () {
      if (!this.selectedSchedules.length) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Selecione ao menos um agendamento.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      } else if (!this.employee_dest_id && !this.vehicle_dest_id) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Indique o instrutor[destino] ou o veículo[destino].',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      } else {
        let message = ''
        const self = this
        if (this.employee_dest_id) {
          message = `${
            this.employees.find((e) => e.value === self.employee_dest_id).label
          } como instrutor`
        }
        if (this.employee_dest_id && this.vehicle_dest_id) message += ' e o'
        if (this.vehicle_dest_id) message += ` veículo ${
          this.vehicles.find((v) => v.value === self.vehicle_dest_id.id).label
        }`

        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: 'Confirmar mudança na agenda',
          text: `Tem certeza que deseja definir ${message} para as aulas selecionadas?`,
          accept: this.syncSelectedSchedulesUpdated,
          acceptText: 'Confirmar',
          cancelText: 'Cancelar'
        })
      }
    },
    async syncSelectedSchedulesUpdated () {
      // alert('em desenvolvimento')
      try {
        this.$vs.loading()
        const params = {
          employee_dest_id: this.employee_dest_id,
          vehicle_dest_id: this.vehicle_dest_id ? this.vehicle_dest_id.id : null
        }
        const resp = await this.$store.dispatch(
          'schedule/updateSchedulesChanged',
          this.selectedSchedules.map((s) => {
            return { ...s, ...params }
          })
        )
        this.$vs.loading.close()

        if (resp.data.length > 0) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: 'Solicitação realizada. Houve aula que não pode ser alterada. Veja abaixo:',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning',
            position: 'top-center'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: 'Operação realizada com sucesso.',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check'
          })
        }
        this.selectedSchedules = []
      } catch (error) {
        this.$vs.loading.close()
        console.log(error)
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning',
            position: 'top-center'
          })
          if (error.response.data.code === 'LOGIN_ERROR') {
            // ERRO DE LOGIN NO IBIO
            this.$store.commit(
              'studentManagement/DELETE_SCHEDULES',
              this.selectedSchedules
            ) // PRECISO COLOCAR ASSIM O TEMPO PQ NO BANCO É TIME
            this.selectedSchedules = []
          }
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    confirmDelete () {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(
        (a) => a.module === 'spa_schedule_practical'
      )
      if (
        !this.user.roles.some((role) => role.name === 'admin') &&
        (!mod || !mod.pivot.permissions.includes('delete'))
      ) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      if (!this.selectedSchedules.length) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Selecione ao menos um agendamento.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      } else {
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: 'Confirmar Exclusão',
          text: 'Tem certeza que deseja excluir as agendas selecionadas?',
          accept: this.deleteSelected,
          acceptText: 'Excluir',
          cancelText: 'Cancelar'
        })
      }
    },
    async filterSchedule () {
      //#
      // CASO NÃO ENVIE FILTRO ESVAZIA A AGENDA
      const filtra = false
      if (
        !this.employee_from_id &&
        !this.vehicle_from_id &&
        !this.student_id &&
        !this.selectedDays
      ) {
        this.$store.commit('schedule/SET_SCHEDULES_BY_FILTER', [])
        this.closeLoading()
        return
      }
      try {
        this.openLoading()
        const payload = {}
        if (this.student_id) payload.student_id = this.student_id
        if (this.employee_from_id) payload.employee_id = this.employee_from_id
        if (this.vehicle_from_id) payload.vehicle_id = this.vehicle_from_id
        if (this.category_cnh) payload.category = this.category_cnh

        payload.dates = this.selectedDays.map((day) => day.id)
        payload.start_time = this.hours
        payload.onlyIbioSync = this.onlyIbioSync
        await this.$store.dispatch('schedule/fetchSchedulesByFilter', payload) // BUSCA OS AGENDAMENTOS NA API CONFORME FILTRO
        this.closeLoading()
      } catch (error) {
        console.error(error)
      }
    },
    fetchStudentOptions (search, loading) {
      if (search.length) {
        loading(true)
        this.$http
          .post(`${process.env.VUE_APP_API_URL}/student/search`, {
            kw: search,
            type: 'STUDENT',
            with: ['schedules']
          })
          .then(
            function (response) {
              this.studentOptions = []
              for (const k in response.data) {
                this.studentOptions.push({
                  value: response.data[k].id,
                  label: `${response.data[k].name} ${
                    response.data[k].cpf || ''
                  }`,
                  data: response.data[k]
                })
              }
              loading(false)
            }.bind(this)
          )
          .catch(function (error) {
            console.error(error)
          })
      } else {
        loading(false)
      }
    },
    searchSchedules () {
      this.selectedSchedules = []
      this.filterSchedule()
    }
  },
  components: {
    VuePerfectScrollbar,
    EmailMailCard,
    vSelect
  },

  created () {
    // Register Module StudentManagement Module
    this.debouncedGetSearchStudent = _.debounce(this.fetchStudentOptions, 600)
    this.debouncedGetFilterSchedule = _.debounce(this.filterSchedule, 800)
    if (!moduleStudentManagement.isRegistered) {
      this.$store.registerModule('studentManagement', moduleStudentManagement)
      moduleStudentManagement.isRegistered = true
    }
    if (!moduleEmployee.isRegistered) {
      this.$store.registerModule('employee', moduleEmployee)
      moduleEmployee.isRegistered = true
    }
  }
}
</script>
