var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "border border-solid d-theme-border-grey-light rounded relative",
      attrs: { id: "schedule-practical" },
    },
    [
      _c("sync-ibio-schedule", { ref: "syncIbio" }),
      _c(
        "vs-popup",
        {
          attrs: {
            active: _vm.popupMultiScheduleCreator,
            title: "Agendamento em massa",
            fullscreen: "",
          },
          on: {
            "update:active": function ($event) {
              _vm.popupMultiScheduleCreator = $event
            },
          },
        },
        [
          _c("multi-schedule-creator", {
            ref: "multiSchedule",
            on: { saved: _vm.closeMultiSchedulePopup },
          }),
        ],
        1
      ),
      _c(
        "sig-prompt",
        {
          attrs: {
            active: _vm.popupInputClassQuantity,
            title: "Replicar aulas",
          },
          on: {
            "update:active": function ($event) {
              _vm.popupInputClassQuantity = $event
            },
            accept: _vm.replicateSchedules,
          },
        },
        [
          _c("input-class-quantity", {
            attrs: { max: _vm.maxQuantityClassAvailable },
            model: {
              value: _vm.quantityForMultiSchedule,
              callback: function ($$v) {
                _vm.quantityForMultiSchedule = $$v
              },
              expression: "quantityForMultiSchedule",
            },
          }),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup80 noOverflow",
          attrs: {
            fullscreen: false,
            title: _vm.showPdfTitle,
            active: _vm.popupShowPdf,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupShowPdf = $event
            },
          },
        },
        [
          _c("object", {
            staticStyle: { height: "600px" },
            attrs: { data: _vm.srcPdf, width: "100%" },
          }),
        ]
      ),
      _c(
        "vs-sidebar",
        {
          staticClass: "items-no-padding",
          attrs: {
            parent: "#schedule-practical",
            "click-not-close": _vm.clickNotClose,
            "hidden-background": _vm.clickNotClose,
          },
          model: {
            value: _vm.isEmailSidebarActive,
            callback: function ($$v) {
              _vm.isEmailSidebarActive = $$v
            },
            expression: "isEmailSidebarActive",
          },
        },
        [
          _c("email-sidebar", {
            on: {
              closeLoading: _vm.closeLoading,
              filterSchedule: _vm.filterSchedule,
              closeSidebar: _vm.toggleEmailSidebar,
            },
          }),
        ],
        1
      ),
      _c(
        "vue-context",
        { ref: "menu", attrs: { closeOnClick: _vm.closeOnClick } },
        [
          _vm.contextAdd
            ? _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "flex items-center text-sm hover:text-primary",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        return _vm.contextMenuClicked("ADD")
                      },
                    },
                  },
                  [
                    _c("feather-icon", {
                      attrs: { icon: "PlusCircleIcon", svgClasses: "w-5 h-5" },
                    }),
                    _c("span", { staticClass: "ml-2" }, [
                      _vm._v("Novo Agendamento"),
                    ]),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.contextAbsence
            ? _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "flex items-center text-sm hover:text-warning",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        return _vm.contextMenuClicked("ABSENCE")
                      },
                    },
                  },
                  [
                    _c("feather-icon", {
                      attrs: { icon: "SlashIcon", svgClasses: "w-5 h-5" },
                    }),
                    _c("span", { staticClass: "ml-2" }, [
                      _vm._v("Falta sem Justificativa"),
                    ]),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.contextReplicate
            ? _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "flex items-center text-sm hover:text-warning",
                    attrs: { href: "#" },
                    on: { click: _vm.openInputClassQuantityPopup },
                  },
                  [
                    _c("feather-icon", {
                      attrs: { icon: "CopyIcon", svgClasses: "w-5 h-5" },
                    }),
                    _c("span", { staticClass: "ml-2" }, [_vm._v("Replicar")]),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.contextDelete
            ? _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "flex items-center text-sm hover:text-danger",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        return _vm.contextMenuClicked("DELETE")
                      },
                    },
                  },
                  [
                    _c("feather-icon", {
                      attrs: { icon: "MinusCircleIcon", svgClasses: "w-5 h-5" },
                    }),
                    _c("span", { staticClass: "ml-2" }, [_vm._v("Excluir")]),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.contextBlockEmployee
            ? _c(
                "li",
                [
                  _c("vs-textarea", {
                    staticClass: "mb-4",
                    attrs: {
                      placeholder: "Motivo do Bloqueio",
                      counter: "120",
                      maxlength: "120",
                      rows: "5",
                    },
                    model: {
                      value: _vm.blockEmployeeMotivation,
                      callback: function ($$v) {
                        _vm.blockEmployeeMotivation = $$v
                      },
                      expression: "blockEmployeeMotivation",
                    },
                  }),
                  _c(
                    "a",
                    {
                      staticClass:
                        "flex items-center text-sm hover:text-danger",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.contextMenuClicked("BLOCK_EMPLOYEE")
                        },
                      },
                    },
                    [
                      _c("feather-icon", {
                        attrs: { icon: "SlashIcon", svgClasses: "w-5 h-5" },
                      }),
                      _c("span", { staticClass: "ml-2" }, [
                        _vm._v("Bloquear Instrutor Selecionado"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "no-scroll-content border border-solid d-theme-border-grey-light border-r-0 border-t-0 border-b-0",
          class: { "sidebar-spacer": _vm.clickNotClose },
        },
        [
          _c(
            "div",
            { staticClass: "flex border d-theme-dark-bg items-center" },
            [
              _c("feather-icon", {
                staticClass:
                  "md:inline-flex lg:hidden ml-4 mr-4 cursor-pointer",
                attrs: { icon: "MenuIcon" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.toggleEmailSidebar(true)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "email__actions p-2 border border-r-0 border-l-0 border-solid d-theme-border-grey-light",
            },
            [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/2 w-full mt-0" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Aluno"),
                    ]),
                    _c("v-select", {
                      staticStyle: { "background-color": "white" },
                      attrs: {
                        clearable: true,
                        filterable: false,
                        reduce: (option) => option.value,
                        options: _vm.studentOptions,
                        placeholder: "Digite cpf ou nome do aluno...",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.changeStudent()
                        },
                        search: _vm.debouncedGetSearchStudent,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n              Nenhum resultado encontrado.\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.student_id,
                        callback: function ($$v) {
                          _vm.student_id = $$v
                        },
                        expression: "student_id",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Instrutor"),
                    ]),
                    _c("v-select", {
                      staticStyle: { "background-color": "white" },
                      attrs: {
                        reduce: (option) => option.value,
                        options: _vm.employees,
                        placeholder: "Selecione",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.changeEmployee()
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n              Desculpe, nenhum resultado encontrado.\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.employee_id,
                        callback: function ($$v) {
                          _vm.employee_id = $$v
                        },
                        expression: "employee_id",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                  [
                    _c("select-vehicle", {
                      on: {
                        input: function ($event) {
                          return _vm.changeVehicle()
                        },
                      },
                      model: {
                        value: _vm.vehicle,
                        callback: function ($$v) {
                          _vm.vehicle = $$v
                        },
                        expression: "vehicle",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/6 w-full mt-0" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Cat."),
                    ]),
                    _c("v-select", {
                      staticStyle: { "background-color": "white" },
                      attrs: {
                        clearable: false,
                        reduce: (option) => option.value,
                        options: _vm.categoryCNHOptions,
                        placeholder: "Selecione",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.changeCategory()
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n              Nenhum resultado encontrado.\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.category,
                        callback: function ($$v) {
                          _vm.category = $$v
                        },
                        expression: "category",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Tipo de Serviço"),
                    ]),
                    _c("v-select", {
                      staticStyle: { "background-color": "white" },
                      attrs: {
                        clearable: true,
                        reduce: (option) => option.value,
                        options: _vm.typeServiceOptions,
                        placeholder: "Selecione",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n              Nenhum resultado encontrado.\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.type_service,
                        callback: function ($$v) {
                          _vm.type_service = $$v
                        },
                        expression: "type_service",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/6 w-full mt-0" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Qtd."),
                    ]),
                    _c("v-select", {
                      staticClass: "w-full",
                      staticStyle: { "background-color": "white" },
                      attrs: {
                        reduce: (option) => option.value,
                        clearable: false,
                        options: [
                          { value: "1", label: "1" },
                          { value: "2", label: "2" },
                          { value: "3", label: "3" },
                        ],
                        placeholder: "",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n              Nenhum resultado encontrado.\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.quantity,
                        callback: function ($$v) {
                          _vm.quantity = $$v
                        },
                        expression: "quantity",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "vx-col md:w-auto mt-0" }, [
                  _c(
                    "div",
                    { staticClass: "mt-3 flex flex-wrap items-center" },
                    [
                      _vm.disabledIbio
                        ? _c(
                            "vx-tooltip",
                            {
                              staticClass: "ml-0 mt-2",
                              attrs: {
                                text: "Configure o acesso ao IBio",
                                position: "top",
                              },
                            },
                            [
                              _c("feather-icon", {
                                staticClass: "block",
                                attrs: {
                                  icon: "InfoIcon",
                                  svgClasses:
                                    "h-5 w-5 stroke-current text-grey",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "vs-checkbox",
                        {
                          staticClass: "ml-0 mt-2",
                          attrs: { disabled: _vm.disabledIbio },
                          model: {
                            value: _vm.ibio,
                            callback: function ($$v) {
                              _vm.ibio = $$v
                            },
                            expression: "ibio",
                          },
                        },
                        [_vm._v("IBio")]
                      ),
                      _vm.scheduledClasses
                        ? _c(
                            "span",
                            {
                              staticClass: "ml-6 font-semibold",
                              staticStyle: { "font-size": "10px" },
                            },
                            [
                              _vm._v(
                                "\n              Total A: " +
                                  _vm._s(_vm.scheduledClasses) +
                                  " / " +
                                  _vm._s(_vm.contractedSchedules) +
                                  "\n              "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n              Disponível A:\n              "
                              ),
                              _c(
                                "span",
                                {
                                  class: {
                                    "text-danger": _vm.availableSchedules < 0,
                                  },
                                },
                                [_vm._v(_vm._s(_vm.availableSchedules))]
                              ),
                              _c("br"),
                              _vm._v(
                                "\n              Faltas A: " +
                                  _vm._s(_vm.absences)
                              ),
                              _c("br"),
                              _vm._v("\n               \n              "),
                            ]
                          )
                        : _vm._e(),
                      _vm.scheduledClassesB
                        ? _c(
                            "span",
                            {
                              staticClass: "ml-6 font-semibold",
                              staticStyle: { "font-size": "10px" },
                            },
                            [
                              _vm._v(
                                "\n              Total B: " +
                                  _vm._s(_vm.scheduledClassesB) +
                                  " / " +
                                  _vm._s(_vm.contractedSchedules) +
                                  "\n              "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n              Disponível B:\n              "
                              ),
                              _c(
                                "span",
                                {
                                  class: {
                                    "text-danger": _vm.availableSchedulesB < 0,
                                  },
                                },
                                [_vm._v(_vm._s(_vm.availableSchedulesB))]
                              ),
                              _c("br"),
                              _vm._v(
                                "\n              Faltas B: " +
                                  _vm._s(_vm.absences)
                              ),
                              _c("br"),
                              _vm._v("\n               \n            "),
                            ]
                          )
                        : _vm._e(),
                      _vm.scheduledClassesC
                        ? _c(
                            "span",
                            {
                              staticClass: "ml-6 font-semibold",
                              staticStyle: { "font-size": "10px" },
                            },
                            [
                              _vm._v(
                                "\n              Total C: " +
                                  _vm._s(_vm.scheduledClassesC) +
                                  " / " +
                                  _vm._s(_vm.contractedSchedules) +
                                  "\n              "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n              Disponível C:\n              "
                              ),
                              _c(
                                "span",
                                {
                                  class: {
                                    "text-danger": _vm.availableSchedulesC < 0,
                                  },
                                },
                                [_vm._v(_vm._s(_vm.availableSchedulesC))]
                              ),
                              _c("br"),
                              _vm._v(
                                "\n              Faltas C: " +
                                  _vm._s(_vm.absences)
                              ),
                              _c("br"),
                              _vm._v("\n               \n            "),
                            ]
                          )
                        : _vm._e(),
                      _vm.scheduledClassesD
                        ? _c(
                            "span",
                            {
                              staticClass: "ml-6 font-semibold",
                              staticStyle: { "font-size": "10px" },
                            },
                            [
                              _vm._v(
                                "\n              Total D: " +
                                  _vm._s(_vm.scheduledClassesD) +
                                  " / " +
                                  _vm._s(_vm.contractedSchedules) +
                                  "\n              "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n              Disponível D:\n              "
                              ),
                              _c(
                                "span",
                                {
                                  class: {
                                    "text-danger": _vm.availableSchedulesD < 0,
                                  },
                                },
                                [_vm._v(_vm._s(_vm.availableSchedulesD))]
                              ),
                              _c("br"),
                              _vm._v(
                                "\n              Faltas D: " +
                                  _vm._s(_vm.absences)
                              ),
                              _c("br"),
                              _vm._v("\n               \n            "),
                            ]
                          )
                        : _vm._e(),
                      _vm.scheduledClassesE
                        ? _c(
                            "span",
                            {
                              staticClass: "ml-6 font-semibold",
                              staticStyle: { "font-size": "10px" },
                            },
                            [
                              _vm._v(
                                "\n              Total E: " +
                                  _vm._s(_vm.scheduledClassesE) +
                                  " / " +
                                  _vm._s(_vm.contractedSchedules) +
                                  "\n              "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n              Disponível E:\n              "
                              ),
                              _c(
                                "span",
                                {
                                  class: {
                                    "text-danger": _vm.availableSchedulesE < 0,
                                  },
                                },
                                [_vm._v(_vm._s(_vm.availableSchedulesE))]
                              ),
                              _c("br"),
                              _vm._v(
                                "\n              Faltas E: " +
                                  _vm._s(_vm.absences)
                              ),
                              _c("br"),
                              _vm._v("\n               \n            "),
                            ]
                          )
                        : _vm._e(),
                      _vm.scheduledClassesACC
                        ? _c(
                            "span",
                            {
                              staticClass: "ml-6 font-semibold",
                              staticStyle: { "font-size": "10px" },
                            },
                            [
                              _vm._v(
                                "\n              Total ACC: " +
                                  _vm._s(_vm.scheduledClassesACC) +
                                  " /\n              " +
                                  _vm._s(_vm.contractedSchedules) +
                                  " "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n              Disponível ACC:\n              "
                              ),
                              _c(
                                "span",
                                {
                                  class: {
                                    "text-danger":
                                      _vm.availableSchedulesACC < 0,
                                  },
                                },
                                [_vm._v(_vm._s(_vm.availableSchedulesACC))]
                              ),
                              _c("br"),
                              _vm._v(
                                "\n              Faltas ACC: " +
                                  _vm._s(_vm.absences)
                              ),
                              _c("br"),
                              _vm._v("\n               \n            "),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "vx-tooltip",
                        {
                          staticClass: "ml-2 mr-2 mt-1",
                          attrs: {
                            text: "Período do Relatório",
                            position: "top",
                          },
                        },
                        [
                          _c(
                            "vs-dropdown",
                            {
                              staticClass: "cursor-pointer hover:text-primary",
                              attrs: {
                                "vs-custom-content": "",
                                "vs-trigger-click": "",
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "flex cursor-pointer",
                                  attrs: { "href.prevent": "" },
                                },
                                [
                                  _c("feather-icon", {
                                    staticClass: "p-2 rounded-full",
                                    class: [`text-primary`, "mb-0"],
                                    style: {
                                      background: `rgba(var(--vs-success),.15)`,
                                    },
                                    attrs: {
                                      icon: "CalendarIcon",
                                      svgClasses: "h-6 w-6",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "vs-dropdown-menu",
                                {
                                  staticClass: "w-60",
                                  staticStyle: { "z-index": "200001" },
                                },
                                [
                                  _c("vs-input", {
                                    staticClass: "w-full",
                                    attrs: {
                                      type: "date",
                                      label: "Data Inicial",
                                    },
                                    model: {
                                      value: _vm.reportFirstPeriod,
                                      callback: function ($$v) {
                                        _vm.reportFirstPeriod = $$v
                                      },
                                      expression: "reportFirstPeriod",
                                    },
                                  }),
                                  _c("vs-input", {
                                    staticClass: "w-full",
                                    attrs: {
                                      type: "date",
                                      label: "Data Final",
                                    },
                                    model: {
                                      value: _vm.reportLastPeriod,
                                      callback: function ($$v) {
                                        _vm.reportLastPeriod = $$v
                                      },
                                      expression: "reportLastPeriod",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.periodError,
                                          expression: "periodError",
                                        },
                                      ],
                                      staticClass: "text-danger text-sm",
                                    },
                                    [
                                      _vm._v(
                                        "O período inicial é maior que o final."
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "vx-tooltip",
                        {
                          staticClass: "mt-2 mr-2",
                          attrs: { text: "Relatório Excel", position: "top" },
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "svg-hover h-8 w-8 cursor-pointer",
                              staticStyle: {
                                "-ms-transform": "rotate(360deg)",
                                "-webkit-transform": "rotate(360deg)",
                                transform: "rotate(360deg)",
                              },
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                                "aria-hidden": "true",
                                focusable: "false",
                                width: "1em",
                                height: "1em",
                                preserveAspectRatio: "xMidYMid meet",
                                viewBox: "0 0 24 24",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.print("EXCEL")
                                },
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M23 1.5q.41 0 .7.3q.3.29.3.7v19q0 .41-.3.7q-.29.3-.7.3H7q-.41 0-.7-.3q-.3-.29-.3-.7V18H1q-.41 0-.7-.3q-.3-.29-.3-.7V7q0-.41.3-.7Q.58 6 1 6h5V2.5q0-.41.3-.7q.29-.3.7-.3zM6 13.28l1.42 2.66h2.14l-2.38-3.87l2.34-3.8H7.46l-1.3 2.4l-.05.08l-.04.09l-.64-1.28l-.66-1.29H2.59l2.27 3.82l-2.48 3.85h2.16zM14.25 21v-3H7.5v3zm0-4.5v-3.75H12v3.75zm0-5.25V7.5H12v3.75zm0-5.25V3H7.5v3zm8.25 15v-3h-6.75v3zm0-4.5v-3.75h-6.75v3.75zm0-5.25V7.5h-6.75v3.75zm0-5.25V3h-6.75v3z",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "vx-tooltip",
                        {
                          staticClass: "mt-2",
                          attrs: { text: "Relatório PDF", position: "top" },
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "svg-hover h-8 w-8 cursor-pointer",
                              staticStyle: {
                                "-ms-transform": "rotate(360deg)",
                                "-webkit-transform": "rotate(360deg)",
                                transform: "rotate(360deg)",
                              },
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                                "aria-hidden": "true",
                                focusable: "false",
                                width: "0.86em",
                                height: "1em",
                                preserveAspectRatio: "xMidYMid meet",
                                viewBox: "0 0 1536 1792",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.doRelatorioPdf()
                                },
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M1468 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28H96q-40 0-68-28t-28-68V96q0-40 28-68T96 0h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528V640H992q-40 0-68-28t-28-68V128H128v1536h1280zm-514-593q33 26 84 56q59-7 117-7q147 0 177 49q16 22 2 52q0 1-1 2l-2 2v1q-6 38-71 38q-48 0-115-20t-130-53q-221 24-392 83q-153 262-242 262q-15 0-28-7l-24-12q-1-1-6-5q-10-10-6-36q9-40 56-91.5t132-96.5q14-9 23 6q2 2 2 4q52-85 107-197q68-136 104-262q-24-82-30.5-159.5T657 552q11-40 42-40h22q23 0 35 15q18 21 9 68q-2 6-4 8q1 3 1 8v30q-2 123-14 192q55 164 146 238zm-576 411q52-24 137-158q-51 40-87.5 84t-49.5 74zm398-920q-15 42-2 132q1-7 7-44q0-3 7-43q1-4 4-8q-1-1-1-2q-1-2-1-3q-1-22-13-36q0 1-1 2v2zm-124 661q135-54 284-81q-2-1-13-9.5t-16-13.5q-76-67-127-176q-27 86-83 197q-30 56-45 83zm646-16q-24-24-140-24q76 28 124 28q14 0 18-1q0-1-2-3z",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "vx-tooltip",
                        {
                          staticClass: "mt-2",
                          attrs: { text: "Mudança na agenda", position: "top" },
                        },
                        [
                          _c("feather-icon", {
                            staticClass: "cursor-pointer",
                            attrs: { icon: "ToolIcon" },
                            on: { click: _vm.changeScheduleShow },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          ),
          _c(
            _vm.scrollbarTag,
            {
              key: _vm.$vs.rtl,
              ref: "mailListPS",
              tag: "component",
              staticClass: "email-content-scroll-area",
              attrs: { settings: _vm.settings },
            },
            [
              _c(
                "div",
                {
                  staticClass: "vs-con-loading__container",
                  attrs: { id: "loading" },
                },
                _vm._l(_vm.tables, function (table, tIndex) {
                  return _c(
                    "vs-table",
                    {
                      key: tIndex,
                      staticClass: "bordered",
                      staticStyle: { overflow: "-webkit-paged-y" },
                      attrs: { data: _vm.selectedDays, hoverFlat: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ data }) {
                              return [
                                _vm._l(_vm.hours, function (hour, i) {
                                  return _c(
                                    "vs-tr",
                                    {
                                      key: i,
                                      staticStyle: {
                                        "font-size": "12px",
                                        opacity: "1 !important",
                                      },
                                    },
                                    [
                                      _c(
                                        "vs-td",
                                        {
                                          staticStyle: {
                                            "text-align": "right",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "cursor-default font-semibold",
                                            },
                                            [_vm._v(_vm._s(hour.slice(0, 5)))]
                                          ),
                                        ]
                                      ),
                                      _vm._l(
                                        _vm.handleRange(table),
                                        function (hr, i) {
                                          return _c(
                                            "vs-td",
                                            {
                                              key: i,
                                              staticClass: "cursor-pointer",
                                              style:
                                                (_vm.fillSchedule(
                                                  _vm.selectedDays[hr - 1],
                                                  hour
                                                ).length === 1 &&
                                                  _vm.fillSchedule(
                                                    _vm.selectedDays[hr - 1],
                                                    hour
                                                  )[0].bg) ||
                                                "",
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.addSchedule(
                                                    _vm.selectedDays[hr - 1],
                                                    hour
                                                  )
                                                },
                                                contextmenu: function ($event) {
                                                  $event.preventDefault()
                                                  if (
                                                    $event.target !==
                                                    $event.currentTarget
                                                  )
                                                    return null
                                                  return _vm.contextMenu(
                                                    $event,
                                                    null,
                                                    _vm.selectedDays[hr - 1],
                                                    hour
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._l(
                                                _vm.selectedDays[hr - 1]
                                                  .holidaySchedule,
                                                function (holiday, hd) {
                                                  return _c("feather-icon", {
                                                    key: `${hd}_holiday`,
                                                    staticClass: "mr-1",
                                                    attrs: {
                                                      title:
                                                        holiday.holidayName,
                                                      icon: "StarIcon",
                                                      svgClasses: "w-4 h-4",
                                                    },
                                                  })
                                                }
                                              ),
                                              _vm._l(
                                                _vm.fillSchedule(
                                                  _vm.selectedDays[hr - 1],
                                                  hour
                                                ),
                                                function (schedule, idx) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: `${idx}_schedule`,
                                                      staticClass:
                                                        "text-black flex items-center hover:text-primary",
                                                      style: schedule.fill
                                                        ? ""
                                                        : schedule.bg,
                                                      on: {
                                                        contextmenu: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.contextMenu(
                                                            $event,
                                                            schedule,
                                                            _vm.selectedDays[
                                                              hr - 1
                                                            ],
                                                            hour
                                                          )
                                                        },
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.viewSchedule(
                                                            schedule
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-semibold mb-2",
                                                          staticStyle: {
                                                            "font-size": "10px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              schedule.sequence
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      schedule.status ===
                                                        "ABSENCE" ||
                                                      schedule.status ===
                                                        "Bloqueado"
                                                        ? _c("feather-icon", {
                                                            class: {
                                                              "mr-1":
                                                                schedule.status ===
                                                                "Bloqueado",
                                                            },
                                                            attrs: {
                                                              icon: "SlashIcon",
                                                              svgClasses:
                                                                "w-4 h-4",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      schedule.vehicle &&
                                                      schedule.vehicle.type ===
                                                        "CAR"
                                                        ? _c(
                                                            "i",
                                                            {
                                                              staticClass:
                                                                "mr-1 material-icons-outlined flex items-center icon",
                                                              style: {
                                                                color:
                                                                  schedule.vehicle
                                                                    ? schedule
                                                                        .vehicle
                                                                        .identification_color ||
                                                                      "#000000"
                                                                    : "#000000",
                                                              },
                                                              attrs: {
                                                                title: "Carro",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "directions_car"
                                                              ),
                                                            ]
                                                          )
                                                        : schedule.vehicle &&
                                                          schedule.vehicle
                                                            .type ===
                                                            "MOTORCYCLE"
                                                        ? _c(
                                                            "i",
                                                            {
                                                              staticClass:
                                                                "mr-1 material-icons-outlined flex items-center icon",
                                                              style: {
                                                                color:
                                                                  schedule.vehicle
                                                                    ? schedule
                                                                        .vehicle
                                                                        .identification_color ||
                                                                      "#000000"
                                                                    : "#000000",
                                                              },
                                                              attrs: {
                                                                title: "Moto",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "sports_motorsports"
                                                              ),
                                                            ]
                                                          )
                                                        : schedule.vehicle &&
                                                          schedule.vehicle
                                                            .type === "TRUCK"
                                                        ? _c(
                                                            "i",
                                                            {
                                                              staticClass:
                                                                "mr-1 material-icons-outlined flex items-center icon",
                                                              style: {
                                                                color:
                                                                  schedule.vehicle
                                                                    ? schedule
                                                                        .vehicle
                                                                        .identification_color ||
                                                                      "#000000"
                                                                    : "#000000",
                                                              },
                                                              attrs: {
                                                                title:
                                                                  "Caminhão",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "directions_bus_filled"
                                                              ),
                                                            ]
                                                          )
                                                        : schedule.status !==
                                                          "Bloqueado"
                                                        ? _c(
                                                            "i",
                                                            {
                                                              staticClass:
                                                                "mr-1 material-icons-outlined flex items-center icon",
                                                              style: {
                                                                color:
                                                                  schedule.vehicle
                                                                    ? schedule
                                                                        .vehicle
                                                                        .identification_color ||
                                                                      "#000000"
                                                                    : "#000000",
                                                              },
                                                              attrs: {
                                                                title: "Ônibus",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "departure_board"
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            schedule.student &&
                                                              schedule.student.name
                                                                .split(" ")
                                                                .slice(0, 1)
                                                                .join(" ") +
                                                                " | "
                                                          ) +
                                                          "\n                  " +
                                                          _vm._s(
                                                            schedule.employee &&
                                                              schedule.employee.name
                                                                .split(" ")
                                                                .slice(0, 1)
                                                                .join(" ")
                                                          ) +
                                                          "\n                   \n                  "
                                                      ),
                                                      schedule.ibio &&
                                                      schedule.id_ibio
                                                        ? _c("div", {
                                                            staticClass:
                                                              "h-4 w-4 inline-block rounded-full bg-success",
                                                          })
                                                        : _vm._e(),
                                                      schedule.status ===
                                                      "Concluído"
                                                        ? _c("feather-icon", {
                                                            staticClass:
                                                              "text-success",
                                                            attrs: {
                                                              icon: "CheckIcon",
                                                              svgClasses:
                                                                "w-5 h-5",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            schedule.student &&
                                                              schedule.student
                                                                .defaulting
                                                              ? "$"
                                                              : ""
                                                          ) +
                                                          "\n                "
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                }),
                                _c(
                                  "vs-tr",
                                  {
                                    staticStyle: {
                                      opacity: "1 !important",
                                      border: "10px",
                                    },
                                  },
                                  [
                                    _c(
                                      "vs-td",
                                      {
                                        staticStyle: { "text-align": "right" },
                                        attrs: {
                                          colspan:
                                            _vm.handleRange(table).length + 1,
                                        },
                                      },
                                      [_c("vs-divider")],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c(
                        "template",
                        { staticStyle: { position: "sticky" }, slot: "thead" },
                        [
                          _c(
                            "vs-th",
                            {
                              staticStyle: {
                                "font-size": "11px",
                                width: "85px",
                              },
                            },
                            [_c("span", [_vm._v("HORÁRIO")])]
                          ),
                          _vm._l(_vm.handleRange(table), function (hr, i) {
                            return _c(
                              "vs-th",
                              {
                                key: i,
                                staticStyle: {
                                  "font-size": "11px",
                                  "min-width": "124px",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.weekDays[
                                        _vm.selectedDays[hr - 1].weekdayPosition
                                      ]
                                    ) +
                                    "\n              " +
                                    _vm._s(
                                      _vm.formatDate(
                                        _vm.selectedDays[hr - 1].id
                                      )
                                    ) +
                                    "\n                "
                                ),
                                _c(
                                  "vx-tooltip",
                                  {
                                    staticClass: "ml-2",
                                    attrs: {
                                      text: "Importar aulas do IBIO",
                                      position: "top",
                                    },
                                  },
                                  [
                                    _c("feather-icon", {
                                      staticClass: "cursor-pointer feather-min",
                                      attrs: { icon: "RefreshCwIcon" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.syncIbio(
                                            _vm.selectedDays[hr - 1]
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    2
                  )
                }),
                1
              ),
              !_vm.tables
                ? _c(
                    "div",
                    {
                      staticClass:
                        "mt-6 flex flex-wrap items-center justify-center",
                    },
                    [
                      _c(
                        "vs-alert",
                        {
                          staticClass: "w-5/6",
                          staticStyle: { height: "-webkit-fill-available" },
                          attrs: {
                            color: "warning",
                            title: "Defina o período",
                            active: !_vm.tables,
                          },
                        },
                        [
                          _vm._v(
                            "\n          Selecione a(s) data(s) no calendário ou defina um intervalo de datas\n          para ver os agendamentos conforme o filtro superior.\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _c("email-view", {
        attrs: { request: _vm.request, isSidebarActive: _vm.isSidebarActive },
        on: { delete: _vm.confirmDelete, closeSidebar: _vm.closeViewSidebar },
      }),
      _c("change-schedule-view", {
        attrs: {
          request: _vm.request,
          isSidebarActive: _vm.isChangeScheduleActive,
        },
        on: { delete: _vm.confirmDelete, closeSidebar: _vm.closeViewSidebar },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }