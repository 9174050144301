<template>
  <div>
    <div>
      Horários ocupados
    </div>
    <div class="inline-flex">
      <div v-for="(schedule, key) in schedules " :key="key">
        <div style="border: 1px solid #c0c0c0">
          <div>{{ schedule.start_time }}</div>
          <div class="text-center">a</div>
          <div>{{ schedule.end_time }}</div>
        </div>
      </div>
    </div>
    <vs-divider />
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import axios from '@/axios'

export default {
  components: {
  },
  data () {
    return {
      schedules: []
    }
  },
  methods: {
    fetchTimes () {
      this.$vs.loading()
      axios.post(`${process.env.VUE_APP_API_URL}/schedule/available`, {
        student_id: 784282,
        type: 'practical',
        employee_id: 388,
        start_date: '2022-07-27',
        vehicle_id: 289,
        category: 'A'
      })
        .then(resp => {
          this.schedules = resp.data
        })
        .catch(this.mixinCatch)
        .finally(this.mixinFinally)
    }
  },
  created () {
    this.fetchTimes()
  }
}
</script>
