var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mail__mail-item sm:px-4 px-2 py-6",
      class: { "mail__opened-mail": !_vm.mail.unread },
    },
    [
      _c(
        "div",
        { staticClass: "flex w-full" },
        [
          _c("vs-avatar", {
            staticClass:
              "sender__avatar flex-shrink-0 mr-3 border-2 border-solid border-white",
            attrs: { src: _vm.mail.img, size: "40px" },
          }),
          _c(
            "div",
            { staticClass: "flex w-full justify-between items-start" },
            [
              _c("div", { staticClass: "mail__details" }, [
                _c(
                  "h5",
                  {
                    staticClass: "mb-1",
                    class: { "font-semibold": _vm.mail.unread },
                  },
                  [_vm._v(_vm._s(_vm.mail.sender_name))]
                ),
                _vm.mail.subject
                  ? _c("span", [_vm._v(_vm._s(_vm.mail.subject))])
                  : _c("span", [_vm._v("(no subject)")]),
              ]),
              _c("div", { staticClass: "mail-item__meta flex items-center" }, [
                _c(
                  "div",
                  { staticClass: "email__labels hidden sm:flex items-center" },
                  _vm._l(_vm.mail.labels, function (label, index) {
                    return _c("div", {
                      key: index,
                      staticClass: "h-2 w-2 rounded-full mr-2",
                      class: "bg-" + _vm.labelColor(label),
                    })
                  }),
                  0
                ),
                _c("span", [_vm._v(_vm._s(_vm._f("date")(_vm.mail.time)))]),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "flex w-full" }, [
        _c(
          "div",
          { staticClass: "flex items-center ml-1" },
          [
            _c("vs-checkbox", {
              staticClass: "vs-checkbox-small ml-0 mr-1",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
              model: {
                value: _vm.isSelectedMail,
                callback: function ($$v) {
                  _vm.isSelectedMail = $$v
                },
                expression: "isSelectedMail",
              },
            }),
            _c("feather-icon", {
              staticClass: "cursor-pointer",
              attrs: {
                icon: "StarIcon",
                svgClasses: [
                  {
                    "text-warning fill-current stroke-current":
                      _vm.mail.isStarred,
                  },
                  "w-5",
                  "h-5",
                ],
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.toggleIsStarred.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "mail__message truncate ml-3" }, [
          _c("span", [_vm._v(_vm._s(_vm._f("filter_tags")(_vm.mail.message)))]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }