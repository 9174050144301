var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full" },
    [
      _c("label", { staticClass: "vs-input--label" }, [_vm._v("Veículo")]),
      _c("v-select", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate.initial",
            value: "required",
            expression: "'required'",
            modifiers: { initial: true },
          },
        ],
        staticClass: "fix-vselect",
        staticStyle: { "background-color": "white" },
        attrs: {
          multiple: _vm.multiple,
          placeholder: "Selecione",
          filterable: true,
          reduce: (option) => option.value,
          options: _vm.allOptions,
          "data-vv-as": "Veículo",
          appendToBody: _vm.appendToBody,
          autoscroll: false,
          name: "single",
          dir: _vm.$vs.rtl ? "rtl" : "ltr",
          disabled: _vm.disabled,
        },
        scopedSlots: _vm._u([
          {
            key: "no-options",
            fn: function ({}) {
              return [_vm._v(" Nenhum resultado encontrado. ")]
            },
          },
          {
            key: "option",
            fn: function (option) {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "text-black flex items-center hover:text-primary",
                  },
                  [
                    _c(
                      "i",
                      {
                        staticClass:
                          "mr-1 material-icons-outlined flex items-center icon",
                        style: {
                          color: option.data.identification_color,
                        },
                        attrs: { title: "Moto" },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(option.data.icon) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(
                      "\n          " + _vm._s(option.label) + "\n        "
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.vehicle,
          callback: function ($$v) {
            _vm.vehicle = $$v
          },
          expression: "vehicle",
        },
      }),
      _vm.required
        ? _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("single"),
                  expression: "errors.has('single')",
                },
              ],
              staticClass: "text-danger text-sm",
            },
            [_vm._v(_vm._s(_vm.errors.first("single")))]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }