<template>
  <div>
    <vs-table
      noDataText="Nenhum Agendamento."
      class="bordered"
      hoverFlat
      style="overflow: -webkit-paged-y"
      :data="data"
    >
      <template slot="thead">
        <vs-th style="font-size: 11px; width: 40px">
          <vs-checkbox
            v-model="checkboxSelectAll"
            :disabled="!data.length"
            icon-pack="feather"
            :icon="selectAllIcon"
            class="select-all-chexkbox"
          ></vs-checkbox>
        </vs-th>
        <vs-th style="font-size: 11px; width: 40px"><span>#</span></vs-th>
        <vs-th style="font-size: 11px"><span>DATA</span></vs-th>
        <vs-th style="font-size: 11px"><span>HORÁRIO</span></vs-th>
        <vs-th style="font-size: 11px"><span>INSTRUTOR</span></vs-th>
        <vs-th style="font-size: 11px"><span>VEÍCULO</span></vs-th>
        <vs-th style="font-size: 11px; width: 50px"><span>CAT.</span></vs-th>
        <vs-th style="font-size: 11px"><span>Observação</span></vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr
          style="font-size: 12px; opacity: 1 !important"
          :key="idx"
          v-for="(schedule, idx) in data"
          :state="schedule.status === 'canceled' ? 'danger' : null"
        >
          <vs-td>
            <vs-checkbox
              v-model="selectedSchedules"
              :vs-value="schedule"
              @input="checkIfAllowSelect"
              class="vs-checkbox-small ml-0 mr-1"
            />
          </vs-td>
          <vs-td>
            <span class="cursor-default font-semibold">{{ idx + 1 }}</span>
          </vs-td>
          <vs-td>
            <span class="cursor-default font-semibold">
              {{ mixinConvertDateStringUsToBr(schedule.start_date) }}
            </span>
          </vs-td>
          <vs-td>
            <span class="cursor-pointer font-semibold">
              {{ schedule.start_time}}-{{ schedule.end_time}}
            </span>
          </vs-td>
          <vs-td>
            <span
              class="cursor-default font-semibold"
              v-if="schedule.employee"
              >{{ schedule.employee.name }}</span
            >
          </vs-td>
          <vs-td>
            <span class="cursor-default font-semibold" v-if="schedule.vehicle">
              {{ schedule.vehicle.brand }}
              {{ schedule.vehicle.model }}
              {{ schedule.vehicle.license_plate }}
            </span></vs-td
          >
          <vs-td style="text-align: center">
            <span class="cursor-default font-semibold">
              {{ schedule.category }}
            </span>
          </vs-td>
          <vs-td style="text-align: center">
            <span class="cursor-default font-semibold">
              {{ schedule.observation }}
            </span>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import axios from '@/axios'
export default {
  props: {
    value: {},
    data: {}
  },
  data () {
    return {
      editedSchedule: null,
      hourOptions: []
    }
  },
  computed: {
    checkboxSelectAll: {
      get () {
        return this.selectedSchedules.length
      },
      set (value) {
        this.selectedSchedules = value
          ? this.data.filter((e) => e.status !== 'canceled')
          : []
      }
    },
    selectedSchedules: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    selectAllIcon () {
      return this.selectedSchedules.length === this.data.length
        ? 'icon-check'
        : 'icon-minus'
    }
  },
  methods: {
    checkIfAllowSelect () {
      this.$nextTick(() => {
        this.selectedSchedules = this.selectedSchedules.filter(
          (e) => e.status !== 'canceled'
        )
      })
    },
    getOperation (status) {
      if (status === 'Bloqueado') return 'Bloquear'
      if (status === 'canceled') return 'Nenhuma'
      return 'Agendar'
    },
    changedSchedule (schedule) {
      this.fetchAvailableSchedules(schedule)
    },
    fetchAvailableSchedules (schedule) {
      if (this.editedSchedule === schedule) return
      this.editedSchedule = schedule
      this.$vs.loading()
      axios
        .post(`${process.env.VUE_APP_API_URL}/schedule/available`, schedule)
        .then((resp) => {
          this.hourOptions = resp.data.map(
            (e) => `${e.start_time}-${e.end_time}`
          )
        })
        .catch(this.mixinCatch)
        .finally(this.mixinFinally)
    },
    changeHourSchedule (schedule, times) {
      const time = times.split('-')
      schedule.start_time = time[0]
      schedule.end_time = time[1]
      schedule.status = 'Não concluído'
      schedule.observation = ''
    }
  }
}
</script>
