<template>
  <div
    id="schedule-practical"
    class="
      border border-solid
      d-theme-border-grey-light
      rounded
      relative
    "
  >
  <sync-ibio-schedule ref="syncIbio" />
    <vs-popup :active.sync="popupMultiScheduleCreator" title="Agendamento em massa" fullscreen>
      <multi-schedule-creator ref="multiSchedule" @saved="closeMultiSchedulePopup" />
    </vs-popup>
     <sig-prompt :active.sync="popupInputClassQuantity" title="Replicar aulas" @accept="replicateSchedules">
      <input-class-quantity v-model="quantityForMultiSchedule" :max="maxQuantityClassAvailable" />
    </sig-prompt>
    <vs-popup
      class="popup80 noOverflow"
      :fullscreen="false"
      :title="showPdfTitle"
      :active.sync="popupShowPdf"
    >
      <object :data="srcPdf" width="100%" style="height: 600px" />
    </vs-popup>

    <vs-sidebar
      class="items-no-padding"
      parent="#schedule-practical"
      :click-not-close="clickNotClose"
      :hidden-background="clickNotClose"
      v-model="isEmailSidebarActive"
    >
      <email-sidebar
        @closeLoading="closeLoading"
        @filterSchedule="filterSchedule"
        @closeSidebar="toggleEmailSidebar"
      />
    </vs-sidebar>

    <!-- MENU DE CONTEXTO -->
    <vue-context ref="menu" :closeOnClick="closeOnClick">
      <li v-if="contextAdd">
        <a
          href="#"
          @click="contextMenuClicked('ADD')"
          class="flex items-center text-sm hover:text-primary"
        >
          <feather-icon icon="PlusCircleIcon" svgClasses="w-5 h-5" />
          <span class="ml-2">Novo Agendamento</span>
        </a>
      </li>
      <li v-if="contextAbsence">
        <a
          href="#"
          @click="contextMenuClicked('ABSENCE')"
          class="flex items-center text-sm hover:text-warning"
        >
          <feather-icon icon="SlashIcon" svgClasses="w-5 h-5" />
          <span class="ml-2">Falta sem Justificativa</span>
        </a>
      </li>
      <li v-if="contextReplicate">
        <a
          href="#"
          @click="openInputClassQuantityPopup"
          class="flex items-center text-sm hover:text-warning"
        >
          <feather-icon icon="CopyIcon" svgClasses="w-5 h-5" />
          <span class="ml-2">Replicar</span>
        </a>
      </li>
      <li v-if="contextDelete">
        <a
          href="#"
          @click="contextMenuClicked('DELETE')"
          class="flex items-center text-sm hover:text-danger"
        >
          <feather-icon icon="MinusCircleIcon" svgClasses="w-5 h-5" />
          <span class="ml-2">Excluir</span>
        </a>
      </li>
      <li v-if="contextBlockEmployee">
        <vs-textarea
          v-model="blockEmployeeMotivation"
          placeholder="Motivo do Bloqueio"
          class="mb-4"
          counter="120"
          maxlength="120"
          rows="5"
        />

        <a
          href="#"
          @click.prevent="contextMenuClicked('BLOCK_EMPLOYEE')"
          class="flex items-center text-sm hover:text-danger"
        >
          <feather-icon icon="SlashIcon" svgClasses="w-5 h-5" />
          <span class="ml-2">Bloquear Instrutor Selecionado</span>
        </a>
      </li>
    </vue-context>

    <div
      :class="{ 'sidebar-spacer': clickNotClose }"
      class="
        no-scroll-content
        border border-solid
        d-theme-border-grey-light
        border-r-0 border-t-0 border-b-0
      "
    >
      <!-- SEARCH BAR AND MENU ICON -->
      <div class="flex border d-theme-dark-bg items-center">
        <feather-icon
          class="md:inline-flex lg:hidden ml-4 mr-4 cursor-pointer"
          icon="MenuIcon"
          @click.stop="toggleEmailSidebar(true)"
        />
      </div>

      <!-- EMAIL ACTION BAR -->
      <div
        class="
          email__actions
          p-2
          border border-r-0 border-l-0 border-solid
          d-theme-border-grey-light
        "
      >
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full mt-0">
            <label class="vs-input--label">Aluno</label>
            <v-select
              v-model="student_id"
              @input="changeStudent()"
              @search="debouncedGetSearchStudent"
              :clearable="true"
              :filterable="false"
              style="background-color: white"
              :reduce="(option) => option.value"
              :options="studentOptions"
              placeholder="Digite cpf ou nome do aluno..."
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
            >
              <template #no-options="{}">
                Nenhum resultado encontrado.
              </template>
            </v-select>
          </div>
          <div class="vx-col md:w-1/4 w-full mt-0">
            <label class="vs-input--label">Instrutor</label>
            <v-select
              v-model="employee_id"
              @input="changeEmployee()"
              :reduce="(option) => option.value"
              :options="employees"
              placeholder="Selecione"
              style="background-color: white"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
            >
              <template #no-options="{}">
                Desculpe, nenhum resultado encontrado.
              </template>
            </v-select>
          </div>
          <div class="vx-col md:w-1/4 w-full mt-0">
            <select-vehicle
              v-model="vehicle"
              @input="changeVehicle()"
             />
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col md:w-1/6 w-full mt-0">
            <label class="vs-input--label">Cat.</label>
            <v-select
              v-model="category"
              @input="changeCategory()"
              :clearable="false"
              style="background-color: white"
              :reduce="(option) => option.value"
              :options="categoryCNHOptions"
              placeholder="Selecione"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
            >
              <template #no-options="{}">
                Nenhum resultado encontrado.
              </template>
            </v-select>
          </div>
          <div class="vx-col md:w-1/4 w-full mt-0">
            <label class="vs-input--label">Tipo de Serviço</label>
            <v-select
              v-model="type_service"
              :clearable="true"
              style="background-color: white"
              :reduce="(option) => option.value"
              :options="typeServiceOptions"
              placeholder="Selecione"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
            >
              <template #no-options="{}">
                Nenhum resultado encontrado.
              </template>
            </v-select>
          </div>

          <div class="vx-col md:w-1/6 w-full mt-0">
            <label class="vs-input--label">Qtd.</label>
            <v-select
              v-model="quantity"
              class="w-full"
              style="background-color: white"
              :reduce="(option) => option.value"
              :clearable="false"
              :options="[
                { value: '1', label: '1' },
                { value: '2', label: '2' },
                { value: '3', label: '3' }
              ]"
              placeholder=""
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
            >
              <template #no-options="{}">
                Nenhum resultado encontrado.
              </template>
            </v-select>
          </div>

          <div class="vx-col md:w-auto mt-0">
            <div class="mt-3 flex flex-wrap items-center">
              <vx-tooltip
                v-if="disabledIbio"
                text="Configure o acesso ao IBio"
                position="top"
                class="ml-0 mt-2"
              >
                <feather-icon
                  icon="InfoIcon"
                  svgClasses="h-5 w-5 stroke-current text-grey"
                  class="block"
                />
              </vx-tooltip>
              <vs-checkbox
                v-model="ibio"
                class="ml-0 mt-2"
                :disabled="disabledIbio"
                >IBio</vs-checkbox
              >

              <!-- CATEGORIA A -->
              <span
                v-if="scheduledClasses"
                class="ml-6 font-semibold"
                style="font-size: 10px"
              >
                Total A: {{ scheduledClasses }} / {{ contractedSchedules }}
                <br />
                Disponível A:
                <span :class="{ 'text-danger': availableSchedules < 0 }">{{
                  availableSchedules
                }}</span>
                <br />
                Faltas A: {{ absences }}<br />
                &nbsp;
                <!-- REMOVIDO PELA NOVA LEGISLAÇÃO -->
                <!-- Noturno: {{ nightSchedules }} / {{ nightSchedulesRequired }} -->
                <!-- Noturno: {{ nightSchedules }} -->
              </span>

              <!-- CATEGORIA B -->
              <span
                v-if="scheduledClassesB"
                class="ml-6 font-semibold"
                style="font-size: 10px"
              >
                Total B: {{ scheduledClassesB }} / {{ contractedSchedules }}
                <br />
                Disponível B:
                <span :class="{ 'text-danger': availableSchedulesB < 0 }">{{
                  availableSchedulesB
                }}</span>
                <br />
                Faltas B: {{ absences }}<br />
                &nbsp;
              </span>

              <!-- CATEGORIA C -->
              <span
                v-if="scheduledClassesC"
                class="ml-6 font-semibold"
                style="font-size: 10px"
              >
                Total C: {{ scheduledClassesC }} / {{ contractedSchedules }}
                <br />
                Disponível C:
                <span :class="{ 'text-danger': availableSchedulesC < 0 }">{{
                  availableSchedulesC
                }}</span>
                <br />
                Faltas C: {{ absences }}<br />
                &nbsp;
              </span>

              <!-- CATEGORIA D -->
              <span
                v-if="scheduledClassesD"
                class="ml-6 font-semibold"
                style="font-size: 10px"
              >
                Total D: {{ scheduledClassesD }} / {{ contractedSchedules }}
                <br />
                Disponível D:
                <span :class="{ 'text-danger': availableSchedulesD < 0 }">{{
                  availableSchedulesD
                }}</span>
                <br />
                Faltas D: {{ absences }}<br />
                &nbsp;
              </span>

              <!-- CATEGORIA E -->
              <span
                v-if="scheduledClassesE"
                class="ml-6 font-semibold"
                style="font-size: 10px"
              >
                Total E: {{ scheduledClassesE }} / {{ contractedSchedules }}
                <br />
                Disponível E:
                <span :class="{ 'text-danger': availableSchedulesE < 0 }">{{
                  availableSchedulesE
                }}</span>
                <br />
                Faltas E: {{ absences }}<br />
                &nbsp;
              </span>

              <!-- CATEGORIA ACC -->
              <span
                v-if="scheduledClassesACC"
                class="ml-6 font-semibold"
                style="font-size: 10px"
              >
                Total ACC: {{ scheduledClassesACC }} /
                {{ contractedSchedules }} <br />
                Disponível ACC:
                <span :class="{ 'text-danger': availableSchedulesACC < 0 }">{{
                  availableSchedulesACC
                }}</span>
                <br />
                Faltas ACC: {{ absences }}<br />
                &nbsp;
              </span>

              <vx-tooltip
                text="Período do Relatório"
                position="top"
                class="ml-2 mr-2 mt-1"
              >
                <vs-dropdown
                  vs-custom-content
                  vs-trigger-click
                  class="cursor-pointer hover:text-primary"
                >
                  <a href.prevent class="flex cursor-pointer">
                    <feather-icon
                      icon="CalendarIcon"
                      svgClasses="h-6 w-6"
                      class="p-2 rounded-full"
                      :class="[`text-primary`, 'mb-0']"
                      :style="{ background: `rgba(var(--vs-success),.15)` }"
                    ></feather-icon>
                    <!-- <feather-icon icon="CalendarIcon" svgClasses="h-6 w-6" class="mt-1 ml-3" /> -->
                  </a>
                  <vs-dropdown-menu class="w-60" style="z-index: 200001">
                    <vs-input
                      type="date"
                      v-model="reportFirstPeriod"
                      class="w-full"
                      label="Data Inicial"
                    />
                    <vs-input
                      type="date"
                      v-model="reportLastPeriod"
                      class="w-full"
                      label="Data Final"
                    />
                    <span class="text-danger text-sm" v-show="periodError"
                      >O período inicial é maior que o final.</span
                    >
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vx-tooltip>
              <vx-tooltip
                text="Relatório Excel"
                position="top"
                class="mt-2 mr-2"
              >
                <svg
                  @click="print('EXCEL')"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  class="svg-hover h-8 w-8 cursor-pointer"
                  aria-hidden="true"
                  focusable="false"
                  width="1em"
                  height="1em"
                  style="
                    -ms-transform: rotate(360deg);
                    -webkit-transform: rotate(360deg);
                    transform: rotate(360deg);
                  "
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M23 1.5q.41 0 .7.3q.3.29.3.7v19q0 .41-.3.7q-.29.3-.7.3H7q-.41 0-.7-.3q-.3-.29-.3-.7V18H1q-.41 0-.7-.3q-.3-.29-.3-.7V7q0-.41.3-.7Q.58 6 1 6h5V2.5q0-.41.3-.7q.29-.3.7-.3zM6 13.28l1.42 2.66h2.14l-2.38-3.87l2.34-3.8H7.46l-1.3 2.4l-.05.08l-.04.09l-.64-1.28l-.66-1.29H2.59l2.27 3.82l-2.48 3.85h2.16zM14.25 21v-3H7.5v3zm0-4.5v-3.75H12v3.75zm0-5.25V7.5H12v3.75zm0-5.25V3H7.5v3zm8.25 15v-3h-6.75v3zm0-4.5v-3.75h-6.75v3.75zm0-5.25V7.5h-6.75v3.75zm0-5.25V3h-6.75v3z"
                  />
                </svg>
              </vx-tooltip>
              <vx-tooltip text="Relatório PDF" position="top" class="mt-2">
                <svg
                  @click="doRelatorioPdf()"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  class="svg-hover h-8 w-8 cursor-pointer"
                  aria-hidden="true"
                  focusable="false"
                  width="0.86em"
                  height="1em"
                  style="
                    -ms-transform: rotate(360deg);
                    -webkit-transform: rotate(360deg);
                    transform: rotate(360deg);
                  "
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 1536 1792"
                >
                  <path
                    d="M1468 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28H96q-40 0-68-28t-28-68V96q0-40 28-68T96 0h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528V640H992q-40 0-68-28t-28-68V128H128v1536h1280zm-514-593q33 26 84 56q59-7 117-7q147 0 177 49q16 22 2 52q0 1-1 2l-2 2v1q-6 38-71 38q-48 0-115-20t-130-53q-221 24-392 83q-153 262-242 262q-15 0-28-7l-24-12q-1-1-6-5q-10-10-6-36q9-40 56-91.5t132-96.5q14-9 23 6q2 2 2 4q52-85 107-197q68-136 104-262q-24-82-30.5-159.5T657 552q11-40 42-40h22q23 0 35 15q18 21 9 68q-2 6-4 8q1 3 1 8v30q-2 123-14 192q55 164 146 238zm-576 411q52-24 137-158q-51 40-87.5 84t-49.5 74zm398-920q-15 42-2 132q1-7 7-44q0-3 7-43q1-4 4-8q-1-1-1-2q-1-2-1-3q-1-22-13-36q0 1-1 2v2zm-124 661q135-54 284-81q-2-1-13-9.5t-16-13.5q-76-67-127-176q-27 86-83 197q-30 56-45 83zm646-16q-24-24-140-24q76 28 124 28q14 0 18-1q0-1-2-3z"
                  />
                </svg>
              </vx-tooltip>
              <vx-tooltip text="Mudança na agenda" position="top" class="mt-2">
                <feather-icon
                  icon="ToolIcon"
                  @click="changeScheduleShow"
                  class="cursor-pointer"
                />
              </vx-tooltip>
            </div>
          </div>
        </div>
      </div>

      <!-- SCHEDULE -->
      <component
        :is="scrollbarTag"
        class="email-content-scroll-area"
        :settings="settings"
        ref="mailListPS"
        :key="$vs.rtl"
      >
        <div class="vs-con-loading__container" id="loading">
          <vs-table
            v-for="(table, tIndex) in tables"
            :key="tIndex"
            :data="selectedDays"
            class="bordered"
            hoverFlat
            style="overflow: -webkit-paged-y"
          >
            <template slot="thead" style="position: sticky">
              <vs-th style="font-size: 11px; width: 85px"
                ><span>HORÁRIO</span></vs-th
              >
              <vs-th
                style="font-size: 11px; min-width: 124px"
                v-for="(hr, i) in handleRange(table)"
                :key="i"
              >
                {{ weekDays[selectedDays[hr - 1].weekdayPosition] }}
                {{ formatDate(selectedDays[hr - 1].id) }}
                  <vx-tooltip text="Importar aulas do IBIO" position="top" class="ml-2">
                  <feather-icon
                    icon="RefreshCwIcon"
                    @click="syncIbio(selectedDays[hr - 1])"
                    class="cursor-pointer feather-min"
                  />
              </vx-tooltip>
              </vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr
                v-for="(hour, i) in hours"
                :key="i"
                style="font-size: 12px; opacity: 1 !important"
              >
                <vs-td style="text-align: right"
                  ><span class="cursor-default font-semibold">{{
                    hour.slice(0, 5)
                  }}</span>
                </vs-td>
                <vs-td
                  v-for="(hr, i) in handleRange(table)"
                  :key="i"
                  class="cursor-pointer"
                  :style="
                    (fillSchedule(selectedDays[hr - 1], hour).length === 1 &&
                      fillSchedule(selectedDays[hr - 1], hour)[0].bg) ||
                    ''
                  "
                  @click.native="addSchedule(selectedDays[hr - 1], hour)"
                  @contextmenu.native.prevent.self="
                    contextMenu($event, null, selectedDays[hr - 1], hour)
                  "
                >
                  <feather-icon
                    v-for="(holiday, hd) in selectedDays[hr - 1]
                      .holidaySchedule"
                    :key="`${hd}_holiday`"
                    :title="holiday.holidayName"
                    class="mr-1"
                    icon="StarIcon"
                    svgClasses="w-4 h-4"
                  />
                  <div
                    v-for="(schedule, idx) in fillSchedule(
                      selectedDays[hr - 1],
                      hour
                    )"
                    :key="`${idx}_schedule`"
                    class="text-black flex items-center hover:text-primary"
                    @contextmenu.prevent="
                      contextMenu($event, schedule, selectedDays[hr - 1], hour)
                    "
                    @click.stop="viewSchedule(schedule)"
                    :style="schedule.fill ? '' : schedule.bg"
                  >
                    <span class="font-semibold mb-2" style="font-size: 10px">{{
                      schedule.sequence
                    }}</span>

                    <feather-icon
                      v-if="
                        schedule.status === 'ABSENCE' ||
                        schedule.status === 'Bloqueado'
                      "
                      :class="{ 'mr-1': schedule.status === 'Bloqueado' }"
                      icon="SlashIcon"
                      svgClasses="w-4 h-4"
                    />
                    <i
                      v-if="schedule.vehicle && schedule.vehicle.type === 'CAR'"
                      class="
                        mr-1
                        material-icons-outlined
                        flex
                        items-center
                        icon
                      "
                      :style="{
                        color: schedule.vehicle
                          ? schedule.vehicle.identification_color || '#000000'
                          : '#000000'
                      }"
                      title="Carro"
                      >directions_car</i
                    >
                    <i
                      v-else-if="
                        schedule.vehicle &&
                        schedule.vehicle.type === 'MOTORCYCLE'
                      "
                      class="
                        mr-1
                        material-icons-outlined
                        flex
                        items-center
                        icon
                      "
                      :style="{
                        color: schedule.vehicle
                          ? schedule.vehicle.identification_color || '#000000'
                          : '#000000'
                      }"
                      title="Moto"
                      >sports_motorsports</i
                    >
                    <i
                      v-else-if="
                        schedule.vehicle && schedule.vehicle.type === 'TRUCK'
                      "
                      class="
                        mr-1
                        material-icons-outlined
                        flex
                        items-center
                        icon
                      "
                      :style="{
                        color: schedule.vehicle
                          ? schedule.vehicle.identification_color || '#000000'
                          : '#000000'
                      }"
                      title="Caminhão"
                      >directions_bus_filled</i
                    >
                    <i
                      v-else-if="schedule.status !== 'Bloqueado'"
                      class="
                        mr-1
                        material-icons-outlined
                        flex
                        items-center
                        icon
                      "
                      :style="{
                        color: schedule.vehicle
                          ? schedule.vehicle.identification_color || '#000000'
                          : '#000000'
                      }"
                      title="Ônibus"
                      >departure_board</i
                    >
                    {{
                      schedule.student &&
                      schedule.student.name.split(' ').slice(0, 1).join(' ') +
                        ' | '
                    }}
                    {{
                      schedule.employee &&
                      schedule.employee.name.split(' ').slice(0, 1).join(' ')
                    }}
                    &nbsp;
                    <div
                      v-if="schedule.ibio && schedule.id_ibio"
                      class="h-4 w-4 inline-block rounded-full bg-success"
                    ></div>
                    <feather-icon
                      v-if="schedule.status === 'Concluído'"
                      class="text-success"
                      icon="CheckIcon"
                      svgClasses="w-5 h-5"
                    />
                    {{ schedule.student && schedule.student.defaulting ? '$' : '' }}
                  </div>
                </vs-td>
              </vs-tr>

              <vs-tr style="opacity: 1 !important; border: 10px">
                <vs-td
                  :colspan="handleRange(table).length + 1"
                  style="text-align: right"
                >
                  <vs-divider></vs-divider>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
        <div
          v-if="!tables"
          class="mt-6 flex flex-wrap items-center justify-center"
        >
          <vs-alert
            class="w-5/6"
            style="height: -webkit-fill-available"
            color="warning"
            title="Defina o período"
            :active="!tables"
          >
            Selecione a(s) data(s) no calendário ou defina um intervalo de datas
            para ver os agendamentos conforme o filtro superior.
          </vs-alert>
        </div>
      </component>
    </div>

    <!-- EMAIL VIEW SIDEBAR -->
    <email-view
      :request="request"
      :isSidebarActive="isSidebarActive"
      @delete="confirmDelete"
      @closeSidebar="closeViewSidebar"
    >
    </email-view>
    <change-schedule-view
      :request="request"
      :isSidebarActive="isChangeScheduleActive"
      @delete="confirmDelete"
      @closeSidebar="closeViewSidebar"
    >
    </change-schedule-view>
  </div>
</template>

<script>
import EmailSidebar from './EmailSidebar.vue'
import _ from 'lodash'
import { VueContext } from 'vue-context'
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')
import moment from 'moment'

import MailItem from './MailItem.vue'
import EmailView from './EmailView.vue'
import ChangeScheduleView from './ChangeScheduleView.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import moduleSchedule from '@/store/schedule/moduleSchedule.js'
import moduleEmployee from '@/store/employee/moduleEmployee.js'

import moduleVehicle from '@/store/vehicle/moduleVehicle.js'
import moduleServicePackage from '@/store/service-package/moduleServicePackage.js'
import moduleStudentManagement from '@/store/student-management/moduleStudentManagement.js'

import { getEmployee as getEmployeeFromVehicle, employeeEstaCadastradoNesteCarro } from '@/helpers/vehicleHelper.js'
import { instrutorEnsinaNaCategoria } from '@/helpers/employeeHelper.js'
import InputClassQuantity from '@/components/schedule/practical/InputClassQuantity.vue'
import MultiScheduleCreator from '@/components/schedule/practical/MultiScheduleCreator.vue'
import SelectAvailableTime from '@/components/schedule/practical/SelectAvailableTime.vue'
import SyncIbioSchedule from '@/components/schedule/ibio/SyncIbioSchedule.vue'
import SelectVehicle from '@/components/register/vehicle/SelectVehicle.vue'

export default {
  components: {
    MailItem,
    SelectVehicle,
    EmailSidebar,
    EmailView,
    ChangeScheduleView,
    InputClassQuantity,
    VuePerfectScrollbar,
    MultiScheduleCreator,
    SelectAvailableTime,
    VueContext,
    SyncIbioSchedule,
    vSelect
  },
  data () {
    return {
      scheduleForReplicate: null,
      maxQuantityClassAvailable: 1,
      quantityForMultiSchedule: 1,
      popupMultiScheduleCreator: false,
      idxHour: -1,
      closeOnClick: true,
      addScheduleParam: {},
      studentData: {},
      blockEmployeeMotivation: '',
      showPdfTitle: 'PDF',
      srcPdf: '',
      popupShowPdf: false,
      quantity: 1,
      request: false,
      contextAdd: false,
      contextAbsence: false,
      contextDelete: false,
      contextReplicate: false,
      contextBlockEmployee: false,
      popupInputClassQuantity: false,
      selectedDay: null,
      selectedHour: null,

      isSidebarActive: false,
      isChangeScheduleActive: false,
      showThread: false,
      clickNotClose: true,
      isEmailSidebarActive: true,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3
      },
      weekDays: [0, 'SEG.', 'TER.', 'QUA.', 'QUI.', 'SEX.', 'SÁB.', 'DOM.'],
      category: null,
      categoryCNHOptionsExistentes: [
        { label: 'A', value: 'A' },
        { label: 'B', value: 'B' },
        { label: 'C', value: 'C' },
        { label: 'D', value: 'D' },
        { label: 'E', value: 'E' },
        { label: 'ACC', value: 'ACC' }
      ],
      student_id: null,
      student_name: null,
      studentOptions: [],
      employee_id: null,
      vehicle: null,
      ibio: false,
      disabledIbio: false,
      type: 'practical',
      status: 'Não Concluído',
      type_service: null,
      typeServiceOptions: [
        { label: '1ª Habilitação', value: '1ª Habilitação' },
        { label: 'Adição de categoria', value: 'Adição de categoria' },
        { label: 'Mudança de categoria', value: 'Mudança de categoria' },
        { label: 'Permissão ACC', value: 'Permissão ACC' },
        { label: 'Aula avulsa', value: 'Aula avulsa' }
      ],
      reportFirstPeriod: '',
      reportLastPeriod: '',
      periodError: false
    }
  },
  watch: {
    selectedDays (value) {
      // if (value.length) {
      //   this.openLoading()
      //   this.debouncedGetFilterSchedule()
      // }
      // AO TROCAR O PERÍODO DE DATAS DO CALENDÁRIO ZERO O PERÍODO MANUAL DO RELATÓRIO
      this.reportFirstPeriod = ''
      this.reportLastPeriod = ''
    },
    isSidebarActive (value) {
      if (!value) this.showThread = false
    },
    windowWidth () {
      this.setSidebarWidth()
    }
  },
  computed: {
    categoryCNHOptions () { // filtra categorias de acordo com o contratado pelo aluno
      if (!this.studentData) {
        return this.categoryCNHOptionsExistentes
      }

      if (!this.studentData.intended_category_cnh) {
        return this.categoryCNHOptionsExistentes
      }

      const categoriasDisponiveisParaAluno = []

      for (let i = 0; i < this.categoryCNHOptionsExistentes.length; i++) {
        if (this.studentData.intended_category_cnh.includes(this.categoryCNHOptionsExistentes[i].value)) {
          categoriasDisponiveisParaAluno.push(this.categoryCNHOptionsExistentes[i])
        }
      }
      return categoriasDisponiveisParaAluno
    },
    user () {
      return this.$store.state.AppActiveUser
    },
    schedule () {
      return this.$store.state.schedule.schedule
    },
    contractedSchedules () {
      const student = this.studentOptions.find(
        (o) => o.value === this.student_id
      )
      if (student) {
        // 1ª habilitação Cat A e B 20hs sendo 1 noturna
        if (this.type_service === '1ª Habilitação') return 20
        //Adiçao cat A e B 15h aula sendo 1 noturna
        else if (this.type_service === 'Adição de categoria') return 15
        // Mudança de categoria de C,D,E  20h no mínimo 4 not
        else if (this.type_service === 'Mudança de categoria') return 20
        // ACC 5h ao menos 4 noturnas
        else if (this.type_service === 'Permissão ACC') return 5
      }
      return 0
    },
    availableSchedules () {
      return this.contractedSchedules - this.scheduledClasses
    },
    availableSchedulesB () {
      return this.contractedSchedules - this.scheduledClassesB
    },
    availableSchedulesC () {
      return this.contractedSchedules - this.scheduledClassesC
    },
    availableSchedulesD () {
      return this.contractedSchedules - this.scheduledClassesD
    },
    availableSchedulesE () {
      return this.contractedSchedules - this.scheduledClassesE
    },
    availableSchedulesACC () {
      return this.contractedSchedules - this.scheduledClassesACC
    },
    scheduledClasses () {
      const student = this.studentOptions.find(
        (o) => o.value === this.student_id
      )
      if (student) return student.data.schedules.filter(
        (o) => o.type === this.type && o.category === 'A'
      ).length
      return 0
    },
    scheduledClassesB () {
      const student = this.studentOptions.find(
        (o) => o.value === this.student_id
      )
      if (student) return student.data.schedules.filter(
        (o) => o.type === this.type && o.category === 'B'
      ).length
      return 0
    },
    scheduledClassesC () {
      const student = this.studentOptions.find(
        (o) => o.value === this.student_id
      )
      if (student) return student.data.schedules.filter(
        (o) => o.type === this.type && o.category === 'C'
      ).length
      return 0
    },
    scheduledClassesD () {
      const student = this.studentOptions.find(
        (o) => o.value === this.student_id
      )
      if (student) return student.data.schedules.filter(
        (o) => o.type === this.type && o.category === 'D'
      ).length
      return 0
    },
    scheduledClassesE () {
      const student = this.studentOptions.find(
        (o) => o.value === this.student_id
      )
      if (student) return student.data.schedules.filter(
        (o) => o.type === this.type && o.category === 'E'
      ).length
      return 0
    },
    scheduledClassesACC () {
      const student = this.studentOptions.find(
        (o) => o.value === this.student_id
      )
      if (student) return student.data.schedules.filter(
        (o) => o.type === this.type && o.category === 'ACC'
      ).length
      return 0
    },

    // C / D / E / ACC
    // REMOVIDO PELA NOVA LEGISLAÇÃO
    // nightSchedules () {
    //   const student = this.studentOptions.find(o => o.value === this.student_id)
    //   const nightTime = moment('18:00:00', 'hh:mm:ss')
    //   if (student) return student.data.schedules.filter(o => moment(o.end_time, 'hh:mm:ss').isAfter(nightTime) && o.type === this.type).length
    //   return 0
    // },
    // nightSchedulesRequired () {
    //   const student = this.studentOptions.find(o => o.value === this.student_id)
    //   if (student) {
    //     // 1ª habilitação Cat A e B 20hs sendo 1 noturna
    //     if (this.type_service === '1ª Habilitação') return 1
    //     //Adiçao cat A e B 15h aula sendo 1 noturna
    //     else if (this.type_service === 'Adição de categoria') return 1
    //     // Mudança de categoria de C,D,E  20h no mínimo 4 noturnas
    //     else if (this.type_service === 'Mudança de categoria') return 4
    //     // ACC 5h ao menos 4 noturnas
    //     else if (this.type_service === 'Permissão ACC') return 4
    //   }
    //   return 0
    // },
    absences () {
      const student = this.studentOptions.find(
        (o) => o.value === this.student_id
      )
      if (student) return student.data.schedules.filter(
        (o) => o.type === this.type && o.status === 'ABSENCE'
      ).length
      return 0
    },
    servicePackages () {
      return this.$store.getters['servicePackage/forSelect']
    },
    selectedDays () {
      return this.$store.state.schedule
        ? this.$store.state.schedule.selectedDays
        : []
    },
    periods () {
      return this.$store.state.schedule.periods // 1 MATUTINO 2 VESPERTINO 3 NOTURNO
    },
    hours () {
      const periods = this.periods
      const employee =
        this.employees.find((o) => o.value === this.employee_id) || null
      const h = this.$store.getters.scheduleClassTimes(
        employee ? employee.data : null
      )
      const res = h
        .filter(function (eachElem, index) {
          return periods.indexOf(index) !== -1
        })
        .flat()
      return res
    },
    tables () {
      return Math.ceil(this.selectedDays.length / 6) // CONTROLE DE COLUNAS POR TABELA DE DATAS (7 COLUNAS POR TABELA)
    },
    employees () {
      return this.$store.getters['employee/forSelectOnlyInstrutores']
    },
    vehicles () {
      return this.$store.getters['vehicle/forSelect']
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    },
    windowWidth () {
      return this.$store.state.windowWidth
    }
  },
  methods: {
    syncIbio (date) {
      if (date && date.id) this.$refs.syncIbio.execute(date.id, this.filterSchedule)
    },
    closeMultiSchedulePopup () {
      this.popupMultiScheduleCreator = false
      this.filterSchedule()
    },
    replicateSchedules () {
      const payload = {
        dates: this.selectedDays.map((day) => day.id).filter(e => e !== this.schedule.start_date.split(' ')[0]),
        schedules: [
          {
            quantity: this.quantityForMultiSchedule, ...this.schedule
          }
        ]
      }

      this.popupMultiScheduleCreator = true
      this.$refs.multiSchedule.generateSchedules(payload)
    },
    doRelatorioPdf () {
      const self = this
      this.$vs.dialog({
        type: 'confirm',
        color:'success',
        title:'Formato',
        acceptText: 'Retrato',
        cancelText: 'Paisagem',
        text:'Selecione a orientação da página',
        accept: self.printPortrait,
        cancel: self.printLandscait
      })
    },
    printPortrait () {
      this.printFormat = 'portrait'
      this.print('PDF')
    },
    printLandscait () {
      this.printFormat = 'landscape'
      this.print('PDF')
    },
    allowStudent () {
      const validator = this.studentValidators()
      let allow = true

      for (let i = 0; i < validator.length  && allow; i++) {
        allow = validator[i]()
      }

      return allow
    },
    getQuantityAvailableSchedules () {
      return this.getQuantityAvailableSchedulesByCategory(this.schedule.category)
    },
    getQuantityAvailableSchedulesByCategory (category) {
      switch (category) {
      case 'A': return this.availableSchedules
      case 'B':
        return this.availableSchedulesB
      case 'C':
        return this.availableSchedulesC
      case 'D':
        return this.availableSchedulesD
      case 'E':
        return this.availableSchedulesE
      }
      return 25
    },
    openInputClassQuantityPopup () {
      this.popupInputClassQuantity = true
      this.maxQuantityClassAvailable = this.getQuantityAvailableSchedules()
    },
    studentValidators () {
      const me = this
      // nao existe configuracao de validacao cadastrada
      if (!me.$user.company.config_schedule) {
        return []
      }
      // inserir aqui os validadores
      const valid  = [
        () => {
          let resp = true
          if (me.$user.company.config_schedule.block_debit && me.studentData.defaulting) {
            me.$vs.notify({
              time: 6000,
              title: 'AVISO',
              text: 'Aluno com pendência no financeiro. Agendamento impedido.',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning',
              position: 'top-center'
            })
            resp = !me.studentData.defaulting
          }

          return resp

        },
        () => {
          let resp = true
          if (me.$user.company.config_schedule.notify_debit && me.studentData.defaulting) {
            // apenas avisar
            me.$vs.dialog({
              type:'confirm',
              color: 'danger',
              title: 'Financeiro - Pendência',
              text: 'Aluno com pendência no financeiro. Deseja continuar com o agendamento?',
              accept:me.newAddSchedule,
              acceptText: 'Sim',
              cancelText: 'Cancelar'
            })

            resp = !me.studentData.defaulting

          }
          return resp
        }
      ]

      return valid
    },
    openLoading () {
      this.$vs.loading({
        container: '#loading',
        scale: 0.8
      })
    },
    closeLoading () {
      this.$vs.loading.close('#loading > .con-vs-loading')
    },
    contextMenu (event, schedule, selectedDay, hour) {
      this.closeOnClick = true
      this.contextAdd = false
      this.contextAbsence = false
      this.contextDelete = false
      this.contextBlockEmployee = false
      this.selectedDay = selectedDay
      this.selectedHour = hour
      this.contextReplicate = false

      if (schedule) {
        this.contextDelete = true
        this.contextReplicate = true
        // A REGRA PARA MOTOS É ALUNO DIFERENTE, MOTO DIFERENTE (SOMENTE 2 MOTOS NO MEMO HORÁRIO) E MESMO INSTRUTOR
        // VERIFICA SE TODOS OS DADOS FORAM PREENCHIDOS E SE AS REGRAS SE CUMPREM PARA DUPLO AGENDAMENTO
        if (
          this.student_id &&
          this.employee_id &&
          this.vehicle &&
          this.category &&
          schedule.student_id !== this.student_id &&
          this.vehicle.id !== schedule.vehicle_id &&
          this.vehicle.type === 'MOTORCYCLE' &&
          schedule.vehicle &&
          schedule.vehicle.type === 'MOTORCYCLE'
        ) {
          const sch = this.fillSchedule(selectedDay, hour)
          if (sch.length < 2) {
            // this.selectedDay = selectedDay
            // this.selectedHour = hour
            this.contextAdd = true
          }
        }
        // MOSTRA A OPÇÃO DE ALTERAR O STATUS PARA AUSÊNCIA
        if (schedule.status !== 'ABSENCE' && schedule.status !== 'Bloqueado') {
          this.contextAbsence = true
        }

        this.$store.commit('schedule/SET_SCHEDULE', schedule)
        this.$refs.menu.open(event)
      } else if (!this.fillSchedule(selectedDay, hour).length) {
        // CONTEXTO EM DATA SEM AGENDAMENTO - BLOQUEIO DE INSTRUTOR
        this.closeOnClick = false
        this.contextBlockEmployee = true
        this.blockEmployeeMotivation = ''
        this.$refs.menu.open(event)
      }
    },
    contextMenuClicked (action) {
      if (action === 'DELETE') {
        this.confirmDelete()
      } else if (action === 'ADD') {
        this.addSchedule(this.selectedDay, this.selectedHour, true)
      } else if (action === 'ABSENCE') {
        // AUSÊNCIA SEM JUSTIFICATIVA PARA AULA PRÁTICA
        this.updateStatus('ABSENCE')
      } else if (action === 'BLOCK_EMPLOYEE') {
        this.blockEmployee()
      }
    },
    viewSchedule (schedule) {
      this.$store.commit('schedule/SET_SCHEDULE', schedule)
      this.scheduleDetails()
    },

    // BAIXA O ARQUIVO EXCEL OU WORD
    makeBlobDoc (blob, filename) {
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `${filename || 'Relatório'}.xlsx`
      link.click()
    },

    async print (type) {
      const selectedDays = this.selectedDays.map((day) => day.id)
      const reportFirstPeriod = this.reportFirstPeriod || selectedDays[0]
      const reportLastPeriod =
        this.reportLastPeriod || selectedDays[selectedDays.length - 1]

      if (
        reportFirstPeriod &&
        reportLastPeriod &&
        moment(reportFirstPeriod).year() > 1000 &&
        moment(reportLastPeriod).year() > 1000
      ) {
        const difDays = moment(reportLastPeriod).diff(
          moment(reportFirstPeriod),
          'days'
        )
        if (difDays < 0) {
          this.periodError = true
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: 'O período inicial é maior que o final.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        } else if (difDays > 365 * 5 + 1) {
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: 'O período não pode ser maior que 5 anos.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        }
        this.periodError = false

        this.$vs.loading()
        this.showPdfTitle = 'Relatório de Agendamentos de Aulas Práticas'
        const resp = await this.$http.post(
          `${process.env.VUE_APP_API_URL}/schedule/practicalClass/report`,
          {
            firstPeriod: reportFirstPeriod,
            lastPeriod: reportLastPeriod,
            orderBy: 'date',
            format: type,
            page: this.printFormat,
            payload: {
              students: this.student_id ? [this.student_id] : [],
              employees: this.employee_id ? [this.employee_id] : [],
              vehicles: this.vehicle ? [this.vehicle.id] : [],
              type_vehicle: null,
              status: null
            }
          },
          { responseType: type === 'EXCEL' ? 'arraybuffer' : null }
        )

        if (type === 'PDF') {
          this.srcPdf = `data:application/pdf;base64,${resp.data}`
          this.popupShowPdf = true
        } else if (type === 'EXCEL') {
          const blob = new Blob([resp.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          })
          this.makeBlobDoc(blob, 'Relatório de Agendamentos de Aulas Práticas')
        }
        this.$nextTick(() => {
          this.$vs.loading.close()
        })
      } else {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Defina um período.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    },
    confirmDelete () {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(
        (a) => a.module === 'spa_schedule_practical'
      )
      if (
        !this.user.roles.some((role) => role.name === 'admin') &&
        (!mod || !mod.pivot.permissions.includes('delete'))
      ) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmar Exclusão',
        text: 'Tem certeza que deseja excluir este agendamento?',
        accept: this.deleteSchedule,
        acceptText: 'Excluir',
        cancelText: 'Cancelar'
      })
    },
    async deleteSchedule () {
      try {
        this.$vs.loading()
        if (this.schedule.student_id) {
          const idx = this.studentOptions.findIndex(
            (o) => o.value === this.schedule.student_id
          )
          if (idx >= 0) {
            const idx2 = this.studentOptions[idx].data.schedules.findIndex(
              (o) => o.id === this.schedule.id
            )
            if (idx2 >= 0) this.studentOptions[idx].data.schedules.splice(idx2, 1)
          }
        }
        /**
         * PRECISO PEGAR TODAS AS AGENDAS DO ALUNO PRA REORDENAR
         */
        await this.$store.dispatch(
          'studentManagement/fetchStudent',
          this.schedule.student_id
        )
        await this.$store.dispatch('schedule/delete', this.schedule.id)

        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Excluídos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
        this.closeViewSidebar()
      } catch (error) {
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    async addSchedule (selectedDay, hour, context = false) {
      try {
        const schedule = this.fillSchedule(selectedDay, hour)
        if (schedule.length && !context) {
          this.viewSchedule(schedule[0]) // SE EXISTIR AGENDA NESTE DIA NÃO CADASTRA E SIM MOSTRA O AGENDAMENTO
          return // (PARA CLIQUES NAS BORDAS DA CÉLULA. OCORRE SOMENTE EM AGENDAMENTOS ÚNICOS ONDE A DIV NÃO COBRE TODO O TD)
        }

        if (
          !this.student_id ||
          !this.employee_id ||
          !this.vehicle ||
          !this.category
        ) {
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: 'Selecione <br> <strong>ALUNO, INSTRUTOR, VEÍCULO e CATEGORIA</strong> <br> para o agendamento.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning',
            position: 'top-center'
          })
          return
        }
        this.idxHour = this.hours.findIndex((o) => o === hour)
        if (this.idxHour >= 0) {
          if (this.idxHour + parseInt(this.quantity) > this.hours.length) {
            this.$vs.notify({
              time: 6000,
              title: 'AVISO',
              text: 'A quantidade de aulas ultrapassa o horário máximo existente.',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning',
              position: 'top-center'
            })
            return
          }
          // VERIFICA BLOQUEIOS POR HORÁRIO DE TRABALHO E FOLGA DO FUNCIONÁRIO
          const employee = this.employees.find(
            (o) => o.value === this.employee_id
          )

          for (let i = 1; i <= this.quantity; i++) {
            // SE NÃO TEM OFFICE HOUR, NUNCA FOI CADASTRADO ENTÃO ENTENDO QUE NÃO DEVO BLOQUEAR NADA
            if (employee.data.office_hour) {
              if (
                this.verifyWorkDays(
                  employee.data,
                  selectedDay,
                  this.hours[this.idxHour + (i - 1)]
                ) === 'STOP'
              ) return
            }

            //  CHECA AVISOS EXCETO PARA AULAS AVULSAS DESDE QUE SELECIONADO O TIPO DE SERVIÇO
            if (this.type_service && this.type_service !== 'Aula avulsa') {
              if (this.verifyWarnings(selectedDay) === 'STOP') return
            }
            //  CHECA AVISOS EXCETO PARA AULAS AVULSAS DESDE QUE SELECIONADO O TIPO DE SERVIÇO

            if (
              this.fillSchedule(selectedDay, this.hours[this.idxHour + (i - 1)])
                .length &&
              i > 1
            ) {
              this.$vs.notify({
                time: 6000,
                title: 'AVISO',
                text: 'FOI GERADO UM CONFLITO DE AGENDAMENTOS.',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'warning',
                position: 'top-center'
              })
            }

            this.addScheduleParam = {selectedDay, hour, context}

            if (!this.allowStudent()) return

            this.$vs.loading()
            const payload = {
              type: this.type,
              student_id: this.student_id,
              student_name: this.student_name,
              employee_id: this.employee_id,
              category: this.category,
              schedules: [
                {
                  status: this.status,
                  type: this.type,
                  duration: '00:50',
                  start_date: selectedDay.id,
                  start_time: `${this.hours[this.idxHour + (i - 1)].slice(
                    0,
                    5
                  )}:00`,
                  end_time: `${this.hours[this.idxHour + (i - 1)].slice(
                    6,
                    11
                  )}:00`,
                  student_id: this.student_id,
                  employee_id: this.employee_id,
                  vehicle_id: this.vehicle.id,
                  category: this.category,
                  type_service: this.type_service,
                  ibio: this.ibio
                }
              ]
            }
            /**
             * PRECISO PEGAR TODAS AS AGENDAS DO ALUNO PRA REORDENAR
             */
            await this.$store.dispatch(
              'studentManagement/fetchStudent',
              this.student_id
            )
            const response = await this.$store.dispatch(
              'schedule/store',
              payload
            )
            const idx = this.studentOptions.findIndex(
              (o) => o.value === this.student_id
            )
            this.studentOptions[idx].data.schedules.push(response.data[0])

            if (this.ibio === true && response.data[0].ibio === false) {
              this.$vs.notify({
                fixed:true,
                title: 'AVISO',
                text: response.data[0].observation,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'warning',
                position: 'top-center'
              })
            }
          }

          this.$vs.loading.close()
          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: 'Dados Salvos.',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            position: 'top-center'
          })
        } else {
          alert('O horário não existe, por favor avise o suporte.')
        }
        this.idxHour = -1
      } catch (error) {
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            //time: error.response.data.erro_sincronizacao_ibio ? '' : 5000,
            title: 'AVISO',
            fixed: !!error.response.data.erro_sincronizacao_ibio,
            text: error.response.data.erro_sincronizacao_ibio ? error.response.data.erro_sincronizacao_ibio : error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning',
            position: 'top-center'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    verifyWorkDays (employee, selectedDay, hour) {
      const weekdaysWork = [
        'work_dom',
        'work_seg',
        'work_ter',
        'work_qua',
        'work_qui',
        'work_sex',
        'work_sab'
      ]
      const weekdaysStartTimeWork = [
        'start_time_dom',
        'start_time_seg',
        'start_time_ter',
        'start_time_qua',
        'start_time_qui',
        'start_time_sex',
        'start_time_sab'
      ]
      const weekdaysFinalTimeWork = [
        'final_time_dom',
        'final_time_seg',
        'final_time_ter',
        'final_time_qua',
        'final_time_qui',
        'final_time_sex',
        'final_time_sab'
      ]
      const [start_time, end_time] = hour.split(' ')

      if (!employee.office_hour[weekdaysWork[selectedDay.weekdayPosition]]) {
        // NÃO TRABALHA NESTE DIA
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: `O funcionário <strong>${employee.name} NÃO trabalha</strong> neste dia.`,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'top-center'
        })
        if (employee.office_hour.block_time) return 'STOP'
      } else {
        const startTimeWork = moment(
          employee.office_hour[
            weekdaysStartTimeWork[selectedDay.weekdayPosition]
          ],
          'h:mm:ss'
        ).subtract(1, 's')
        const finalTimeWork = moment(
          employee.office_hour[
            weekdaysFinalTimeWork[selectedDay.weekdayPosition]
          ],
          'h:mm:ss'
        ).add(1, 's')
        // VERIFICAR SE TRABALHA NO HORÁRIO DEFINIDO
        const startTimeBetweenWork = moment(
          `${start_time}:00`,
          'h:mm:ss'
        ).isBetween(startTimeWork, finalTimeWork, undefined, '[)')
        const endTimeBetweenWork = moment(
          `${end_time}:00`,
          'h:mm:ss'
        ).isBetween(startTimeWork, finalTimeWork, undefined, '[)')
        if (!startTimeBetweenWork || !endTimeBetweenWork) {
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: `O funcionário <strong>${employee.name} NÃO trabalha</strong> neste horário.`,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning',
            position: 'top-center'
          })
          if (employee.office_hour.block_time) return 'STOP'
        } else if (employee.office_hour.lunch_time) {
          // VERIFICAR SE NÃO ESTÁ EM ALMOÇO
          const startTimeLunch = moment(
            employee.office_hour.lunch_time_ini,
            'h:mm:ss'
          )
          const finalTimeLunch = moment(
            employee.office_hour.lunch_time_fin,
            'h:mm:ss'
          )
          const startTimeBetweenLunch = moment(
            `${start_time}:00`,
            'h:mm:ss'
          ).isBetween(startTimeLunch, finalTimeLunch, undefined, '[)')
          const endTimeBetweenLunch = moment(
            `${end_time}:00`,
            'h:mm:ss'
          ).isBetween(startTimeLunch, finalTimeLunch, undefined, '[)')
          if (startTimeBetweenLunch || endTimeBetweenLunch) {
            //EM HORÁRIO DE ALMOÇO
            this.$vs.notify({
              time: 6000,
              title: 'AVISO',
              text: `O funcionário <strong>${employee.name} está em período de almoço</strong> neste horário.`,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning',
              position: 'top-center'
            })
            if (employee.office_hour.block_time) return 'STOP'
          }
        }
        if (employee.office_hour.breaks && employee.office_hour.breaks.length) {
          // VERIFICAR SE NÃO ESTÁ EM FOLGA
          const idx = employee.office_hour.breaks.findIndex((a) => {
            const startTimeBreak = moment(`${a.start_time}:00`, 'h:mm:ss')
            const finalTimeBreak = moment(`${a.end_time}:00`, 'h:mm:ss')
            const startTimeBetweenBreak = moment(
              `${start_time}:00`,
              'h:mm:ss'
            ).isBetween(startTimeBreak, finalTimeBreak, undefined, '[)')
            const endTimeBetweenBreak = moment(
              `${end_time}:00`,
              'h:mm:ss'
            ).isBetween(startTimeBreak, finalTimeBreak, undefined, '[)')
            return (
              a.weekday === selectedDay.weekdayPosition &&
              (startTimeBetweenBreak || endTimeBetweenBreak)
            )
          })
          if (idx >= 0) {
            // ESTÁ EM FOLGA
            this.$vs.notify({
              time: 6000,
              title: 'AVISO',
              text: `O funcionário <strong>${employee.name} está em período de folga</strong> neste horário.`,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning',
              position: 'top-center'
            })
            if (employee.office_hour.block_time) return 'STOP'
          }
        }
      }
      return 'GO' // STOP PARA PARAR NÃO PERMITINDO O AGENDAMENTO
    },
    verifyWarnings (selectedDay) {
      //  BLOCO QUE CHECA SE JÁ EXISTEM 3 MARCAÇÕES DA MESMA CATEGORIA NO DIA SELECIONADO
      //  EVITA UMA 4ª MARCAÇÃO SE RETORNAR STOP
      const counter = {}
      for (let index = 0; index < selectedDay.studentSchedule.length; index++) {
        const obj = selectedDay.studentSchedule[index]
        counter[obj.category] = (counter[obj.category] || 0) + 1
        if (counter[obj.category] >= 3 && obj.category === this.category) {
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: `<strong>Este aluno já possui 3 agendamentos na categoria ${obj.category} para o dia selecionado.</strong>`,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning',
            position: 'top-center'
          })
          return 'GO' // STOP PARA PARAR NÃO PERMITINDO O AGENDAMENTO
        }
      }
      //  BLOCO QUE CHECA SE JÁ EXISTEM 3 MARCAÇÕES DA MESMA CATEGORIA NO DIA SELECIONADO
      //  EVITA UMA 4ª MARCAÇÃO SE RETORNAR STOP

      let message = null
      if (
        this.availableSchedules === 1 ||
        this.availableSchedulesB === 1 ||
        this.availableSchedulesC === 1 ||
        this.availableSchedulesD === 1 ||
        this.availableSchedulesE === 1 ||
        this.availableSchedulesACC === 1
      ) {
        message = 'Esta é a última aula necessária.'
      }

      if (
        this.availableSchedules === 2 ||
        this.availableSchedulesB === 2 ||
        this.availableSchedulesC === 2 ||
        this.availableSchedulesD === 2 ||
        this.availableSchedulesE === 2 ||
        this.availableSchedulesACC === 2
      ) {
        message = 'Falta somente um agendamento.'
      }

      // REMOVIDO PELA NOVA LEGISLAÇÃO
      // if (this.availableSchedules <= 4 && (this.nightSchedules < this.nightSchedulesRequired)) {
      //   message = 'Não esqueça dos agendamentos noturnos.'
      // }

      if (message) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'top-center'
        })
      }
    },
    async updateStatus (status) {
      try {
        this.openLoading()
        this.$store.commit('schedule/SET_SCHEDULE', {
          status,
          duration: '00:50'
        }) // PRECISO COLOCAR ASSIM O TEMPO PQ NO BANCO É TIME
        await this.$store.dispatch('schedule/update', this.schedule)

        // O BLOCO ABAIXO SERVE SOMENTE PARA MUDAR O NÚMERO DE AGENDAMENTOS CONFORME O STATUS
        const idx = this.studentOptions.findIndex(
          (o) => o.value === this.schedule.student.id
        )
        if (idx >= 0) {
          const idx2 = this.studentOptions[idx].data.schedules.findIndex(
            (o) => o.id === this.schedule.id
          )
          this.$set(
            this.studentOptions[idx].data.schedules,
            idx2,
            this.schedule
          )
        }
        this.request = true // FORÇO CONSULTA NA API PARA LSTAGEM MOSTRAR A ALTERAÇÃO
        this.closeLoading()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Alterados.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning',
            position: 'top-center'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
        this.$nextTick(() => {
          this.closeLoading()
        })
      }
    },
    async blockEmployee () {
      if (!this.employee_id) {
        this.$vs.notify({
          time: 5000,
          title: 'AVISO',
          text: 'Selecione o Instrutor a ser Bloqueado.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }

      try {
        this.$vs.loading()
        const payload = {
          type: this.type,
          start_date: this.selectedDay.id,
          start_time: `${this.selectedHour.slice(0, 5)}:00`,
          end_time: `${this.selectedHour.slice(6, 11)}:00`,
          employee_id: this.employee_id,
          student_id: null,
          vehicle_id: null,
          category: null,
          doctor_id: null,
          cac_id: null,
          extra: this.blockEmployeeMotivation
        }
        await this.$store.dispatch('schedule/block', payload)
        this.$refs.menu.close()

        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    formatDate (date) {
      if (!date) return ''
      const [YYYY, MM, DD] = date.slice(0, 10).split('-')
      return `${DD}/${MM}/${YYYY}`
    },
    handleRange (row, hoursPerRow = 6) {
      if (row === this.tables && this.selectedDays.length % hoursPerRow > 0) {
        return _.range(
          row * hoursPerRow - (hoursPerRow - 1),
          row * hoursPerRow -
            (hoursPerRow - 1) +
            (this.selectedDays.length % hoursPerRow)
        )
      }
      return _.range(
        row * hoursPerRow - (hoursPerRow - 1),
        row * hoursPerRow + 1
      )
    },
    changeStudent () {
      const student = this.studentOptions.find(
        (o) => o.value === this.student_id
      )
      if (student) {
        this.student_name = student.data.name
        this.studentData = student.data
      }
      if (
        student &&
        student.data.intended_services &&
        student.data.intended_services.length
      ) {
        const service = this.servicePackages.find(
          (o) => o.value === student.data.intended_services[0]
        )
        this.type_service = service ? service.data.type_service : null
      } else {
        this.type_service = null
      }
      this.filterSchedule()
    },
    changeEmployee () {
      this.atualizarVehiclePeloEmployeeAtual()
      this.filterSchedule()
    },
    atualizarVehiclePeloEmployeeAtual () {
      // não selecionou employee?
      if (!this.employee_id) {
        return
      }

      // não tem nenhum veiculo cadastrado?
      if (!this.vehicles) {
        return
      }

      // já tem veiculo selecionado?
      if (this.vehicle) {
        return
      }

      // pega o primeiro veiculo em que este employee esteja cadastrado
      for (const i in this.vehicles) {
        if (employeeEstaCadastradoNesteCarro(this.vehicles[i].data, this.employee_id)) {
          this.vehicle = this.vehicles[i].data
          return
        }
      }

      // nao achou? pega o primeiro veiculo que tenha a mesma categoria deste funcionario E do aluno, se tiver selecionado
      // TODO
    },
    changeVehicle () {
      this.category = this.vehicle ? this.vehicle.category : null
      this.atualizarEmployeePeloVeiculoAtual()
      this.filterSchedule()
    },
    atualizarEmployeePeloVeiculoAtual () {
      if (!this.vehicle) {
        return
      }

      // se já tem employee escolhido, não alterar
      if (this.employee_id) {
        return
      }

      //buscar um employee no veiculo
      const employee = getEmployeeFromVehicle(this.vehicle)

      if (employee !== null) {
        this.employee_id = employee.id
      }
    },
    changeCategory () {
      this.filterSchedule()
    },

    async filterSchedule () {
      // CASO NÃO ENVIE FILTRO ESVAZIA A AGENDA
      if (!this.student_id && !this.employee_id && !this.vehicle) {
        this.$store.commit('schedule/SET_HOLIDAY_SCHEDULES', [])
        this.$store.commit('schedule/SET_STUDENT_SCHEDULES', [])
        this.$store.commit('schedule/SET_EMPLOYEE_SCHEDULES', [])
        this.$store.commit('schedule/SET_VEHICLE_SCHEDULES', [])
        this.$store.commit('schedule/FILL_SELECTED_DAYS')
        this.closeLoading()
        return
      }
      try {
        this.openLoading()
        const payload = {
          getByDate: true,
          getByPeriod: false,
          type: this.type,
          dates: this.selectedDays.map((day) => day.id),
          category: this.category,
          student_id: this.student_id,
          employee_id: this.employee_id,
          vehicle_id: this.vehicle ? this.vehicle.id : null
        }
        await this.$store.dispatch('schedule/fetchFilter', payload) // BUSCA OS AGENDAMENTOS NA API CONFORME FILTRO
        this.$store.commit('schedule/FILL_SELECTED_DAYS') // COLOCA OS DADOS DOS AGENDAMENTOS EM SELECEDDAYS PARA PREENCHER A TABELA DE AGENDAS
        this.closeLoading()
      } catch (error) {
        console.error(error)
      }
    },

    fillSchedule (schedule, hour) {
      if (
        schedule.studentSchedule.length ||
        schedule.employeeSchedule.length ||
        schedule.vehicleSchedule.length
      ) {
        const format = 'h:mm:ss'
        const beforeTime = moment(`${hour.slice(0, 5)}:00`, format)
        const afterTime = moment(`${hour.slice(6, 11)}:00`, format)

        const studentSchedule = schedule.studentSchedule.filter((e) => {
          if (
            moment(e.start_time, format).isBetween(
              beforeTime,
              afterTime,
              undefined,
              '(]'
            ) &&
            moment(e.start_time, format).isBetween(
              afterTime,
              beforeTime,
              undefined,
              '(]'
            )
          ) {
            return true
          } else if (
            moment(e.end_time, format).isBetween(
              beforeTime,
              afterTime,
              undefined,
              '(]'
            )
          ) {
            return true
          } else return false
        })
        if (studentSchedule.length) {
          studentSchedule.map((e, idx) => {
            if (idx === 0 && studentSchedule.length > 1) e.bg = 'background-color: rgba(21, 132, 130, 0.3); margin: -6px;'
            // #158482
            else if (idx === 1 && studentSchedule.length > 1) e.bg =
                'background-color: rgba(21, 132, 130, 0.3); margin: 6px -6px -6px -6px;'
            // #158482
            else {
              e.bg = 'background-color: rgba(21, 132, 130, 0.3);' // #158482
              e.fill = true
            }
            if (e.student && e.student.defaulting) e.bg = 'background-color: rgba(234, 84, 85, 0.3);'
          })
          return studentSchedule
        }

        const employeeSchedule = schedule.employeeSchedule.filter((e) => moment(e.start_time, format).isBetween(
          beforeTime,
          afterTime,
          undefined,
          '[)'
        )
        )
        if (employeeSchedule.length) {
          employeeSchedule.map((e, idx) => {
            if (e.status === 'Bloqueado') {
              e.bg = 'background-color: rgba(92, 99, 106, 0.3);' // #5c636a
              e.fill = true
            } else if (idx === 0 && employeeSchedule.length > 1) e.bg = 'background-color: rgba(255, 159, 67, 0.3); margin: -6px;'
            // #FF9F43
            else if (idx === 1 && employeeSchedule.length > 1) e.bg =
                'background-color: rgba(255, 159, 67, 0.3); margin: 6px -6px -6px -6px;'
            // #FF9F43
            else {
              e.bg = 'background-color: rgba(255, 159, 67, 0.3);' // #FF9F43
              e.fill = true
            }
          })
          return employeeSchedule
        }

        const vehicleSchedule = schedule.vehicleSchedule.filter((e) => moment(e.start_time, format).isBetween(
          beforeTime,
          afterTime,
          undefined,
          '[)'
        )
        )
        if (vehicleSchedule.length) {
          vehicleSchedule.map((e, idx) => {
            // red vehicle
            if (idx === 0 && vehicleSchedule.length > 1) e.bg = 'background-color: rgba(246, 255, 0, 0.3); margin: -6px;'
            // #EA5455
            else if (idx === 1 && vehicleSchedule.length > 1) e.bg =
                'background-color: rgba(234, 84, 85, 0.3); margin: 6px -6px -6px -6px;'
            // #EA5455
            else {
              e.bg = 'background-color: rgba(234, 84, 85, 0.3);' // #EA5455
              e.fill = true
            }
          })
          return vehicleSchedule
        }
      }
      return []
    },

    scheduleDetails () {
      this.isSidebarActive = true
    },
    changeScheduleShow () {
      this.isChangeScheduleActive = true
    },
    fetchStudentOptions (search, loading) {
      if (search.length) {
        loading(true)
        this.$http
          .post(`${process.env.VUE_APP_API_URL}/student/search`, {
            kw: search,
            type: 'STUDENT',
            with: ['schedules']
          })
          .then(
            function (response) {
              this.studentOptions = []
              for (const k in response.data) {
                this.studentOptions.push({
                  value: response.data[k].id,
                  label: `${response.data[k].name} ${
                    response.data[k].cpf || ''
                  }`,
                  data: response.data[k]
                })
              }
              loading(false)
            }.bind(this)
          )
          .catch(function (error) {
            console.error(error)
          })
      } else {
        loading(false)
      }
    },
    closeViewSidebar () {
      this.isSidebarActive = false
      this.isChangeScheduleActive = false
      this.request = false // RETORNO PARA FALSE AO FECHAR A LISTA PARA NÃO FORÇAR REQUEST NA PRÓXIMA CONSULTA CASO SEJA O MESMO ALUNO
    },
    setSidebarWidth () {
      if (this.windowWidth < 992) {
        this.isEmailSidebarActive = this.clickNotClose = false
      } else {
        this.isEmailSidebarActive = this.clickNotClose = true
      }
    },
    toggleEmailSidebar (value = false) {
      if (!value) {
        this.closeViewSidebar()
        if (this.clickNotClose) {
          return
        }
      }
      this.isEmailSidebarActive = value
    },
    async newAddSchedule () {
      const selectedDay = this.addScheduleParam.selectedDay
      const hour = this.addScheduleParam.hour
      const context = this.addScheduleParam.context

      try {
        const schedule = this.fillSchedule(selectedDay, hour)
        if (schedule.length && !context) {
          this.viewSchedule(schedule[0]) // SE EXISTIR AGENDA NESTE DIA NÃO CADASTRA E SIM MOSTRA O AGENDAMENTO
          return // (PARA CLIQUES NAS BORDAS DA CÉLULA. OCORRE SOMENTE EM AGENDAMENTOS ÚNICOS ONDE A DIV NÃO COBRE TODO O TD)
        }

        if (
          !this.student_id ||
          !this.employee_id ||
          !this.vehicle ||
          !this.category
        ) {
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: 'Selecione <br> <strong>ALUNO, INSTRUTOR, VEÍCULO e CATEGORIA</strong> <br> para o agendamento.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning',
            position: 'top-center'
          })
          return
        }
        this.idxHour = this.hours.findIndex((o) => o === hour)
        if (this.idxHour >= 0) {
          if (this.idxHour + parseInt(this.quantity) > this.hours.length) {
            this.$vs.notify({
              time: 6000,
              title: 'AVISO',
              text: 'A quantidade de aulas ultrapassa o horário máximo existente.',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning',
              position: 'top-center'
            })
            return
          }
          // VERIFICA BLOQUEIOS POR HORÁRIO DE TRABALHO E FOLGA DO FUNCIONÁRIO
          const employee = this.employees.find(
            (o) => o.value === this.employee_id
          )

          for (let i = 1; i <= this.quantity; i++) {
            // SE NÃO TEM OFFICE HOUR, NUNCA FOI CADASTRADO ENTÃO ENTENDO QUE NÃO DEVO BLOQUEAR NADA
            if (employee.data.office_hour) {
              if (
                this.verifyWorkDays(
                  employee.data,
                  selectedDay,
                  this.hours[this.idxHour + (i - 1)]
                ) === 'STOP'
              ) return
            }

            //  CHECA AVISOS EXCETO PARA AULAS AVULSAS DESDE QUE SELECIONADO O TIPO DE SERVIÇO
            if (this.type_service && this.type_service !== 'Aula avulsa') {
              if (this.verifyWarnings(selectedDay) === 'STOP') return
            }
            //  CHECA AVISOS EXCETO PARA AULAS AVULSAS DESDE QUE SELECIONADO O TIPO DE SERVIÇO

            if (
              this.fillSchedule(selectedDay, this.hours[this.idxHour + (i - 1)])
                .length &&
              i > 1
            ) {
              this.$vs.notify({
                time: 6000,
                title: 'AVISO',
                text: 'FOI GERADO UM CONFLITO DE AGENDAMENTOS.',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'warning',
                position: 'top-center'
              })
            }

            this.$vs.loading()
            const payload = {
              type: this.type,
              student_id: this.student_id,
              student_name: this.student_name,
              employee_id: this.employee_id,
              category: this.category,
              schedules: [
                {
                  status: this.status,
                  type: this.type,
                  duration: '00:50',
                  start_date: selectedDay.id,
                  start_time: `${this.hours[this.idxHour + (i - 1)].slice(
                    0,
                    5
                  )}:00`,
                  end_time: `${this.hours[this.idxHour + (i - 1)].slice(
                    6,
                    11
                  )}:00`,
                  student_id: this.student_id,
                  employee_id: this.employee_id,
                  vehicle_id: this.vehicle.id,
                  category: this.category,
                  type_service: this.type_service,
                  ibio: this.ibio
                }
              ]
            }
            /**
             * PRECISO PEGAR TODAS AS AGENDAS DO ALUNO PRA REORDENAR
             */
            await this.$store.dispatch(
              'studentManagement/fetchStudent',
              this.student_id
            )
            const response = await this.$store.dispatch(
              'schedule/store',
              payload
            )
            const idx = this.studentOptions.findIndex(
              (o) => o.value === this.student_id
            )
            this.studentOptions[idx].data.schedules.push(response.data[0])
          }

          this.$vs.loading.close()
          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: 'Dados Salvos.',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            position: 'top-center'
          })
        } else {
          alert('O horário não existe, por favor avise o suporte.')
        }
        this.idxHour = -1
      } catch (error) {
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({

            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning',
            position: 'top-center'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    }
  },
  mounted () {
    if (this.user.company.ibio_config) {
      this.ibio = true
      this.disabledIbio = false
    } else {
      this.disabledIbio = true
    }
  },
  created () {
    this.debouncedGetSearchStudent = _.debounce(this.fetchStudentOptions, 600)
    this.debouncedGetFilterSchedule = _.debounce(this.filterSchedule, 800)

    if (!moduleSchedule.isRegistered) {
      this.$store.registerModule('schedule', moduleSchedule)
      moduleSchedule.isRegistered = true
    }

    if (!moduleEmployee.isRegistered) {
      this.$store.registerModule('employee', moduleEmployee)
      moduleEmployee.isRegistered = true
    }
    this.$store
      .dispatch('employee/fetchAll')
      .then(() => {})
      .catch((err) => {
        console.error(err)
      })

    if (!moduleVehicle.isRegistered) {
      this.$store.registerModule('vehicle', moduleVehicle)
      moduleVehicle.isRegistered = true
    }
    this.$store
      .dispatch('vehicle/fetchAll')
      .then(() => {})
      .catch((err) => {
        console.error(err)
      })

    if (!moduleServicePackage.isRegistered) {
      // this.$store.registerModule('servicePackage', moduleServicePackage)
      moduleServicePackage.isRegistered = true
    }
    this.$store
      .dispatch('servicePackage/fetchAll')
      .then(() => {})
      .catch((err) => {
        console.error(err)
      })

    if (!moduleStudentManagement.isRegistered) {
      this.$store.registerModule('studentManagement', moduleStudentManagement)
      moduleStudentManagement.isRegistered = true
    }

    this.setSidebarWidth()
    this.filterSchedule() // INICIA COM ESTE MÉTODO PARA ESVAZIAR LIXO DO STATE
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/vuexy/apps/email.scss';

#schedule-practical th .vs-table-text {
  cursor: default;
}
.svg-hover:hover {
  fill: #158482;
}
.svg-hover {
  fill: #626262;
}
#schedule-practical td {
  border: 1px solid #c0c0c0;
  line-height: 1;
}
#schedule-practical td:hover {
  border-bottom: 1px solid #353434;
}
#schedule-practical td:first-child:hover {
  border-bottom: 1px solid #c0c0c0;
}
#schedule-practical .vs-con-table td:last-child {
  border-right: 1px solid transparent;
}
#schedule-practical .vs-table--thead {
  border: 1px solid transparent;
}
.icon {
  font-size: 16px;
}
#schedule-practical
  .vs-con-table
  .vs-con-tbody
  .vs-table--tbody-table
  .tr-values
  .vs-table--td {
  padding: 6px;
}
#schedule-practical .ps__scrollbar-y-rail {
  z-index: 99999;
}
</style>
