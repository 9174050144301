var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-sidebar",
        {
          staticClass: "full-vs-sidebar email-view-sidebar items-no-padding",
          attrs: {
            "click-not-close": "",
            parent: "#schedule-practical",
            "hidden-background": true,
            "position-right": "",
          },
          model: {
            value: _vm.isSidebarActive,
            callback: function ($$v) {
              _vm.isSidebarActive = $$v
            },
            expression: "isSidebarActive",
          },
        },
        [
          _c(
            "div",
            { staticClass: "mail-sidebar-content px-0 sm:pb-6 h-full" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "flex flex-wrap items-center email-header justify-between md:px-8 px-6 sm:pb-2 sm: pt-6 d-theme-dark-bg",
                },
                [
                  _c("div", { staticClass: "flex mb-4" }, [
                    _c(
                      "div",
                      { staticClass: "flex items-center" },
                      [
                        _c("feather-icon", {
                          staticClass: "cursor-pointer hover:text-primary mr-4",
                          attrs: {
                            icon: _vm.$vs.rtl
                              ? "ArrowRightIcon"
                              : "ArrowLeftIcon",
                            "svg-classes": "w-6 h-6",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("closeSidebar")
                            },
                          },
                        }),
                        _c("h4", [_vm._v("MUDANÇA DE AGENDA PRÁTICA")]),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "ml-10 mb-4 mt-1" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "email__actions--single flex items-baseline",
                      },
                      [
                        _c(
                          "vx-tooltip",
                          {
                            staticClass: "mr-3 ml-auto",
                            attrs: {
                              text: "Não trocar. Apenas enviar para o IBIO",
                              position: "top",
                            },
                          },
                          [
                            _c("feather-icon", {
                              staticClass: "cursor-pointer",
                              attrs: {
                                icon: "UploadCloudIcon",
                                "svg-classes": "h-6 w-6",
                              },
                              on: { click: _vm.askConfirmIbioSync },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "vx-tooltip",
                          {
                            staticClass: "mr-3 ml-auto",
                            attrs: { text: "Confirmar Troca", position: "top" },
                          },
                          [
                            _c("feather-icon", {
                              staticClass: "cursor-pointer",
                              attrs: {
                                icon: "SaveIcon",
                                "svg-classes": "h-6 w-6",
                              },
                              on: { click: _vm.confirmChangeSchedules },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _c(
                _vm.scrollbarTag,
                {
                  key: _vm.$vs.rtl,
                  tag: "component",
                  staticClass:
                    "scroll-area-mail-content md:px-8 md:pb-8 pt-4 px-6 pb-6",
                  attrs: { settings: _vm.settings },
                },
                [
                  _c("vs-card", [
                    _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v("Instrutor [origem]"),
                          ]),
                          _c("v-select", {
                            staticStyle: { "background-color": "white" },
                            attrs: {
                              reduce: (option) => option.value,
                              options: _vm.employees,
                              placeholder: "Selecione",
                              dir: _vm.$vs.rtl ? "rtl" : "ltr",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "no-options",
                                fn: function ({}) {
                                  return [
                                    _vm._v(
                                      "\n                  Desculpe, nenhum resultado encontrado.\n                "
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.employee_from_id,
                              callback: function ($$v) {
                                _vm.employee_from_id = $$v
                              },
                              expression: "employee_from_id",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v("Instrutor [destino]"),
                          ]),
                          _c("v-select", {
                            staticStyle: { "background-color": "white" },
                            attrs: {
                              reduce: (option) => option.value,
                              options: _vm.employees,
                              placeholder: "Selecione",
                              dir: _vm.$vs.rtl ? "rtl" : "ltr",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "no-options",
                                fn: function ({}) {
                                  return [
                                    _vm._v(
                                      "\n                  Desculpe, nenhum resultado encontrado.\n                "
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.employee_dest_id,
                              callback: function ($$v) {
                                _vm.employee_dest_id = $$v
                              },
                              expression: "employee_dest_id",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v("Veículo [origem]"),
                          ]),
                          _c("v-select", {
                            staticStyle: { "background-color": "white" },
                            attrs: {
                              reduce: (option) => option.value,
                              options: _vm.vehicles,
                              placeholder: "Selecione",
                              dir: _vm.$vs.rtl ? "rtl" : "ltr",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "no-options",
                                fn: function ({}) {
                                  return [
                                    _vm._v(
                                      "\n                  Desculpe, nenhum resultado encontrado.\n                "
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.vehicle_from_id,
                              callback: function ($$v) {
                                _vm.vehicle_from_id = $$v
                              },
                              expression: "vehicle_from_id",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v("Veículo [destino]"),
                          ]),
                          _c("v-select", {
                            staticStyle: { "background-color": "white" },
                            attrs: {
                              reduce: (option) => option.data,
                              options: _vm.vehicles,
                              placeholder: "Selecione",
                              dir: _vm.$vs.rtl ? "rtl" : "ltr",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "no-options",
                                fn: function ({}) {
                                  return [
                                    _vm._v(
                                      "\n                  Desculpe, nenhum resultado encontrado.\n                "
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.vehicle_dest_id,
                              callback: function ($$v) {
                                _vm.vehicle_dest_id = $$v
                              },
                              expression: "vehicle_dest_id",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-0" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v("Aluno"),
                          ]),
                          _c("v-select", {
                            staticStyle: { "background-color": "white" },
                            attrs: {
                              clearable: true,
                              filterable: false,
                              reduce: (option) => option.value,
                              options: _vm.studentOptions,
                              placeholder: "Digite cpf ou nome do aluno...",
                              dir: _vm.$vs.rtl ? "rtl" : "ltr",
                            },
                            on: { search: _vm.debouncedGetSearchStudent },
                            scopedSlots: _vm._u([
                              {
                                key: "no-options",
                                fn: function ({}) {
                                  return [
                                    _vm._v(
                                      "\n                  Nenhum resultado encontrado.\n                "
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.student_id,
                              callback: function ($$v) {
                                _vm.student_id = $$v
                              },
                              expression: "student_id",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-0" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v("Categoria"),
                          ]),
                          _c("v-select", {
                            staticStyle: { "background-color": "white" },
                            attrs: {
                              clearable: true,
                              reduce: (option) => option.value,
                              options: _vm.categoryCNHOptions,
                              placeholder: "Selecione",
                              dir: _vm.$vs.rtl ? "rtl" : "ltr",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "no-options",
                                fn: function ({}) {
                                  return [
                                    _vm._v(
                                      "\n                  Nenhum resultado encontrado.\n                "
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.category_cnh,
                              callback: function ($$v) {
                                _vm.category_cnh = $$v
                              },
                              expression: "category_cnh",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mt-3 flex flex-wrap items-center" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "ml-0 mt-2",
                              model: {
                                value: _vm.onlyIbioSync,
                                callback: function ($$v) {
                                  _vm.onlyIbioSync = $$v
                                },
                                expression: "onlyIbioSync",
                              },
                            },
                            [_vm._v("IBio")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mt-3 flex flex-wrap items-center" },
                        [
                          _c(
                            "vs-button",
                            {
                              attrs: { icon: "search", type: "filled" },
                              on: { click: _vm.searchSchedules },
                            },
                            [_vm._v("Buscar")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c(
                    "vs-card",
                    [
                      _vm.schedules
                        ? _c(
                            "vs-table",
                            {
                              staticClass: "bordered",
                              staticStyle: { overflow: "-webkit-paged-y" },
                              attrs: {
                                noDataText: "Nenhum Agendamento.",
                                data: _vm.schedules,
                                hoverFlat: "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ data }) {
                                      return _vm._l(
                                        data,
                                        function (schedule, indextr) {
                                          return _c(
                                            "vs-tr",
                                            {
                                              key: indextr,
                                              staticStyle: {
                                                "font-size": "12px",
                                                opacity: "1 !important",
                                              },
                                            },
                                            [
                                              _c(
                                                "vs-td",
                                                [
                                                  _c("vs-checkbox", {
                                                    staticClass:
                                                      "vs-checkbox-small ml-0 mr-1",
                                                    attrs: {
                                                      "vs-value": schedule,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedSchedules,
                                                      callback: function ($$v) {
                                                        _vm.selectedSchedules =
                                                          $$v
                                                      },
                                                      expression:
                                                        "selectedSchedules",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c("vs-td", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "cursor-default font-semibold",
                                                  },
                                                  [_vm._v(_vm._s(indextr + 1))]
                                                ),
                                              ]),
                                              _c("vs-td", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "cursor-default font-semibold",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("dateTime")(
                                                          schedule.start_date,
                                                          "date"
                                                        )
                                                      ) +
                                                        "\n                    " +
                                                        _vm._s(
                                                          schedule.start_time.slice(
                                                            0,
                                                            5
                                                          )
                                                        )
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _c(
                                                "vs-td",
                                                {
                                                  staticClass: "text-red-dark",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "cursor-default font-semibold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          schedule.employee.name
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c("vs-td", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "cursor-default font-semibold",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        schedule.student
                                                          ? schedule.student
                                                              .name
                                                          : ""
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _c("vs-td", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "cursor-default font-semibold",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        schedule.vehicle
                                                          ? schedule.vehicle
                                                              .brand
                                                          : ""
                                                      ) +
                                                        "\n                    " +
                                                        _vm._s(
                                                          schedule.vehicle
                                                            ? schedule.vehicle
                                                                .model
                                                            : ""
                                                        ) +
                                                        "\n                    " +
                                                        _vm._s(
                                                          schedule.vehicle
                                                            ? schedule.vehicle
                                                                .license_plate
                                                            : ""
                                                        )
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _c(
                                                "vs-td",
                                                {
                                                  staticStyle: {
                                                    "text-align": "center",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "cursor-default font-semibold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          schedule.category
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c("vs-td", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "cursor-default font-semibold",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(schedule.message)
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _c(
                                                "vs-td",
                                                {
                                                  staticStyle: {
                                                    "text-align": "center",
                                                  },
                                                },
                                                [
                                                  (!schedule.ibio &&
                                                    schedule.sync_ibio_last_try) ||
                                                  schedule.sincIbioStatus ===
                                                    "processando"
                                                    ? _c("feather-icon", {
                                                        staticClass:
                                                          "text-warning",
                                                        attrs: {
                                                          title:
                                                            "Sincronizando",
                                                          icon: "ClockIcon",
                                                          svgClasses: "w-5 h-5",
                                                        },
                                                      })
                                                    : schedule.ibio &&
                                                      schedule.id_ibio
                                                    ? _c("feather-icon", {
                                                        staticClass:
                                                          "text-success",
                                                        attrs: {
                                                          title: "Sincronizada",
                                                          icon: "CheckIcon",
                                                          svgClasses: "w-5 h-5",
                                                        },
                                                      })
                                                    : _c("feather-icon", {
                                                        staticClass:
                                                          "text-warning",
                                                        attrs: {
                                                          title:
                                                            "Não sincronizada",
                                                          icon: "XIcon",
                                                          svgClasses: "w-5 h-5",
                                                        },
                                                      }),
                                                  _vm._v(
                                                    "\n                   " +
                                                      _vm._s(
                                                        schedule.observation
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "vs-td",
                                                [
                                                  schedule.status ===
                                                  "Não Concluído"
                                                    ? _c("div", {
                                                        staticClass:
                                                          "h-4 w-4 inline-block rounded-full border-2 border-solid border-secondary",
                                                      })
                                                    : schedule.status ===
                                                      "Concluído"
                                                    ? _c("div", {
                                                        staticClass:
                                                          "h-4 w-4 inline-block rounded-full bg-success",
                                                      })
                                                    : _c("feather-icon", {
                                                        staticClass:
                                                          "text-danger",
                                                        attrs: {
                                                          icon: "SlashIcon",
                                                          svgClasses: "w-4 h-4",
                                                        },
                                                      }),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "cursor-default ml-1 mr-2",
                                                      class: `text-${
                                                        schedule.status ===
                                                        "Concluído"
                                                          ? "success"
                                                          : schedule.status ===
                                                            "Não Concluído"
                                                          ? "secondary"
                                                          : "danger"
                                                      }`,
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            schedule.status
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      )
                                    },
                                  },
                                ],
                                null,
                                false,
                                1158134364
                              ),
                            },
                            [
                              _c(
                                "template",
                                { slot: "thead" },
                                [
                                  _c(
                                    "vs-th",
                                    {
                                      staticStyle: {
                                        "font-size": "11px",
                                        width: "40px",
                                      },
                                    },
                                    [
                                      _c("vs-checkbox", {
                                        staticClass: "select-all-chexkbox",
                                        attrs: {
                                          disabled: !_vm.schedules.length,
                                          "icon-pack": "feather",
                                          icon: _vm.selectAllIcon,
                                        },
                                        model: {
                                          value: _vm.selectAllCheckBox,
                                          callback: function ($$v) {
                                            _vm.selectAllCheckBox = $$v
                                          },
                                          expression: "selectAllCheckBox",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-th",
                                    {
                                      staticStyle: {
                                        "font-size": "11px",
                                        width: "40px",
                                      },
                                    },
                                    [_c("span", [_vm._v("#")])]
                                  ),
                                  _c(
                                    "vs-th",
                                    { staticStyle: { "font-size": "11px" } },
                                    [_c("span", [_vm._v("HORÁRIO")])]
                                  ),
                                  _c(
                                    "vs-th",
                                    { staticStyle: { "font-size": "11px" } },
                                    [_c("span", [_vm._v("INSTRUTOR")])]
                                  ),
                                  _c(
                                    "vs-th",
                                    { staticStyle: { "font-size": "11px" } },
                                    [_c("span", [_vm._v("ALUNO")])]
                                  ),
                                  _c(
                                    "vs-th",
                                    { staticStyle: { "font-size": "11px" } },
                                    [_c("span", [_vm._v("VEÍCULO")])]
                                  ),
                                  _c(
                                    "vs-th",
                                    {
                                      staticStyle: {
                                        "font-size": "11px",
                                        width: "50px",
                                      },
                                    },
                                    [_c("span", [_vm._v("CAT.")])]
                                  ),
                                  _c(
                                    "vs-th",
                                    { staticStyle: { "font-size": "11px" } },
                                    [_c("span", [_vm._v("Mensagem")])]
                                  ),
                                  _c(
                                    "vs-th",
                                    { staticStyle: { "font-size": "11px" } },
                                    [_c("span", [_vm._v("IBio")])]
                                  ),
                                  _c(
                                    "vs-th",
                                    { staticStyle: { "font-size": "11px" } },
                                    [_c("span", [_vm._v("STATUS")])]
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }