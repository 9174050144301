var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "list-filled-schedule",
    [
      _c(
        "div",
        { staticClass: "inline-flex" },
        [
          _c("vs-input", {
            attrs: { type: "time", label: "Início" },
            model: {
              value: _vm.startTime,
              callback: function ($$v) {
                _vm.startTime = $$v
              },
              expression: "startTime",
            },
          }),
          _c("vs-input", {
            staticClass: "ml-2",
            attrs: { type: "time", label: "Fim", disabled: "" },
            model: {
              value: _vm.endTime,
              callback: function ($$v) {
                _vm.endTime = $$v
              },
              expression: "endTime",
            },
          }),
        ],
        1
      ),
      _c("vs-divider"),
      _c(
        "div",
        { staticClass: "flex flex-row-reverse bg-grey-lighter" },
        [_c("vs-button", [_vm._v("Salvar")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }