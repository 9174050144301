<template>
  <div>
    <vs-sidebar
      click-not-close
      parent="#schedule-practical"
      :hidden-background="true"
      class="full-vs-sidebar email-view-sidebar items-no-padding"
      v-model="isSidebarActive"
      position-right
    >
      <vs-popup
        fullscreen
        :title="showPdfTitle"
        :active.sync="popupShowPdf"
      >
        <object :data="srcPdf" width="100%" style="height: 600px" />
      </vs-popup>
      <vs-prompt
        title="Imprimir"
        @accept="doPrint"
        accept-text="Confirmar"
        :active.sync="popupCategoryCnh"
      >
        <vs-row>
          <vs-col>
            <label class="vs-input--label">Categoria</label>
            <v-select
              v-model="categoryCnh"
              :clearable="false"
              style="background-color: white"
              :reduce="(option) => option.value"
              :options="categoryCNHOptions"
              placeholder="Selecione"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
            >
              <template #no-options="{}">
                Nenhum resultado encontrado.
              </template>
            </v-select>
          </vs-col>
        </vs-row>
      </vs-prompt>
      <div class="mail-sidebar-content px-0 sm:pb-6 h-full" v-if="schedule.id">
        <div
          class="
            flex flex-wrap
            items-center
            email-header
            justify-between
            md:px-8
            px-6
            sm:pb-2 sm:
            pt-6
            d-theme-dark-bg
          "
        >
          <div class="flex mb-4">
            <div class="flex items-center">
              <feather-icon
                :icon="$vs.rtl ? 'ArrowRightIcon' : 'ArrowLeftIcon'"
                @click="$emit('closeSidebar')"
                class="cursor-pointer hover:text-primary mr-4"
                svg-classes="w-6 h-6"
              ></feather-icon>
              <h4>DETALHAMENTO DE AGENDA PRÁTICA</h4>
            </div>
          </div>
          <div class="ml-10 mb-4 mt-1">
            <div class="email__actions--single flex items-baseline">
              <vx-tooltip text="Imprimir" position="top" class="mr-3 ml-auto">
                <feather-icon
                  icon="PrinterIcon"
                  svg-classes="h-6 w-6"
                  class="cursor-pointer"
                  @click="popupCategoryCnh = true"
                ></feather-icon>
              </vx-tooltip>
              <!-- <vx-tooltip text="Deletar este agendamento" position="top" class="ml-4">
                    <feather-icon icon="TrashIcon" svg-classes="h-6 w-6" class="cursor-pointer" @click="$emit('delete')"></feather-icon>
                  </vx-tooltip> -->

              <vx-tooltip
                text="Sincronizar IBio"
                position="top"
                class="mr-3 ml-auto"
              >
                <feather-icon
                  icon="UploadCloudIcon"
                  svg-classes="h-6 w-6"
                  class="cursor-pointer"
                  @click="confirmSincIBio"
                ></feather-icon>
              </vx-tooltip>

              <vx-tooltip text="Alterar Status" position="top">
                <vs-dropdown
                  vs-custom-content
                  vs-trigger-click
                  class="cursor-pointer"
                >
                  <feather-icon
                    icon="FolderIcon"
                    svg-classes="h-6 w-6"
                    class="mb-2"
                  ></feather-icon>

                  <vs-dropdown-menu class="w-60">
                    <vs-dropdown-item @click="updateStatus('Concluído')">
                      <span class="flex items-center">
                        <div
                          class="
                            h-4
                            w-4
                            inline-block
                            rounded-full
                            mr-3
                            bg-success
                          "
                        ></div>
                        <span>Concluído</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item @click="updateStatus('Não Concluído')">
                      <span class="flex items-center">
                        <div
                          class="
                            h-4
                            w-4
                            inline-block
                            rounded-full
                            mr-3
                            border-2 border-solid border-secondary
                          "
                        ></div>
                        <!-- <div class="h-4 w-4 inline-block rounded-full mr-3 bg-danger"></div> -->
                        <span>Não Concluído</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item @click="updateStatus('ABSENCE')">
                      <span class="flex items-center">
                        <feather-icon
                          class="mr-2 text-danger"
                          icon="SlashIcon"
                          svgClasses="w-4 h-4"
                        />
                        <span class="text-danger hover:text-primary"
                          >Ausente</span
                        >
                      </span>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vx-tooltip>

              <vx-tooltip
                text="Deletar Selecionados"
                position="top"
                class="ml-3"
              >
                <feather-icon
                  icon="TrashIcon"
                  svg-classes="h-6 w-6"
                  class="cursor-pointer"
                  @click="confirmDelete"
                ></feather-icon>
              </vx-tooltip>
            </div>
          </div>
        </div>

        <component
          :is="scrollbarTag"
          class="scroll-area-mail-content md:px-8 md:pb-8 pt-4 px-6 pb-6"
          :settings="settings"
          :key="$vs.rtl"
        >
          <div v-if="isSidebarActive">
            <!-- LATEST MESSAGE -->
            <div class="vx-row">
              <div class="vx-col w-full">
                <email-mail-card :student="student" :schedule="schedule" />
              </div>
            </div>
            <div v-if="student.id" class="vx-row" style="margin-top: 2.2rem">
              <div class="vx-col w-full">
                <vx-card>
                  <div class="vx-row">
                    <div
                      class="
                        vx-col
                        w-full
                        border-b border-l-0 border-r-0 border-t-0
                        d-theme-border-grey-light
                        border-solid
                        flex
                        justify-between
                        flex
                        items-center
                      "
                    >
                      <h5 class="mb-1">
                        Outros Agendamentos de {{ student.name }}
                      </h5>
                      <!-- <vx-tooltip text="Alterar Status" position="top" class="ml-auto">
                                <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
                                  <feather-icon icon="FolderIcon" svg-classes="h-6 w-6" class="mb-2"></feather-icon>

                                  <vs-dropdown-menu class="w-60">
                                    <vs-dropdown-item @click="updateStatus('Concluído')">
                                      <span class="flex items-center">
                                        <div class="h-4 w-4 inline-block rounded-full mr-3 bg-success"></div>
                                        <span>Concluído</span>
                                      </span>
                                    </vs-dropdown-item>

                                    <vs-dropdown-item @click="updateStatus('Não Concluído')">
                                      <span class="flex items-center">
                                        <div class="h-4 w-4 inline-block rounded-full mr-3 border-2 border-solid border-secondary"></div> -->
                      <!-- <div class="h-4 w-4 inline-block rounded-full mr-3 bg-danger"></div> -->
                      <!-- <span>Não Concluído</span>
                                      </span>
                                    </vs-dropdown-item>

                                    <vs-dropdown-item @click="updateStatus('ABSENCE')">
                                      <span class="flex items-center">
                                        <feather-icon class="mr-2 text-danger" icon="SlashIcon" svgClasses="w-4 h-4" />
                                        <span class="text-danger hover:text-primary">Ausente</span>
                                      </span>
                                    </vs-dropdown-item>
                                  </vs-dropdown-menu>
                                </vs-dropdown>
                              </vx-tooltip>

                              <vx-tooltip text="Deletar Selecionados" position="top" class="ml-4">
                                <feather-icon icon="TrashIcon" svg-classes="h-6 w-6" class="cursor-pointer" @click="confirmDelete"></feather-icon>
                              </vx-tooltip> -->
                    </div>
                  </div>
                  <vs-table
                    noDataText="Nenhum Agendamento."
                    v-if="student.schedules"
                    :data="
                      student.schedules
                        .filter((a) => a.type === 'practical')
                        .sort(
                          (a, b) =>
                            new Date(
                              `${a.start_date.slice(0, 10)}T${
                                a.start_time
                              }-03:00`
                            ) -
                            new Date(
                              `${b.start_date.slice(0, 10)}T${
                                b.start_time
                              }-03:00`
                            )
                        )
                    "
                    class="bordered"
                    hoverFlat
                    style="overflow: -webkit-paged-y"
                  >
                    <template slot="thead">
                      <vs-th style="font-size: 11px; width: 40px">
                        <vs-checkbox
                          v-model="selectAllCheckBox"
                          :disabled="!student.schedules.length"
                          icon-pack="feather"
                          :icon="selectAllIcon"
                          class="select-all-chexkbox"
                        ></vs-checkbox>
                      </vs-th>
                      <vs-th style="font-size: 11px; width: 40px"
                        ><span>#</span></vs-th
                      >
                      <vs-th style="font-size: 11px"
                        ><span>HORÁRIO</span></vs-th
                      >
                      <vs-th style="font-size: 11px"
                        ><span>INSTRUTOR</span></vs-th
                      >
                      <vs-th style="font-size: 11px"
                        ><span>VEÍCULO</span></vs-th
                      >
                      <vs-th style="font-size: 11px; width: 50px"
                        ><span>CAT.</span></vs-th
                      >
                      <vs-th style="font-size: 11px"><span>IBio</span></vs-th>
                      <vs-th style="font-size: 11px"><span>STATUS</span></vs-th>
                    </template>
                    <template slot-scope="{ data }">
                      <vs-tr
                        :key="indextr"
                        v-for="(schedule, indextr) in data"
                        style="font-size: 12px; opacity: 1 !important"
                      >
                        <vs-td
                          ><vs-checkbox
                            v-model="selectedSchedules"
                            :vs-value="schedule"
                            class="vs-checkbox-small ml-0 mr-1"
                          ></vs-checkbox
                        ></vs-td>
                        <vs-td
                          ><span class="cursor-default font-semibold">{{
                            indextr + 1
                          }}</span></vs-td
                        >
                        <vs-td
                          ><span class="cursor-default font-semibold"
                            >{{ schedule.start_date | dateTime('date') }}
                            {{ schedule.start_time.slice(0, 5) }}</span
                          ></vs-td
                        >
                        <vs-td
                          ><span class="cursor-default font-semibold">{{
                            schedule.employee.name
                          }}</span></vs-td
                        >
                        <vs-td
                          ><span class="cursor-default font-semibold"
                            >{{ schedule.vehicle.brand }}
                            {{ schedule.vehicle.model }}
                            {{ schedule.vehicle.license_plate }}</span
                          ></vs-td
                        >
                        <vs-td style="text-align: center"
                          ><span class="cursor-default font-semibold">{{
                            schedule.category
                          }}</span></vs-td
                        >
                        <vs-td style="text-align: center">
                          <feather-icon
                            title="Sincronizando"
                            v-if="
                              (!schedule.ibio && schedule.sync_ibio_last_try) ||
                              schedule.sincIbioStatus === 'processando'
                            "
                            class="text-warning"
                            icon="ClockIcon"
                            svgClasses="w-5 h-5"
                          />
                          <feather-icon
                            title="Sincronizada"
                            v-else-if="schedule.ibio && schedule.id_ibio"
                            class="text-success"
                            icon="CheckIcon"
                            svgClasses="w-5 h-5"
                          />
                          <feather-icon
                            v-else
                            class="text-warning"
                            title="Não sincronizada"
                            icon="XIcon"
                            svgClasses="w-5 h-5"
                          />
                        </vs-td>
                        <vs-td>
                          <div
                            v-if="schedule.status === 'Não Concluído'"
                            class="
                              h-4
                              w-4
                              inline-block
                              rounded-full
                              border-2 border-solid border-secondary
                            "
                          ></div>
                          <div
                            v-else-if="schedule.status === 'Concluído'"
                            class="h-4 w-4 inline-block rounded-full bg-success"
                          ></div>
                          <feather-icon
                            v-else
                            class="text-danger"
                            icon="SlashIcon"
                            svgClasses="w-4 h-4"
                          />
                          <span
                            class="cursor-default ml-1 mr-2"
                            :class="`text-${
                              schedule.status === 'Concluído'
                                ? 'success'
                                : schedule.status === 'Não Concluído'
                                ? 'secondary'
                                : 'danger'
                            }`"
                          >
                            {{ schedule.status | scheduleStatus }}
                          </span>
                        </vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                </vx-card>
              </div>
            </div>
          </div>
        </component>
      </div>
    </vs-sidebar>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import EmailMailCard from './EmailMailCard.vue'
import moduleStudentManagement from '@/store/student-management/moduleStudentManagement.js'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    // USO ESTA PROPRIEDADE PARA FORÇAR A ATUALIZAÇÃO DO ALUNO E COM ISSO MOSTRAR AS ALTERAÇÕES NA AGENDA
    // QUANDO NECESSÁRIO ISSO FOI FEITO PARA MOSTRAR NA LISTAGEM A TROCA DE STATUS PARA AUSENTE
    request: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      popupCategoryCnh: false,
      categoryCnh: 'ALL',
      categoryCNHOptions: [
        { label: 'A', value: 'A' },
        { label: 'B', value: 'B' },
        { label: 'C', value: 'C' },
        { label: 'D', value: 'D' },
        { label: 'E', value: 'E' },
        { label: 'ACC', value: 'ACC' },
        { label: 'TODAS', value: 'ALL' }
      ],
      showPdfTitle: 'PDF',
      srcPdf: '',
      popupShowPdf: false,
      payload: {
        offset: 0,
        limit: 200, // SE EXISTIREM MAIS DO QUE 200 HAVERÁ PROBLEMAS NA SELEÇÃO MÚLTIPLA
        student_id: null,
        type: 'practical',
        params: null,
        consultApi: true
      },
      selectedSchedules: [],
      showThread: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.5
      }
    }
  },
  watch: {
    async isSidebarActive (value) {
      this.selectedSchedules = []
      if (!value) {
        this.$emit('closeSidebar')
        setTimeout(() => {
          this.showThread = false
        }, 500)
      } else if (value) {
        this.$vs.loading()
        console.log(this.schedule)
        await this.$store.dispatch(
          'studentManagement/fetchStudent',
          this.schedule.student_id
        )
        this.$vs.loading.close()
      } else if (this.schedule.status === 'Bloqueado') {
        // console.log('limpa')
      }
    }
  },
  computed: {
    user () {
      return this.$store.state.AppActiveUser
    },
    schedule () {
      return this.$store.state.schedule.schedule
    },
    selectAllCheckBox: {
      get () {
        return this.selectedSchedules.length
      },
      set (value) {
        if (value) {
          this.selectedSchedules = this.student.schedules
        } else {
          this.selectedSchedules = []
        }
      }
    },
    selectAllIcon () {
      return this.selectedSchedules.length === this.student.schedules.length
        ? 'icon-check'
        : 'icon-minus'
    },
    student () {
      return this.schedule.status !== 'Bloqueado'
        ? this.$store.getters['studentManagement/getStudent']
        : {}
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    doPrint () {
      setTimeout(this.printPracticalScheduleReport, 500)
    },
    async printPracticalScheduleReport () {
      this.$vs.loading()
      this.showPdfTitle = 'Relatório de Agendamentos de Aulas Práticas'
      const resp = await this.$http.post(
        `${process.env.VUE_APP_API_URL}/schedule/practicalClass/report`,
        {
          firstPeriod: null,
          lastPeriod: null,
          orderBy: 'date',
          format: 'PDF',
          payload: {
            students: [this.student.id],
            employees: [],
            vehicles: [],
            type_vehicle: null,
            status: null,
            category: this.categoryCnh
          }
        },
        { responseType: this.type === 'EXCEL' ? 'arraybuffer' : null }
      )

      // if (this.type === 'PDF') {
      this.srcPdf = `data:application/pdf;base64,${resp.data}`
      this.popupShowPdf = true
      // } else if (this.type === 'EXCEL') {
      //   const blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      //   this.makeBlobDoc(blob, 'Relatório de Agendamentos de Aulas Práticas')
      // }
      this.$nextTick(() => {
        this.$vs.loading.close()
      })
    },
    async updateStatus (status) {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(
        (a) => a.module === 'spa_schedule_practical'
      )
      if (
        !this.user.roles.some((role) => role.name === 'admin') &&
        (!mod || !mod.pivot.permissions.includes('save'))
      ) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      if (!this.selectedSchedules.length) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Selecione ao menos um agendamento.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }
      try {
        this.$vs.loading()
        /**
         * FAÇO UM COMMIT EM ALUNOS ALTERANDO AS AGENDAS NO ALUNO NA LISTA
         * FAÇO OUTRO AOMMIT DIRETO NAS AGENDAS ALTERANDO AS AGENDAS DA TABELA
         */
        this.$store.commit('studentManagement/UPDATE_MASSIVE_SCHEDULES', {
          schedules: this.selectedSchedules,
          changes: { status, duration: '00:50' }
        }) // PRECISO COLOCAR ASSIM O TEMPO PQ NO BANCO É TIME
        this.$store.commit('schedule/UPDATE_MASSIVE_SCHEDULES', {
          schedules: this.selectedSchedules,
          changes: { status, duration: '00:50' }
        }) // PRECISO COLOCAR ASSIM O TEMPO PQ NO BANCO É TIME
        const selectedSchedules = JSON.parse(
          JSON.stringify(this.selectedSchedules)
        )
        selectedSchedules.map((a) => {
          a.status = status
          a.duration = '00:50'
        })

        await this.$store.dispatch('schedule/updateMany', selectedSchedules)
        this.selectedSchedules = []
        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Alterados.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning',
            position: 'top-center'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    async deleteSelected () {
      if (!this.selectedSchedules.length) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Selecione ao menos um agendamento.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }
      try {
        this.$vs.loading()

        // SE EU ESTIVER EXCLUINDO O AGENDAMENTO QUE CLIQUEI,
        // DEVO FECHAR A JANELA LATERAL POIS O MESMO FICA VAZIO
        const scheduleIndex = this.selectedSchedules.findIndex(
          (e) => e.id === this.schedule.id
        )
        if (scheduleIndex >= 0) this.$emit('closeSidebar')

        await this.$store.dispatch(
          'schedule/deleteMany',
          this.selectedSchedules.map((a) => a.id)
        )
        this.$store.commit(
          'studentManagement/DELETE_SCHEDULES',
          this.selectedSchedules
        ) // PRECISO COLOCAR ASSIM O TEMPO PQ NO BANCO É TIME

        this.selectedSchedules = []
        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Excluídos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning',
            position: 'top-center'
          })
          if (error.response.data.code === 'LOGIN_ERROR') {
            // ERRO DE LOGIN NO IBIO
            this.$store.commit(
              'studentManagement/DELETE_SCHEDULES',
              this.selectedSchedules
            ) // PRECISO COLOCAR ASSIM O TEMPO PQ NO BANCO É TIME
            this.selectedSchedules = []
          }
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    confirmSincIBio () {
      if (!this.selectedSchedules.length) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Selecione ao menos um agendamento.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      } else {
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: 'Confirmar Sincronização IBio',
          text: 'Tem certeza que deseja sincronizar as agendas selecionadas no IBio?',
          accept: this.sincIBioSelected,
          acceptText: 'Sincronizar',
          cancelText: 'Cancelar'
        })
      }
    },
    async sincIBioSelected () {
      // alert('em desenvolvimento')
      try {
        this.$vs.loading()

        const s = await this.$store.dispatch(
          'schedule/syncSelectedSchedulesIbio',
          this.selectedSchedules.map((a) => a.id)
        )
        s.data.forEach((schedule) => {
          this.$store.commit('studentManagement/UPDATE_MASSIVE_SCHEDULES', {
            schedules: [schedule],
            changes: {
              sincIbioStatus: 'processando',
              ibio: schedule.ibio,
              id_ibio: schedule.id_ibio
            }
          }) // PRECISO COLOCAR ASSIM O TEMPO PQ NO BANCO É TIME
          this.$store.commit('schedule/UPDATE_MASSIVE_SCHEDULES', {
            schedules: [schedule],
            changes: {
              sincIbioStatus: 'processando',
              ibio: schedule.ibio,
              id_ibio: schedule.id_ibio
            }
          }) // PRECISO COLOCAR ASSIM O TEMPO PQ NO BANCO É TIME
        })

        this.selectedSchedules = []
        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Agendas Sincronizadas.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        this.$vs.loading.close()
        console.log(error)
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning',
            position: 'top-center'
          })
          if (error.response.data.code === 'LOGIN_ERROR') {
            // ERRO DE LOGIN NO IBIO
            this.$store.commit(
              'studentManagement/DELETE_SCHEDULES',
              this.selectedSchedules
            ) // PRECISO COLOCAR ASSIM O TEMPO PQ NO BANCO É TIME
            this.selectedSchedules = []
          }
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    confirmDelete () {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(
        (a) => a.module === 'spa_schedule_practical'
      )
      if (
        !this.user.roles.some((role) => role.name === 'admin') &&
        (!mod || !mod.pivot.permissions.includes('delete'))
      ) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      if (!this.selectedSchedules.length) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Selecione ao menos um agendamento.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      } else {
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: 'Confirmar Exclusão',
          text: 'Tem certeza que deseja excluir as agendas selecionadas?',
          accept: this.deleteSelected,
          acceptText: 'Excluir',
          cancelText: 'Cancelar'
        })
      }
    }
  },
  components: {
    VuePerfectScrollbar,
    EmailMailCard
  },

  created () {
    // Register Module StudentManagement Module
    if (!moduleStudentManagement.isRegistered) {
      this.$store.registerModule('studentManagement', moduleStudentManagement)
      moduleStudentManagement.isRegistered = true
    }
  }
}
</script>

