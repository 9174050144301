import { categoriaStringToArray } from '@/helpers/categoryHelper.js'


export function instrutorEnsinaNaCategoria (instrutor, categoryString) {
  const categoriasArray = categoriaStringToArray(instrutor.category_cnh_employee)
    
  for (let i = 0; i < categoriasArray.length; i++) {
    if (categoryString.includes(categoriasArray[i])) {
      return true
    }
      
    return false
  }
}