<template>
  <list-filled-schedule>
    <div class="inline-flex">
      <vs-input type="time" label="Início" v-model="startTime" />
      <vs-input type="time" class="ml-2" label="Fim" v-model="endTime" disabled />
    </div>
    <vs-divider />
     <div class="flex flex-row-reverse bg-grey-lighter">
      <vs-button>Salvar</vs-button>
    </div>
  </list-filled-schedule>
</template>

<script>
import axios from '@/axios'
import ListFilledSchedule from './ListFilledSchedule.vue'

export default {
  components: {
    ListFilledSchedule
  },
  props: {
    value: {
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      schedules: []
    }
  },
  computed: {
    startTime: {
      get () {
        return this.value.start_time
      },
      set (value) {
        this.$emit('input', {
          start_time: value,
          end_time: this.endTime
        })
      }
    },
    endTime: {
      get () {
        return this.value.end_time
      },
      set (value) {
        this.$emit('input', {
          start_time: this.startTime,
          end_time: this.value
        })
      }
    }
  },
  methods: {
    fetchTimes () {
      this.$vs.loading()
      axios.post(`${process.env.VUE_APP_API_URL}/schedule/available`, {
        student_id: 784282,
        type: 'practical',
        employee_id: 388,
        start_date: '2022-07-27',
        vehicle_id: 289,
        category: 'A'
      })
        .then(resp => {
          this.schedules = resp.data
        })
        .catch(this.mixinCatch)
        .finally(this.mixinFinally)
    }
  },
  created () {
    this.fetchTimes()
  }
}
</script>
